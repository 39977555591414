	/* Note: Design for a width of 960px */	
    @media only screen and (min-width:992px) and (max-width:1199px) {
		
		/* Base */
				
		/*----*****---- << Plumber >> --****--*/
		.dt-sc-shadow { width:100%; background-size:100%; }
		
		
		/* Grid */
		
		/*----*****---- << Container >> ----*****----*/	
		.container, .type5.tribe_events .container .container, .layout-boxed .vc_row .dt-sc-stretch-row-content, .left-header #header-wrapper .top-bar .container { width:900px; }
		.container.fill-container, .page-template-tpl-fullwidth .section-wrapper.fullwidth-section .container.fill-stretch-row { width:930px; }
		.layout-boxed .wrapper, .layout-boxed .main-header-wrapper, .layout-boxed .is-sticky #header-wrapper, .layout-boxed.standard-header .main-header-wrapper, .layout-boxed.header-align-center .sticky-wrapper.is-sticky .menu-wrapper { width:940px; }
		
		.page-with-sidebar .vc_row-no-padding .dt-sc-newsletter-section.type7 { max-width:900px; }
		
		#primary { width:670px; }
		#primary.with-both-sidebar { width:440px; }
		#secondary, #secondary-right, #secondary-left { width:200px; }
		
		.layout-boxed .vc_row.vc_row-no-padding { width:calc(100% + 40px) !important; left:-20px !important; }
		
		.site-with-border #main .container { padding:0 20px; }
		
		
		/*----*****---- << Mega Menu >> ----*****----*/		
		#main-menu .megamenu-2-columns-group .megamenu-child-container { width:460px; }
		#main-menu .megamenu-3-columns-group .megamenu-child-container { width:680px; }
		#main-menu .megamenu-4-columns-group .megamenu-child-container { width:900px; }
		
		.left-header #main-menu .megamenu-4-columns-group .megamenu-child-container { width:640px; }
 		
		/*----*****---- << Column >> ----*****----*/
		.vc_col-md-6.wpb_column.no-space.dt-sc-two-fifth, .vc_col-md-6.column.no-space.dt-sc-two-fifth { width:50%; }
 		
		.wpb_column.dt-sc-one-fifth.vc_col-md-6 { width: 50%; }
		
		.wpb_column.dt-sc-one-fifth.vc_col-md-12,
		.wpb_column.dt-sc-two-fifth.vc_col-md-12,
		.wpb_column.dt-sc-three-fifth.vc_col-md-12 { width: 100%; }
		
		.vc_col-md-12.wpb_column { padding-bottom: 25px; }
		.vc_col-md-12.wpb_column:last-child { padding-bottom: 0; }
		
		.rs_margin_top_md-150.vc_row,
		.rs_margin_top_md-150.wpb_column .vc_column-inner { margin-top: 150px !important; }
		
		
		/* Widget */
		
		/*----*****---- << Widgets >> ----*****----*/
		.dt-sc-one-third .widget_text ul.split-list { width:100%; margin-right:0; }		
		.flickr-widget div { height: 68px; width: 68px; }
		.secondary-sidebar .type7 .widgettitle:before { left: -20px; }
		
		.secondary-sidebar .type8 .widget { padding: 0 15px 15px; }
		.secondary-sidebar .type8 .widgettitle { margin: -28px auto 20px; }
		
		.widget .recent-posts-widget li .entry-meta p:last-child { margin-right: 0; }
		.tagcloud a { margin:0 4px 10px 0; }
		
		.secondary-sidebar .type9 .widget ul li, .secondary-sidebar .type12 .widget ul li { font-size: 13px; }
		.woo-type6 .secondary-sidebar .widget.woocommerce .woocommerce-product-search input[type="submit"] { border-radius:5px; }
				
		.secondary-sidebar .widget .tweet_list li { padding: 0 0 20px 30px; }

		.secondary-sidebar .widget .tweet_list li:before { font-size: 22px; }
		.secondary-sidebar .widget .tweet_list li:before, .secondary-sidebar .widget .tweet_list li:first-child:before { top: 0; }
				
		.secondary-sidebar aside.widget, .secondary-sidebar .type11 .widget { font-size: 12px; }		
		.secondary-sidebar .type2 .widget, .secondary-sidebar .type6 .widget, .secondary-sidebar .type8 .widget, .secondary-sidebar .type12 .widget, .secondary-sidebar .type13 .widget { font-size: 13px; }
				
		.secondary-sidebar .type8 .widget, .secondary-sidebar .type12 .widget { padding: 15px 15px 20px; }
		.secondary-sidebar .type11 .widget { padding: 15px 10px 20px; }
		
		.secondary-sidebar .type5 .widget .tweet_list li, .secondary-sidebar .type8 .widget .tweet_list li,  .secondary-sidebar .type11 .widget .tweet_list li, .secondary-sidebar .type12 .widget .tweet_list li { padding: 0 0 20px; }
		.secondary-sidebar .type5 .widget .tweet_list li:before, .secondary-sidebar .type8 .widget .tweet_list li:before, 
		.secondary-sidebar .type11 .widget .tweet_list li:before, .secondary-sidebar .type12 .widget .tweet_list li:before { margin-right: 8px; position: static; }
		
		.secondary-sidebar .type11 > .widget:before { padding-right: 10px; top: 18px; }
		.secondary-sidebar .type11 .widgettitle:before { top: 9px; }
		.secondary-sidebar .type11 .widgettitle { font-size: 22px; }
				
		.secondary-sidebar .type12 .widget.woocommerce .woocommerce-product-search input[type="submit"]	{ width: 100%; }
		
		.widget.widget_pages ul li ul.children, .widget.widget_nav_menu ul li ul.sub-menu { padding: 10px 0px 0px 10px; }
		
		.widget #wp-calendar td { padding: 9px 5px; }
		.widget #wp-calendar th { padding: 10px 5px; }

		.widget.woocommerce .woocommerce-product-search input[type="submit"] { float:right; min-height:50px; margin-top:10px; position:inherit; }		
		.woo-type4 .widget.woocommerce .woocommerce-product-search input[type="submit"] { -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; border-radius: 5px; }
		.woo-type20 .widget.woocommerce .woocommerce-product-search input[type="submit"] { border-radius: 5px; }
		.widget.woocommerce .woocommerce-product-search input[type="search"] { padding-right: 10px; }
		
		
		/* Layout */
		
		.left-header.fixed-left-header #footer, .left-header.fixed-left-header #main { padding-left: 200px; }
		.left-header.fixed-left-header #header-wrapper { width:200px; }
		.left-header.fixed-left-header .portfolio-fullwidth-container .dt-sc-one-fourth { width: 49.92%; }
		.left-header.fixed-left-header #main-menu > ul.menu > li > a { font-size: 17px; }
		.left-header.fixed-left-header #main-menu > ul.menu > li > a > span, .left-header.fixed-left-header #main-menu > ul.menu > li > a > i { font-size: 18px; }
		
		/*----*****---- << Topbar >> ----*****----*/
		.top-bar .dt-sc-vertical-separator { margin:0px 10px; }
		
		/*----*****---- << Menu >> ----*****----*/
 		.menu-with-slanting-splitter #main-menu > ul.menu > li > a { padding:0px 15px 0 20px; }
		.menu-active-border-with-arrow #main-menu > ul.menu > li > a { padding:0px 9px; font-size:12px; }
		.menu-active-with-icon #main-menu > ul.menu > li > a { padding:0px 8px; }
		.menu-active-highlight-grey #main-menu ul.menu > li { padding:0px 12px; }
		.header-align-center.fullwidth-menu-header #main-menu ul.menu > li > a { padding:0 15px; }
		
		/*----*****---- << Header >> ----*****----*/
		.split-header #main-menu ul.menu > li > a { padding:0px 15px; }
		.split-header.transparent-header #main-menu ul.menu > li { padding:0px 10px; }
		.split-header .menu-with-slanting-splitter #main-menu > ul.menu > li > a { font-size:11px; padding:0px 7px; }
		.split-header .menu-with-slanting-splitter #main-menu ul.menu > li:before { right:-8px; }
		.split-header #main-menu .menu-left, .split-header #main-menu .menu-right { width:41%; }
		
		/*----*****---- << Hosting >> ----*****----*/
		ul.side-nav li a { font-size:13px; }
		
		/*----*****---- << Breadcrumb >> ----*****----*/
		.main-title-section-wrapper.breadcrumb-right .main-title-section h1 { font-size: 26px; }
		.main-title-section-wrapper.breadcrumb-right .main-title-section { width:58%; }
		.main-title-section-wrapper.breadcrumb-right .breadcrumb { width:42%; }		
		
		/*----*****---- << Responsive Map >> ----*****----*/
		.responsive-map { max-width: 100%; }		
		
		/*----*****---- << Footer >> ----*****----*/
		.footer-copyright .vc_col-sm-6 { width:100%; text-align:center; }
		.footer-copyright .vc_col-sm-6 .vc_column-inner  { padding:0; }
		.footer-copyright .menu-links { text-align:center; margin-top:10px; }
		
		.footer-copyright .vc_col-sm-6.alignright .dt-sc-sociable { float: left; margin-top: 10px; text-align: center; width: 100%; }
		.footer-copyright .vc_col-sm-6.alignright .dt-sc-sociable li { display: inline-block; float: none; }
		
		
		/* Blog */
		.with-both-sidebar .tpl-blog-holder.apply-isotope .dt-sc-one-third, .with-both-sidebar .tpl-blog-holder.apply-isotope .dt-sc-one-half  { width:100%; }
		.page-with-sidebar .tpl-blog-holder.apply-isotope .dt-sc-one-third { width:50%; }
		.with-both-sidebar .tpl-blog-holder.apply-isotope .dt-sc-one-third { width:100%; }
		 
		.with-both-sidebar .tpl-blog-holder.apply-isotope .dt-sc-one-half { width:100%; }
		
		.dt-sc-one-half .blog-entry.blog-medium-style .entry-thumb ~ .entry-details { padding-top:30px; }
		
		.with-both-sidebar .blog-entry.blog-medium-style .entry-thumb, .with-both-sidebar .blog-entry.blog-medium-style .entry-details { width:100%; }
		.with-both-sidebar .dt-sc-one-column .blog-entry.blog-medium-style .entry-thumb ~ .entry-details { padding-left:0; margin-top:20px; }
		.with-both-sidebar .dt-sc-one-column .blog-entry.blog-medium-style.dt-blog-medium-highlight .entry-thumb ~ .entry-details { padding-left:30px; }
		
		.with-both-sidebar .blog-entry.blog-medium-style .entry-details { padding-bottom:20px; }
		.with-both-sidebar .dt-sc-one-column .blog-entry.blog-medium-style .entry-format { right:0; }
		
		.dt-sc-one-half .blog-entry.blog-medium-style .entry-thumb, .dt-sc-one-half .blog-entry.blog-medium-style .entry-details { width:100%; }
		.dt-sc-one-half .blog-entry.blog-medium-style .entry-thumb ~ .entry-details { padding-left:0; }
		.dt-sc-one-third .blog-entry.blog-medium-style { width:99.5%; }
		.with-both-sidebar .dt-sc-one-third .blog-entry.entry-date-left .entry-title { min-height:auto; }
		.with-both-sidebar .dt-sc-one-third .blog-entry.entry-date-left .entry-body, .with-both-sidebar .dt-sc-one-third .blog-entry.entry-date-left .entry-meta-data { margin-top:0; margin-left:0; width:100%; }
		.with-both-sidebar .dt-sc-one-third .blog-entry.entry-date-left .entry-title h4 { font-size:15px; }
		
		.with-both-sidebar .dt-sc-one-half .blog-entry.blog-medium-style .entry-details, .page-with-sidebar .dt-sc-one-half .blog-entry.blog-medium-style.dt-blog-medium-highlight .entry-details { margin-top:0; }
		.page-with-sidebar .dt-sc-one-half .blog-entry.blog-medium-style .entry-thumb, .page-with-sidebar .dt-sc-one-half .blog-entry.blog-medium-style .entry-details { width:100%; }
		.dt-sc-one-half .blog-entry.blog-medium-style.dt-blog-medium-highlight .entry-thumb ~ .entry-details { padding-left:30px; width:100%; padding-top:30px; }	
		.dt-sc-one-half .blog-entry.blog-medium-style .entry-format { right:0; }
		
		.dt-sc-one-column .blog-entry.blog-medium-style.dt-blog-medium-highlight .entry-body { display:none; }
		.page-with-sidebar.with-both-sidebar .dt-sc-one-column .blog-entry.blog-medium-style.dt-blog-medium-highlight .entry-thumb, .page-with-sidebar.with-both-sidebar .dt-sc-one-column .blog-entry.blog-medium-style.dt-blog-medium-highlight .entry-details { width:100%; }
		
		
		/* Portfolio */
		
		.with-both-sidebar .portfolio.dt-sc-one-half.with-sidebar, .with-both-sidebar .portfolio.dt-sc-one-third.with-sidebar, .with-both-sidebar .portfolio.dt-sc-one-fourth.with-sidebar, .page-with-sidebar .dt-sc-portfolio-container .hover-icons-only.dt-sc-one-third, .page-with-sidebar .dt-sc-portfolio-container .column.no-space.hover-icons-only.dt-sc-one-third { width:100%; }
		
		.dt-sc-portfolio-container .dt-sc-one-tenth, .dt-sc-portfolio-container .column.no-space.dt-sc-one-tenth { width:20%; }
 					
		.page-with-sidebar .portfolio.type1.dt-sc-one-third .image-overlay h2, .page-with-sidebar .portfolio.type1.dt-sc-one-fourth .image-overlay h2, .with-both-sidebar .portfolio.type1 .image-overlay h2 { font-size:20px; }
		.page-template-tpl-portfolio .portfolio.type1.dt-sc-one-fourth .image-overlay h2, .portfolio.hover-icons-only.dt-sc-one-third .image-overlay h2 { font-size:18px; }
		.page-template-tpl-portfolio .portfolio.type1.dt-sc-one-fourth.no-space .image-overlay-details .categories { display:none; }
				
		.page-with-sidebar .portfolio.type1.dt-sc-one-fourth .image-overlay .links a span { font-size: 20px; line-height: 45px; }
		.page-with-sidebar .portfolio.type1.dt-sc-one-fourth .image-overlay .links a { height: 45px;  width: 45px;  }	
		
		.content-full-width .portfolio.dt-sc-one-fourth.hover-modern-title .image-overlay h2 { font-size:15px; }
		.content-full-width .portfolio.dt-sc-one-fourth.hover-modern-title .image-overlay .links a { margin:10px 0 0 10px; }
			
		.dt-sc-portfolio-sorting.type2 { width:100%; left:0; position:static; top:0; }
		.dt-sc-portfolio-sorting.type2 a { display:inline-block; padding-right:10px; } 
		.left-header.fixed-left-header .portfolio-fullwidth-container .content-full-width .dt-sc-portfolio-container, .fixed-left-header .dt-sc-portfolio-sorting.type2 { width:100%; }
		.fixed-left-header .dt-sc-portfolio-sorting.type2 { text-align:center; }
		
		/* Type2 **/
		.page-with-sidebar .dt-sc-portfolio-sorting.type2 a { padding-left:12px; padding-right:12px; }
		.with-both-sidebar .dt-sc-portfolio-sorting.type2:before { content:none; }
		.with-both-sidebar .dt-sc-portfolio-sorting.type2 { width:100%; box-sizing: border-box; }
		.dt-sc-portfolio-sorting.type2 { padding:0px 20px 0 10px; }
		
 		.portfolio.dt-sc-one-fourth .image-overlay h2, .portfolio.dt-sc-one-fourth.hover-icons-only .image-overlay .links a { font-size:15px; text-transform:capitalize; }
		.portfolio.dt-sc-one-fourth.hover-icons-only .image-overlay .links { bottom:40px; }
		.portfolio.dt-sc-one-fourth.hover-with-gallery-thumb .image-overlay-details h2, .portfolio.dt-sc-one-third.hover-with-gallery-thumb .image-overlay-details h2, .portfolio.hover-with-gallery-thumb .image-overlay-details h2 { font-size: 16px; }
		
		.page-with-sidebar .dt-sc-portfolio-sorting.type2:before { content:none; }
		
		/** Type3 **/
		.with-both-sidebar .portfolio.hover-title-overlay .image-overlay .links a { font-size:14px; }
		.portfolio.hover-title-overlay .image-overlay .links a { font-size: 12px; }
		.portfolio.hover-title-overlay .image-overlay-details { right:15px; }
		.page-with-sidebar .portfolio.hover-title-overlay.dt-sc-one-third .image-overlay h2 { font-size: 17px; }
  		
		.type7.no-space.portfolio.dt-sc-one-fourth .image-overlay-details { display:none; }
		.portfolio_content_middle .wpb_wrapper > p { display:none; }
		.portfolio.hover-presentation:hover .image-overlay .links { bottom:-2px; }
		.dt-sc-one-third.portfolio.hover-presentation .image-overlay h2, .dt-sc-one-third.portfolio.hover-presentation.no-space .image-overlay h2 { font-size:12px; }
 		.portfolio_content_middle .portfolio.hover-presentation .image-overlay-details { display:none; }
		
		.portfolio.dt-sc-one-seventh.hover-overlay .image-overlay .links a { width: 25px; height: 25px; line-height: 25px; font-size: 13px; }
		.portfolio.dt-sc-one-seventh.hover-overlay .image-overlay .links a span { line-height: 25px; }
		.portfolio.dt-sc-one-seventh.hover-overlay .image-overlay .links { height: 25px; }
		
		.page-with-sidebar .portfolio.hover-girly.dt-sc-one-fourth .image-overlay-details .categories { display:block; }
		.portfolio.hover-girly .image-overlay h2 { font-size:17px; }
		.page-with-sidebar.with-both-sidebar .portfolio.hover-icons-only .image-overlay-details { display: block; }
		.page-with-sidebar .dt-sc-portfolio-container .dt-sc-one-fourth, .page-with-sidebar .dt-sc-portfolio-container .column.no-space.dt-sc-one-fourth { width: 50%; }
		.page-with-sidebar .dt-sc-portfolio-categories-list .dt-sc-one-fourth { width: 50%; margin:0; padding:0 10px 20px; }
		.page-with-sidebar.with-both-sidebar .dt-sc-portfolio-container .dt-sc-one-fourth, .page-with-sidebar.with-both-sidebar .dt-sc-portfolio-container .column.no-space.dt-sc-one-fourth { width: 100%; }
		.post-nav-container.type4 .post-prev-link, .post-nav-container.type4 .post-next-link { position:inherit; transform: inherit; display:inline-block; }
		.post-nav-container.type4 { margin: 20px 0; line-height: 24px; }
 		
		.dt-sc-portfolio-fullpage-carousel-content { bottom:100px; }
		
		.fullpage-portfolio .dt-portfolio-single-details { max-height:300px; overflow-y: scroll; }
		.fullpage-portfolio .dt-portfolio-single-details::-webkit-scrollbar { display: none; }
		.dt-sc-portfolio-fullwidth-container.gallery-list .dt-sc-fixed-content #footer { position:static; width:100%; }
		
		.portfolio.hover-with-gallery-list .image-overlay h2 { font-size:24px; }
		.portfolio.dt-sc-one-third.hover-with-gallery-list .image-overlay h2 { font-size: 15px; }
		.portfolio.dt-sc-one-half.hover-with-gallery-list .image-overlay-container > p { display:none; } 
		.portfolio.dt-sc-one-half.hover-with-gallery-list .image-overlay h2 { font-size:19px; }
		.portfolio.dt-sc-one-half.hover-with-gallery-list .image-overlay-container > ul li:nth-child(4), .portfolio.dt-sc-one-half.hover-with-gallery-list .image-overlay-container > ul li:nth-child(5), .portfolio.dt-sc-one-half.hover-with-gallery-list .image-overlay-container > ul li:nth-child(6) { display: none; }
		
		.portfolio.dt-sc-one-fourth.hover-grayscale:hover .image-overlay-details, .portfolio.dt-sc-one-third.hover-grayscale:hover .image-overlay-details { right:0; }
		
		
 		/* Contact */
		
		/** Restaurant **/		
		.restaurant-contact-bg:before { content: none; }		
		.restaurant-contact-bg > .vc_column-inner { padding-right: 0 !important; }
		
		.restaurant-contact-form { float: none; display: block; max-width: 100%; margin: 0 auto; width: 80%; }
		
		/** Fitness **/
		.fitness-contact-boxes .wpb_text_column:nth-child(3) { padding-top:55px; }
		
		
		
		/* Custom Class */
		
		/*----*****---- << Hosting >> ----*****----*/
		.hosting-contact-info-container .dt-sc-contact-info.type7 span { left:10px; }
		
		/*----*****---- << Restaurant >> ----*****----*/
		.dt-sc-contact-details-on-map .dt-sc-map-overlay { padding: 72px 0 0; }
		
		/*----*****---- << Miscellaneous >> ----*****----*/
		.dt-sc-text-with-icon { font-size: 13px; }
		
		
		/*--------------------------------------------------------------
			Woocommerce
		--------------------------------------------------------------*/
		
 		/* Default */	
		.woocommerce .with-both-sidebar ul.products li.product .column.dt-sc-one-half, .with-both-sidebar .woocommerce ul.products li.product .column.dt-sc-one-half, .woocommerce-page .with-both-sidebar ul.products li.product .column.dt-sc-one-half, 
		.woocommerce .with-both-sidebar ul.products li.product .column.dt-sc-one-third, .with-both-sidebar .woocommerce ul.products li.product .column.dt-sc-one-third, .woocommerce-page .with-both-sidebar ul.products li.product .column.dt-sc-one-third, 
		.woocommerce .with-both-sidebar ul.products li.product .column.dt-sc-one-fourth, .with-both-sidebar .woocommerce ul.products li.product .column.dt-sc-one-fourth, .woocommerce-page .with-both-sidebar ul.products li.product .column.dt-sc-one-fourth { margin-left: 0; width: 100%; }
		
		.with-left-sidebar ul.products li.product .column.dt-sc-one-fourth,
		.with-right-sidebar ul.products li.product .column.dt-sc-one-fourth { width: 49%; }
		
		.with-left-sidebar ul.products li.product.first .column.dt-sc-one-fourth,
		.with-right-sidebar ul.products li.product.first .column.dt-sc-one-fourth { margin-left: 2%; }
		
		.with-left-sidebar ul.products li.product:nth-child(2n+1) .column.dt-sc-one-fourth,
		.with-right-sidebar ul.products li.product:nth-child(2n+1) .column.dt-sc-one-fourth { clear: both; margin-left: 0; }		
		
		
		.woocommerce ul.products li.product-type-variable:not(.has-default-attributes) .product-details .product-price, .woocommerce ul.products li.product-type-variable:not(.has-default-attributes) .product-details .product-price .price { width: 100%; }
		.woocommerce ul.products li.product-type-variable:not(.has-default-attributes) .product-details .product-rating-wrapper { display: none; position: static; }
		.woocommerce ul.products li.product.product-type-variable:not(.has-default-attributes):hover .price { padding: 0;  text-align: center; }
		.woocommerce ul.products li.product.product-type-variable:not(.has-default-attributes):hover .product-details .product-rating-wrapper { display: inline-block; }
		
		.woocommerce ul.products li.product-type-variable:not(.has-default-attributes), .woocommerce ul.products li.product-type-variable:not(.has-default-attributes) .product-wrapper, .woocommerce ul.products li.product-type-variable:not(.has-default-attributes) .product-details .product-rating-wrapper { -webkit-transition: all 300ms linear 0s; -moz-transition: all 300ms linear 0s; -ms-transition: all 300ms linear 0s; -o-transition: all 300ms linear 0s; transition: all 300ms linear 0s;  }		
		
		
		.woocommerce table.shop_table th, .woocommerce-page table.shop_table th, .woocommerce table.shop_table td, .woocommerce-page table.shop_table td, .woocommerce table.cart td.product-name a, .woocommerce table.cart td.product-price, .woocommerce table.cart td.product-subtotal, .woocommerce table.shop_table tbody th, .woocommerce table.shop_table tfoot td, .woocommerce table.shop_table tfoot th { font-weight: normal; }	
		
		.woocommerce div.product .images .thumbnails .yith_magnifier_gallery li { width: 92px !important; }
			
		.woocommerce .page-with-sidebar div.product .images .thumbnails .yith_magnifier_gallery li,
		.woocommerce-page .page-with-sidebar div.product .images .thumbnails .yith_magnifier_gallery li { width: 67px !important; }
			
		.woocommerce .page-with-sidebar.with-both-sidebar div.product .images .thumbnails .yith_magnifier_gallery li,
		.woocommerce-page .page-with-sidebar.with-both-sidebar div.product .images .thumbnails .yith_magnifier_gallery li { width: 41px !important; }		
		
		.woocommerce .page-with-sidebar.with-both-sidebar div.product .woocommerce-tabs ul.tabs li a { font-size: 12px; }
		
 		
		/* Type 1 - Fashion */		
		.woo-type1 .page-with-sidebar div.product .woocommerce-tabs ul.tabs li a { font-size: 20px; letter-spacing: 0.5px; }
		
		
		/* Type 2 - Jewel */
		.woo-type2 .page-with-sidebar.with-both-sidebar div.product .woocommerce-tabs ul.tabs li a { font-size: 12px; letter-spacing: 0; padding: 5px 15px; }
		
		
		/* Type 7 - University */
		.woo-type7 ul.products li.product .product-details .product-price { padding: 0 10px; }
		
		.woo-type7 ul.products li.product:hover .product-details .product-rating-wrapper { right: 8px; }	
		
		
		/* Type 8 - Insurance */
		.woo-type8 ul.products li.product .product-details .product-price { padding: 0 10px; }
		
		.woo-type8 ul.products li.product:hover .product-details .product-rating-wrapper { right: 8px; }
				
		.woo-type8 ul.products li.product .product-details h5:before { width: calc(100% - 100px); }
		.woo-type8 ul.products li.product:hover .product-details h5:before { width: calc(100% - 50px); }		
		
		.woo-type8 ul.products li.product .product-details h5:before { width: calc(100% - 100px); }
		.woo-type8 ul.products li.product:hover .product-details h5:before { width: calc(100% - 50px); }
		
		
		/* Type 9 - Plumber */
		.woo-type9 ul.products li.product .product-details .product-price { padding: 0 10px; }
		
		.woo-type9 ul.products li.product:hover .product-details .product-rating-wrapper { right: 8px; }
				

		.woo-type9 ul.products li.product .product-thumb a.add_to_cart_button, .woo-type9 ul.products li.product .product-thumb a.button.product_type_simple, .woo-type9 ul.products li.product .product-thumb a.button.product_type_variable, .woo-type9 ul.products li.product .product-thumb a.added_to_cart.wc-forward { left: -65px; }
		
		.woo-type9 ul.products li.product .product-thumb a.add_to_wishlist, .woo-type9 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a, .woo-type9 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a { right: -65px; }
		
		.woo-type9 ul.products li.product:hover .product-thumb a.add_to_cart_button, .woo-type9 ul.products li.product:hover .product-thumb a.button.product_type_simple, .woo-type9 ul.products li.product:hover .product-thumb a.button.product_type_variable, .woo-type9 ul.products li.product:hover .product-thumb a.added_to_cart.wc-forward { left: 0; right: 65px; }
		
		.woo-type9 ul.products li.product:hover .product-thumb a.add_to_wishlist, .woo-type9 ul.products li.product:hover .product-thumb .yith-wcwl-wishlistaddedbrowse a, .woo-type9 ul.products li.product:hover .product-thumb .yith-wcwl-wishlistexistsbrowse a { left: 65px; right: 0; }					
				
		
		.woo-type9 ul.products li.product .product-details h5:before { width: 60%; }
		
		
		/* Type 10 - Medical */
		.woo-type10 ul.products li.product .product-details .product-price,
		.woo-type13 ul.products li.product .product-details .product-price { padding: 0 10px; }
		
		.woo-type10 ul.products li.product:hover .product-details .product-rating-wrapper,
		.woo-type13 ul.products li.product:hover .product-details .product-rating-wrapper { right: 8px; }
					
		
		.woo-type10 ul.products li.product .product-details .product-rating-wrapper .star-rating { margin: 5px 0 0; }
		
		
		/* Type 12 - Attorney */
		.woo-type12 ul.products li.product .product-details h5 { font-size: 12px; }
		
		
		/* Type 13 - Architecture */
		.woo-type13 ul.products li.product .product-details .product-price { padding: 0 10px; }
		.woo-type13 ul.products li.product:hover .product-details .product-rating-wrapper { right: 8px; }
		
		.woo-type13 ul.products li.product .onsale span, .woo-type13 ul.products li.product .out-of-stock span, 
		.woo-type13 ul.products li.product .featured-tag span, .woo-type13.woocommerce.single-product .featured-tag span { font-size: 13px; }
		
		.woo-type13 ul.products li.product .featured-tag i, .woo-type13.woocommerce.single-product .featured-tag i { font-size: 14px; }	
		
		.woo-type13 ul.products li.product.product-type-variable .dt-sc-one-fourth .product-details .product-price .price del,
		.woo-type13 .page-with-sidebar ul.products li.product.product-type-variable .dt-sc-one-fourth .product-details .product-price del,
		.woo-type13 .page-with-sidebar .woocommerce.columns-4 ul.products li.product.product-type-variable .dt-sc-one-fourth .product-details .product-price del,
		.woo-type13 .page-with-sidebar ul.products li.product.product-type-variable .dt-sc-one-third .product-details .product-price del,
		.woo-type13 .page-with-sidebar .woocommerce.columns-3 ul.products li.product.product-type-variable .dt-sc-one-third .product-details .product-price del { display: none; }
		
		
		/* Type 14 - Fitness */
		.woo-type14 ul.products li.product .product-details .product-rating-wrapper .star-rating { margin: 6px 0 0; }
		
		
 		/* Type 15 - Hotel */
		.woo-type15.woocommerce ul.products li.product .product-thumb a.add_to_cart_button, .woo-type15.woocommerce ul.products li.product .product-thumb a.button.product_type_simple, .woo-type15.woocommerce ul.products li.product .product-thumb a.button.product_type_variable, .woo-type15.woocommerce ul.products li.product .product-thumb a.added_to_cart.wc-forward, .woo-type15.woocommerce ul.products li.product .product-thumb a.add_to_wishlist, .woo-type15.woocommerce ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a, .woo-type15.woocommerce ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a, .woo-type15 .woocommerce ul.products li.product .product-thumb a.add_to_cart_button, .woo-type15 .woocommerce ul.products li.product .product-thumb a.button.product_type_simple, .woo-type15 .woocommerce ul.products li.product .product-thumb a.button.product_type_variable, .woo-type15 .woocommerce ul.products li.product .product-thumb a.added_to_cart.wc-forward, .woo-type15 .woocommerce ul.products li.product .product-thumb a.add_to_wishlist, .woo-type15 .woocommerce ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a, .woo-type15 .woocommerce ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a { height: 36px; width: 36px; }
		

		.woo-type15 ul.products li.product .product-thumb a.add_to_cart_button:before, .woo-type15 ul.products li.product .product-thumb a.button.product_type_simple:before, .woo-type15 ul.products li.product .product-thumb a.button.product_type_variable:before, .woo-type15 ul.products li.product .product-thumb a.added_to_cart.wc-forward:before, .woo-type15 ul.products li.product .product-thumb a.add_to_wishlist:before, .woo-type15 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:before, .woo-type15 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:before { font-size: 16px; line-height: 36px; }

		.woo-type15 ul.products li.product .product-thumb a.add_to_cart_button, .woo-type15 ul.products li.product .product-thumb a.button.product_type_simple, .woo-type15 ul.products li.product .product-thumb a.button.product_type_variable, .woo-type15 ul.products li.product .product-thumb a.added_to_cart.wc-forward, .woo-type15 ul.products li.product:hover .product-thumb a.add_to_cart_button, .woo-type15 ul.products li.product:hover .product-thumb a.button.product_type_simple, .woo-type15 ul.products li.product:hover .product-thumb a.button.product_type_variable, .woo-type15 ul.products li.product:hover .product-thumb a.added_to_cart.wc-forward { right: 70px; }
		
		.woo-type15 ul.products li.product .product-thumb a.add_to_wishlist, .woo-type15 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a, .woo-type15 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a, 
		.woo-type15 ul.products li.product:hover .product-thumb a.add_to_wishlist, .woo-type15 ul.products li.product:hover .product-thumb .yith-wcwl-wishlistaddedbrowse a, .woo-type15 ul.products li.product:hover .product-thumb .yith-wcwl-wishlistexistsbrowse a { left: 70px; }	
		
		.woo-type15.woocommerce ul.products li.product .product-thumb .yith-wcwl-add-button img, .woo-type15 .woocommerce ul.products li.product .product-thumb .yith-wcwl-add-button img { right: calc(50% - 90px); }
		
		
		/* Type 18 - Event */
		.woo-type18 ul.products li.product .dt-sc-one-fourth .product-details .price { font-size: 11px; }
		.woo-type18 ul.products li.product .dt-sc-one-fourth .product-details .product-rating-wrapper .star-rating { font-size: 9px; margin: 10px 0 0; }
		
		.woo-type18 ul.products li.product .dt-sc-one-half .product-thumb:after, .woo-type18 ul.products li.product .dt-sc-one-half .product-thumb:before,
		.woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-half .product-thumb:after, .woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-half .product-thumb:before { top: -96px; }
		
		.woo-type18 ul.products li.product .dt-sc-one-half .product-thumb .image:before, .woo-type18 ul.products li.product .dt-sc-one-half .product-thumb .image:after,
		.woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-half .product-thumb .image:before, .woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-half .product-thumb .image:after { bottom: -96px; }		
				
		.woo-type18 ul.products li.product .dt-sc-one-fourth .product-thumb:after, .woo-type18 ul.products li.product .dt-sc-one-fourth .product-thumb:before,
		.woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-third .product-thumb:after, .woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-third .product-thumb:before { top: -39px; }
		
		.woo-type18 ul.products li.product .dt-sc-one-fourth .product-thumb .image:before, .woo-type18 ul.products li.product .dt-sc-one-fourth .product-thumb .image:after,
		.woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-third .product-thumb .image:before, .woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-third .product-thumb .image:after { bottom: -39px; }		
		
		
		.woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-fourth .product-thumb:after, .woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-fourth .product-thumb:before,
		.woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-third .product-thumb:after, .woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-third .product-thumb:before { top: -100px; }
		
		.woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-fourth .product-thumb .image:before, .woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-fourth .product-thumb .image:after,
		.woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-third .product-thumb .image:before, .woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-third .product-thumb .image:after { bottom: -100px; }		
			
		
		.woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-fourth .product-thumb:after, .woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-fourth .product-thumb:before { top: -72px; }
		.woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-fourth .product-thumb .image:before, .woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-fourth .product-thumb .image:after { bottom: -72px; }		

		
		.woo-type18 ul.products li.product .dt-sc-one-third .product-thumb:after, .woo-type18 ul.products li.product .dt-sc-one-third .product-thumb:before { top: -56px; }
		.woo-type18 ul.products li.product .dt-sc-one-third .product-thumb .image:before, .woo-type18 ul.products li.product .dt-sc-one-third .product-thumb .image:after { bottom: -56px; }		
 		
		.woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-half .product-thumb:after, .woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-half .product-thumb:before { top: -68px; }
		.woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-half .product-thumb .image:before, .woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-half .product-thumb .image:after { bottom: -68px; }


		/* Type 19 - Nightclub */
		.woo-type19 ul.products li.product .product-details .price { font-size: 12px; }
		.woo-type19 ul.products li.product .product-details .product-rating-wrapper .star-rating { font-size: 10px; margin: 15px 0 0; }					
				
		.woo-type19 .page-with-sidebar ul.products li.product .dt-sc-one-fourth .product-thumb .image, .woo-type19 .page-with-sidebar .woocommerce.columns-4 ul.products li.product .dt-sc-one-fourth .product-thumb .image, .woo-type19 .with-both-sidebar ul.products li.product .dt-sc-one-third .product-thumb .image, .woo-type19 .with-both-sidebar .woocommerce.columns-3 ul.products li.product .dt-sc-one-third .product-thumb .image { margin: 100px 0; }
		
		
		/* Type 20 - Yoga */
		.woo-type20 ul.products li.product .product-wrapper { padding: 20px 20px 0; }
		
		.woo-type20 ul.products li.product .dt-sc-one-fourth .product-details .price { font-size: 10px; }
		.woo-type20 ul.products li.product .dt-sc-one-fourth .product-details .product-rating-wrapper .star-rating { font-size: 10px; margin: 12px 0 0; }
		
		
		/* Type 21 - Styleshop */
		.woo-type21.woocommerce.single-product div.product div.images div.thumbnails { padding: 0 10px; }
		
		
		/* Tribe Events */
		.type3.tribe_events .event-date { font-size: 30px; }
		.type3.tribe_events .event-date span { font-size: 40px; }
		
		/*** Events ***/
		.type2.tribe_events .date-wrapper p > span { font-size:70px; }
		.type2.tribe_events .date-wrapper p { font-size:13px; }
		.type2.tribe_events .event-details, .type2.tribe_events .event-organize, .type2.tribe_events .event-venue { padding:15px; box-shadow:0 0 0 15px rgba(255, 255, 255, 0.7); }
		
		.type3.tribe_events .wpb_column { width:49%; }
		
		.type5.tribe_events .container { width:900px; }
		.type5.tribe_events .nav-top-links a { margin-top:1px; }
		.type5.tribe_events .wpb_column { width:49%; }
		.type5.tribe_events .wpb_column.dt-sc-one-fifth { width:100%; margin:0; }
		.type5.tribe_events .data-wrapper p { font-size:22px; }
		.type5.tribe_events .data-wrapper p span { font-size:47px; }
		
		.type5.tribe_events .column { margin: 0; width: 100%; }
		
		
		/* Shortcodes */
		
		/* Blockquote */
		blockquote.type2 cite:before { left: -42px; right: auto; width: calc(90% - 32px); }	
		.dt-sc-testimonial.type3 blockquote { padding:0 5px; }
		.dt-sc-testimonial-wrapper.type8 .carousel-arrows { bottom:0px; }
		
		
		.dt-sc-contact-info.type5 h6 { font-size: 15px; margin: 20px 0 0; }
		
		
		/** Contact Info **/
		.dt-sc-contact-info.type6, .dt-sc-contact-info.type6 p, .dt-sc-contact-info.type6 a { font-size: 12px; }
		
		.dt-sc-contact-info.type7, .dt-sc-contact-info.type7 p, .dt-sc-contact-info.type7 a { font-size: 11px; }
		.dt-sc-contact-info.type3, .dt-sc-contact-info.type3 p, .dt-sc-contact-info.type3 a { font-size:15px; }			
				
		.dt-sc-contact-info.type7 { padding-left: 70px; }
		.dt-sc-contact-info.type7 span { line-height: 45px; width: 45px; }
		.dt-sc-contact-info.type7 span:after { height: 45px; width: 45px; -webkit-border-radius: 8px; -moz-border-radius: 8px; -ms-border-radius: 8px; -o-border-radius: 8px; border-radius: 8px; }
		.dt-sc-contact-info.type3 h6 { font-size: 23px; }
		
		
		/** Counters **/
		.vc_col-md-6 .dt-sc-counter.type1 { margin-bottom: 25px; }
		
		*.dt-sc-counter.type3, .vc_row-no-padding .dt-sc-counter.type3 { padding: 0; }
		
		.dt-sc-counter.type3.diamond-square .dt-sc-couter-icon-holder .icon-wrapper:before,
		.dt-sc-counter.type3.diamond-square .dt-sc-couter-icon-holder .icon-wrapper:after,
		.vc_row-no-padding .dt-sc-counter.type3.diamond-square .dt-sc-couter-icon-holder .icon-wrapper:before,
		.vc_row-no-padding .dt-sc-counter.type3.diamond-square .dt-sc-couter-icon-holder .icon-wrapper:after { left: -2px; }		
				
		.dt-sc-counter.type5::before { height: 180px; width: 180px; }
		.dt-sc-counter.type5::after { height: 160px; width: 160px; }
		
		
 		/** Donut Chart Medical **/
		.dt-sc-one-fifth .dt-sc-donutchart-medium { width:160px; }
		.dt-sc-one-fifth .dt-sc-donutchart, .dt-sc-donutchart-medium canvas { width: 150px !important; height: 150px !important; }
		.dt-sc-one-fifth .dt-sc-donutchart-medium .donutchart-text { width: 150px !important; line-height: 150px !important; }
		
		
		/** Dropcap **/
		.dt-sc-dropcap.dt-sc-dropcap-default { font-size: 32px; padding-right: 15px; }
		.dt-sc-dropcap.dt-sc-dropcap-circle, .dt-sc-dropcap.dt-sc-dropcap-bordered-circle,
		.dt-sc-dropcap.dt-sc-dropcap-square, .dt-sc-dropcap.dt-sc-dropcap-bordered-square { margin-right: 15px; }
		
		
		/** Event Shortcodes **/
		.dt-sc-event-image-caption .dt-sc-image-content, .dt-sc-event-image-caption .dt-sc-image-wrapper { width:100%; }
		.dt-sc-event-image-caption .dt-sc-image-content::before { bottom:-22px; top:auto; transform: rotate(90deg); border-left-width:16px; right:30px; }
		.dt-sc-event-image-caption .dt-sc-image-wrapper img { width:100%; }
		
		
		/** Fancy Boxes **/
		.dt-sc-error-box:before, .dt-sc-warning-box:before, .dt-sc-success-box:before, .dt-sc-info-box:before { top: 16px; }
		
		
		/** Hexagons Images **/
		.dt-sc-hexagons li { height: 60px; width: 119px; }
		.dt-sc-hexagons li::before, .dt-sc-hexagons li::after, .dt-sc-hexagons li .dt-sc-hexagon-overlay::before, .dt-sc-hexagons li .dt-sc-hexagon-overlay::after { left: 14.934px; width: 86px; height: 83px; }
		.dt-sc-hexagons li::before, .dt-sc-hexagons li .dt-sc-hexagon-overlay::before { top: -43.066px; }
		.dt-sc-hexagons li::after, .dt-sc-hexagons li .dt-sc-hexagon-overlay::after { bottom: -44.066px; }
		.dt-sc-hexagons li span { font-size: 48px; height: 48px; }
		
		.dt-sc-hexagons li .dt-sc-hexagon-overlay::before { height: 89px; left: 17.934px; top: -42.066px; }
		.dt-sc-hexagons li .dt-sc-hexagon-overlay::after { bottom: -41.066px; height: 83px; left: 16.934px; width: 87px; }
		.dt-sc-hexagons li .dt-sc-hexagon-overlay p { font-size:14px; }
		
		.dt-sc-hexagons li:first-child { top:-34px; }
		.dt-sc-hexagons li:nth-child(2) { left: -47%; top: 87px; }
		.dt-sc-hexagons li:nth-child(3) { left:48%; top:88px; }
		.dt-sc-hexagons li:nth-child(4) { left:-48%; top: 340px; }
		.dt-sc-hexagons li:nth-child(5) { left: 47%; top: 339px; }
		.dt-sc-hexagons li:last-child { bottom:-34px; }
		
		.dt-sc-hexagon-image-overlay { height:130px; }
		.dt-sc-hexagon-image-overlay h3 { font-size:23px; }
		.dt-sc-hexagon-image-overlay h2 { font-size:22px; }
		.dt-sc-hexagon-image-overlay h2::before { height:37px; }
		
		
		/** Icon Box **/
		.dt-sc-icon-box.type1 { padding: 35px 25px; }
		.dt-sc-icon-box.type1 .icon-content h4 { font-size: 13px; letter-spacing: 0; }
		
		.dt-sc-icon-box.type2 .icon-wrapper { margin-right: 8px; }
		.dt-sc-icon-box.type2.alignright .icon-wrapper { margin-left: 8px; }
 		.dt-sc-icon-box.type2 .icon-content h4 { font-size: 17px; }
		
		.dt-sc-icon-box.type4 .icon-content h4 { font-size: 16px; }		
		
		.dt-sc-icon-box.type5.no-icon { padding: 0 30px 0 0; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -ms-box-sizing: border-box; -o-box-sizing: border-box; box-sizing: border-box; }
		.dt-sc-icon-box.type5.no-icon .icon-content h4 { padding: 0 0 20px; }	
		
		.vc_row-no-padding .dt-sc-icon-box.type5.no-icon { padding: 0; }
		
		.dt-sc-icon-box.type6 .icon-wrapper { height: 80px; margin: 0 10px 15px 0; width: 80px; }
		.dt-sc-icon-box.type6 .icon-wrapper span { font-size: 30px; line-height: 60px; }
		.dt-sc-icon-box.type6 .icon-content h4 { font-size: 14px; letter-spacing: 0; margin-top: 95px; }
		
		.dt-sc-icon-box.type9 .icon-content h4 { font-size: 18px; }
		/*.dt-sc-icon-box.type9 .icon-wrapper { height:100% !important; }*/
		
		.dt-sc-icon-box.type11 .dt-sc-button.medium { padding: 13px 45px 11px 25px; }
		
		.dt-sc-icon-box.type12 { min-height: 180px; }
		.dt-sc-icon-box.type12 .icon-wrapper { margin: 0 10px 0 0; top: 60px; }
		.dt-sc-icon-box.type12 .icon-wrapper span { font-size: 24px; }
		.dt-sc-icon-box.type12 .icon-content { top: 56px; }
		.dt-sc-icon-box.type12:hover .icon-content, .dt-sc-icon-box.type12.selected .icon-content { top: 15px; }
		
		.dt-sc-icon-box.type14 { min-height: 325px; }		
		.dt-sc-icon-box.type14 .icon-content h4 { font-size: 16px; }
		
		/** Photography **/
		.dt-sc-icon-box.type11 .dt-sc-button.medium { font-size: 13px; }
		
		
		/** Image Caption **/
		.dt-sc-image-caption.type2 .dt-sc-image-content { padding: 20px 15px; }
		
		.dt-sc-image-caption.type5 img { width: 100%; }
		
		.dt-sc-image-caption.type6 { overflow: hidden; }
		.dt-sc-image-caption.type6 .dt-sc-image-wrapper img { max-height: none; width: 100%; }
		
		.dt-sc-image-caption.type7 .dt-sc-image-content { margin-top: 35px; }		
		.dt-sc-image-caption.type7.bottom-content .dt-sc-image-content { margin-top: 55px; }
		
		.dt-sc-image-caption.type2 .dt-sc-image-content p { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
		
		
 		/** Newsletter **/
		.dt-sc-newsletter-section .dt-sc-subscribe-frm input[type="email"], 
		.dt-sc-newsletter-section .dt-sc-subscribe-frm input[type="text"] { margin: 0 15px 0 0; width: 34%; }
		
		.dt-sc-newsletter-section.type3 .dt-sc-subscribe-frm { width: 80%; }
		.dt-sc-newsletter-section.type3 .dt-sc-subscribe-frm input[type="submit"] { font-size: 13px; padding: 18px 15px 15px; }
		
		.dt-sc-newsletter-section.type6 .dt-sc-subscribe-frm input[type="email"],
		.dt-sc-newsletter-section.type6 .dt-sc-subscribe-frm input[type="submit"] { font-size: 20px; }
		
		.dt-sc-newsletter-section.type7 .newsletter-tooltip { width: 20%; }
		
		.dt-sc-newsletter-section.type6 .dt-sc-subscribe-frm input[type="submit"] { padding: 16px 25px 15px; }
		
		
		/** Pricing Tables **/
		.dt-sc-pr-tb-col.type2 .dt-sc-tb-header::before,
		.side-navigation-content .dt-sc-pr-tb-col.type2 .dt-sc-tb-header::before { height: 124px; width: 124px; }
		
		.dt-sc-pr-tb-col.type2 .dt-sc-tb-title,
		.side-navigation-content .dt-sc-pr-tb-col.type2 .dt-sc-tb-title { height: 103px; }
		
		.dt-sc-pr-tb-col.type2 .dt-sc-tb-header .dt-sc-tb-title::before, .dt-sc-pr-tb-col.type2 .dt-sc-price::before,
		.side-navigation-content .dt-sc-pr-tb-col.type2 .dt-sc-tb-header .dt-sc-tb-title::before, .side-navigation-content .dt-sc-pr-tb-col.type2 .dt-sc-price::before { height: 126px; width: 126px; }
		
		.dt-sc-pr-tb-col.type2 .dt-sc-tb-header .dt-sc-tb-title::before,
		.side-navigation-content .dt-sc-pr-tb-col.type2 .dt-sc-tb-header .dt-sc-tb-title::before { top: 39px; }
		
		.dt-sc-pr-tb-col.type2 .dt-sc-price::before,
		.side-navigation-content .dt-sc-pr-tb-col.type2 .dt-sc-price::before { top: -64px; }
		
				
		.dt-sc-pr-tb-col.type2 .dt-sc-tb-title h5 { font-size: 15px; line-height: 20px; margin: 20px 0 0; }
		.dt-sc-pr-tb-col.type2 .dt-sc-tb-title h5 span { font-size: 25px; }
		
		.dt-sc-pr-tb-col.type2 .dt-sc-price { height: 85px; }
		.dt-sc-pr-tb-col.type2 .dt-sc-price h6 { font-size: 20px; }		
		.dt-sc-pr-tb-col.type2 .dt-sc-price h6 sup { font-size: 16px; top: auto; }			
		
		.dt-sc-pr-tb-col.type2 .dt-sc-tb-content li { font-size: 14px; }
		
		
		/** Progress Bar **/
		.dt-sc-progress-wrapper .dt-sc-bar-title { font-size: 12px; }
		
		
		/** Shortcodes **/
		.dt-sc-tabs-vertical-frame-content img { position:inherit !important; }		
				
		
		/** Horizontal Tabs **/
		ul.dt-sc-tabs-horizontal > li > a,
		ul.dt-sc-tabs-horizontal-frame > li > a { font-size: 13px; }
		
		ul.dt-sc-tabs-horizontal > li > a { padding: 10px 15px 15px; }
		ul.dt-sc-tabs-horizontal-frame > li > a { padding: 10px 15px; }
		
		ul.dt-sc-tabs-horizontal > li > a > span { top: 7px; }
		ul.dt-sc-tabs-horizontal-frame > li > a > span { top: 6px; }


		ul.dt-sc-tabs-vertical-frame > li > a { font-size: 13px; }
		.dt-sc-tabs-horizontal-frame-container.type2 ul.dt-sc-tabs-horizontal-frame > li > a { font-size: 15px; }
		
		.dt-sc-tabs-horizontal-frame-container.type3 ul.dt-sc-tabs-horizontal-frame > li > a { font-size: 13px; }
		.dt-sc-tabs-horizontal-frame-container.type4 ul.dt-sc-tabs-horizontal-frame > li > a { font-size: 14px; }
		
		.dt-sc-tabs-horizontal-frame-container.type2 ul.dt-sc-tabs-horizontal-frame > li > a { padding: 10px 18px; }
		.dt-sc-tabs-horizontal-frame-container.type3 ul.dt-sc-tabs-horizontal-frame > li > a { padding: 11px 20px; }
		.dt-sc-tabs-horizontal-frame-container.type4 ul.dt-sc-tabs-horizontal-frame > li > a { min-width: 25%; }
		
		.dt-sc-tabs-horizontal-frame-container.type6 ul.dt-sc-tabs-horizontal-frame > li > a { font-size: 14px; padding: 18px 10px; }		

		
		/** Vertical Tabs **/
		.dt-sc-tabs-vertical-frame-container.type2 ul.dt-sc-tabs-vertical-frame > li > a,
		.dt-sc-tabs-vertical-frame-container.type3 ul.dt-sc-tabs-vertical-frame > li > a { font-size: 12px; }
		
		.dt-sc-tabs-vertical-frame-container.type2 ul.dt-sc-tabs-vertical-frame > li > a { padding: 15px; }
		
		.dt-sc-tabs-vertical-frame-container.type4 ul.dt-sc-tabs-vertical-frame > li > a { font-size: 15px; }		
		.dt-sc-tabs-vertical-frame-container.type4 ul.dt-sc-tabs-vertical-frame > li > a:before { line-height: 46px; padding-right: 0; }
		.dt-sc-tabs-vertical-frame-container.type4 ul.dt-sc-tabs-vertical-frame > li > a.current:after { right: 27px; }
		
		.dt-sc-tabs-vertical-frame-container.type3 .dt-sc-tabs-vertical-frame-content,
		.dt-sc-tabs-vertical-frame-container.type4 .dt-sc-tabs-vertical-frame-content { padding-left: 50px; }
		
		.dt-sc-tabs-vertical-frame-container.type3 ul.dt-sc-tabs-vertical-frame > li > a.current:before { border-bottom-width: 24px; border-top-width: 24px; }
		
		
		/** Team **/
		.vc_col-md-6 .dt-sc-team, .vc_col-md-12 .dt-sc-team { margin-top: 30px; }
		
		.dt-sc-team .dt-sc-team-thumb img,
		.dt-sc-team.type2 .dt-sc-team-thumb, .dt-sc-team.type2 .dt-sc-team-details { width: 100%; }		
		
		.dt-sc-team.type2.alter .dt-sc-team-details::before { right: 30px; transform: rotate(-90deg); }		
		
		.dt-sc-team-social li { margin: 0 3px; }
		.dt-sc-team-social li a { font-size: 15px; height: 30px; line-height: 30px; width: 30px; }
		
		.dt-sc-team-social.hexagon-border li { margin: 8px 3px; }
		
		.dt-sc-team.type2 .dt-sc-team-details { padding: 50px 25px; }
		.dt-sc-team.type2 .dt-sc-team-details:before { border-bottom-width: 16px; top: -22px; left: 30px; transform: rotate(90deg); -webkit-transform: rotate(90deg); -moz-transform: rotate(90deg); -ms-transform: rotate(90deg); -o-transform: rotate(90deg); }		
	
		.dt-sc-team.type2 .dt-sc-team-details h4 { font-size: 15px; margin-bottom: 5px; }
		.dt-sc-team.type2 .dt-sc-team-details h5 { margin-bottom: 10px; padding-bottom: 10px; }
	
		.dt-sc-team.rounded.hide-social-show-on-hover .dt-sc-team-social { top: 35%; }
		.dt-sc-team.hide-social-show-on-hover .dt-sc-team-social { top: -70px; }
		.dt-sc-team-social.diamond-square-border li { margin: 0 7px; }
		
		.rs_min_height_team_col .wpb_column { min-height: 355px !important; }	
		
		.dt-sc-team.hide-social-show-on-hover .dt-sc-team-social.rounded-border, .dt-sc-team.hide-social-show-on-hover .dt-sc-team-social.square-border { top:-70px; }	
		
		
		/*----*****---- << Testimonial >> ----*****----*/
		.dt-sc-testimonial-images li.selected div { width:65px; height:65px; }
		.dt-sc-testimonial-images li { width:32%;  min-height:114px; }
		.dt-sc-testimonial-images li:nth-child(5) { clear:none; }
		
 		.dt-sc-testimonial.type6::after { left: 60%; }
		.dt-sc-testimonial.type6 .dt-sc-testimonial-author::before { content: none; }
		
		.dt-sc-testimonial.type8 .dt-sc-testimonial-quote blockquote { padding-top: 0; }		
		.dt-sc-testimonial.type8 .dt-sc-testimonial-author cite { right: 10px; }
		.dt-sc-testimonial.type8 .dt-sc-testimonial-quote blockquote q:before { top: 0; }
		.dt-sc-testimonial.type8.alignright .dt-sc-testimonial-author cite { left: 10px; }	
		
		.dt-sc-special-testimonial-container .vc_col-sm-6 { padding:70px 0 70px 65px; }
		
 		
		/** Timeline **/
		.dt-sc-hr-timeline-section.type2 .dt-sc-hr-timeline-thumb { top: 18px; }		
		.dt-sc-hr-timeline-section.type2 .dt-sc-hr-timeline.bottom .dt-sc-hr-timeline-thumb { top: -10px; }	
		
		
		/** Headings - Titles **/
		.dt-sc-title.with-two-color-stripe h5 { padding: 50px 0px 40px 70px; }
		.dt-sc-title.with-two-color-stripe h5:before { left: 50px; }
		
		.dt-sc-triangle-title { height: 450px; width: 100%; }
		.dt-sc-triangle-title:before { height: 250px; top: 100px; width: 250px; }
		.dt-sc-triangle-title:after { height: 230px; top: 110px; width: 230px; }
		.dt-sc-triangle-title h2 { margin: 170px 0 0; }		
		
		.dt-sc-photography-style.vc_col-md-12 { padding-bottom: 0; }
		
		
		/** Toggles **/
		h5.dt-sc-toggle-accordion, h5.dt-sc-toggle, .dt-sc-toggle-frame h5.dt-sc-toggle-accordion, .dt-sc-toggle-frame h5.dt-sc-toggle { font-size: 13px; }
		.type2 h5.dt-sc-toggle-accordion, .type2 h5.dt-sc-toggle { font-size: 24px; }
		.type3 .dt-sc-toggle-frame h5.dt-sc-toggle-accordion, .type3 .dt-sc-toggle-frame h5.dt-sc-toggle { font-size: 14px; }
		
		
		/*----*****---- << Content Shortcodes >> ----*****----*/
		.dt-sc-triangle-img-crop { height: 295px; width: 295px; }
		.dt-sc-triangle-img-crop a { height: 270px; margin-top: 32px; margin-left: 32px; width: 270px; }
		
		.dt-sc-triangle-content { margin-top: -245px; padding: 60px 0 0; left: 39px; width: 260px; }
		.dt-sc-triangle-wrapper.alter .dt-sc-triangle-content { padding: 110px 0 0 0; left: 19px; }
		
		.dt-sc-triangle-content:before { border-left-width: 130px; border-right-width: 130px; border-top-width: 200px; }
		.dt-sc-triangle-wrapper.alter .dt-sc-triangle-content:before { border-bottom-width: 200px; }
		
		.alter .dt-sc-triangle-img { left: 205px; top: -105px; }
		
		
		.dt-sc-triangle-content h4 { font-size: 13px; }
		.dt-sc-triangle-content h5 { font-size: 10px; }
		
		
	}