@charset "utf-8";

/* Table of Content
==================================================
	1.0 - Container
	2.0 - Boxed
	3.0 - Columns
	4.0 - Mega Menu */
	
	
	/*--------------------------------------------------------------
	1.0 - Container
	--------------------------------------------------------------*/
	.wrapper { clear:both; width:100%; margin:0px; padding:0px; float:left; height:100%; }
	.inner-wrapper { margin:0px; width:100%; float:left; padding:0px; overflow:hidden; height:100%; }
	
	.container { width:1170px; margin:0 auto; position:relative; clear:both; }
	
	.container.fill-container, .container.fill-stretch-row, .page-template-tpl-fullwidth .section-wrapper.fullwidth-section .container.fill-stretch-row { width:1200px; }
	.page-template-tpl-fullwidth .vc_row.vc-default-row { margin-left:0px; }	
	
	#main { float:left; clear:both; width:100%; margin:0px; padding:0px; display:block; }
	
	#primary { width:870px; margin:0px; padding:0px 0px 0px; float:left; position:relative; }
	#primary.content-full-width { width:100%; }
	#primary.with-left-sidebar { float:right; margin-left:30px; }
	#primary.with-right-sidebar { float:left; margin-right:30px; }
	#primary.with-both-sidebar { width:570px; float:left; margin:0px 30px; }
	
	
 	#secondary, #secondary-right, #secondary-left { width:270px; margin:0px; float:left; padding:0px; position:relative; z-index:1; }
	#secondary-right.secondary-has-right-sidebar { float:right; }
	#secondary-left.secondary-has-left-sidebar { float:left; }
	#secondary.secondary-has-both-sidebar { margin:0px; }
	
	.page-template-tpl-fullwidth-php #primary { padding-bottom:0px; }
	.left-header.fixed-left-header .portfolio-fullwidth-container .content-full-width .dt-sc-portfolio-container { width:82%; }
	.left-header.fixed-left-header .portfolio-fullwidth-container .dt-sc-one-fourth { width: 24.92%; }
	
	/*----*****---- << Single Page >> ----*****----*/
	.page-template-tpl-blog #primary, .page-template-tpl-portfolio #primary, .page-template-tpl-sidenavigation #primary, .single #primary, 
.archive #primary, .post-type-archive-tribe_events #primary, .woocommerce-page #primary, .single.single-wp_router_page #main, .search.search-results #primary, .search.search-no-results #primary, .home.blog #primary { padding-bottom:80px; }

	.page-template.remove-bottom-spaces #primary, .single.remove-bottom-spaces #primary { padding:0; }
	
	
	/*--------------------------------------------------------------
	2.0 - Boxed
	--------------------------------------------------------------*/
	body.layout-boxed { background-attachment: fixed; }
	.layout-boxed .wrapper { overflow:hidden; }
	.layout-boxed .inner-wrapper { background:#ffffff; }
	
	.layout-boxed .vc_row.vc_row-no-padding { left: -40px !important; margin: 0 auto; width: calc(100% + 80px) !important; }
	.layout-boxed .wrapper, .layout-boxed .main-header-wrapper, .layout-boxed.header-align-center .menu-wrapper, .layout-boxed.header-align-left .is-sticky .menu-wrapper { width:1250px; margin:0 auto; float:none; }
	.layout-boxed .main-header-wrapper, .layout-boxed.header-align-center .is-sticky .menu-wrapper, .layout-boxed.header-align-left .is-sticky .menu-wrapper { right:0; }	
	.layout-boxed .vc_row .dt-sc-stretch-row-content { width: 1170px; margin: 0 auto; float: none; padding: 0; }

	.layout-boxed .wrapper { overflow: hidden; -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.05); -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.05); box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.05); margin: 60px auto; border-radius: 50px; }
	
 	
	
	/*--------------------------------------------------------------
	3.0 - Columns
	--------------------------------------------------------------*/
	
	.column { margin:0px 0px 0px 2%; float:left; min-height:1px; position:relative; }
	.column.first { margin-left:0px; clear:both; }	
	
	.dt-sc-full-width, .dt-sc-one-column { width:100%; }
	.column.dt-sc-full-width, .column.dt-sc-one-column { margin-left:0px; }
	
	.dt-sc-one-half { width:49%; }
	
	.dt-sc-one-third { width:32%; }
	.dt-sc-two-third { width:66%; }
	
	.dt-sc-one-fourth { width:23.5%; }
	.dt-sc-three-fourth { width:74.5%; }	
	
	.dt-sc-one-fifth { width:18.4%; }
	.dt-sc-two-fifth { width:38.8%; }
	.dt-sc-three-fifth { width:59.2%; }
	.dt-sc-four-fifth { width:79.6%; }
	
	.dt-sc-one-sixth { width:15%; }
	.dt-sc-two-sixth { width:32%; }
	.dt-sc-three-sixth { width:49%; }
	.dt-sc-four-sixth { width:66%; }
	.dt-sc-five-sixth { width:83%; }
	
	.column.wpb_column.dt-sc-one-fifth { width: 18.4%; } /*http://prntscr.com/e0hlbf */
	.wpb_column.dt-sc-one-fifth { width: 20%; }
	.wpb_column.dt-sc-two-fifth { width: 40%; }
	.wpb_column.dt-sc-three-fifth { width: 60%; }
	
	.dt-sc-one-half iframe, .dt-sc-one-third iframe, .dt-sc-one-fourth iframe, .dt-sc-three-fourth iframe, .dt-sc-two-third iframe, iframe { max-width:100%; }
	embed, iframe, object, video { max-width:100%; }
	
	.column.no-space { margin-left:0px; margin-right:0px; }
	.column.no-space.dt-sc-one-fourth { width:24.982%; } 	
	.column.no-space.dt-sc-one-third { width:33.333333%; }	
	.column.no-space.dt-sc-one-half { width:50%; }
	.column.no-space.dt-sc-one-fifth, .wpb_column.no-space.dt-sc-one-fifth { width:20%; }
	.column.no-space.dt-sc-one-sixth { width:16.666%; }
	.column.no-space.dt-sc-one-seventh { width:14.28%; }
	.column.no-space.dt-sc-one-eight { width:12.5%; }
	.column.no-space.dt-sc-one-nineth { width:11.11%; }
	.column.no-space.dt-sc-one-tenth { width:10%; }
	
	.column.no-space.dt-sc-three-fourth { width:75%; }
	.column.no-space.dt-sc-two-third { width:66.666666%; }		
	.column.no-space.dt-sc-four-fifth, .wpb_column.no-space.dt-sc-four-fifth { width:80%; }
	.column.no-space.dt-sc-three-fifth, .wpb_column.no-space.dt-sc-three-fifth { width:60%; }
	.column.no-space.dt-sc-two-fifth, .wpb_column.no-space.dt-sc-two-fifth { width:40%; }		
	.column.no-space.dt-sc-two-sixth { width:33.2%; }
	.column.no-space.dt-sc-three-sixth { width:49.98%; }
	.column.no-space.dt-sc-four-sixth { width:66.4%; }
	.column.no-space.dt-sc-five-sixth { width:83%; }	
	
	
	/*--------------------------------------------------------------
	4.0 - Mega Menu
	--------------------------------------------------------------*/
	#main-menu .megamenu-2-columns-group .megamenu-child-container { width:590px; }
	#main-menu .megamenu-3-columns-group .megamenu-child-container { width:875px; }
	#main-menu .megamenu-4-columns-group .megamenu-child-container { width:1160px; }
	
		#main-menu .megamenu-child-container > ul.sub-menu > li { width:25%; padding:15px; float:left; display:inline; border-right: 1px solid #f7f7f7; }
	#main-menu .megamenu-child-container > ul.sub-menu > li:last-child { border-right: none; }
	
	#main-menu .megamenu-2-columns-group .megamenu-child-container > ul.sub-menu > li.menu-item-fullwidth, #main-menu .megamenu-3-columns-group .megamenu-child-container > ul.sub-menu > li.menu-item-fullwidth, #main-menu .megamenu-4-columns-group .megamenu-child-container > ul.sub-menu > li.menu-item-fullwidth { display:block; }	
	
	#main-menu .megamenu-child-container ul.sub-menu li.menu-item-fullwidth .widget { float:left; margin:0px; padding:0px 15px 30px;  }
	
	#main-menu .megamenu-2-columns-group .megamenu-child-container > ul.sub-menu > li { width:50%; }
	#main-menu .megamenu-3-columns-group .megamenu-child-container > ul.sub-menu > li { width:33.3333%; }
	#main-menu .megamenu-4-columns-group .megamenu-child-container > ul.sub-menu > li { width:25%; }
	
	#main-menu .megamenu-4-columns-group .megamenu-child-container ul.sub-menu li.menu-item-fullwidth .widget { width:25%; }
	#main-menu .megamenu-4-columns-group .megamenu-child-container ul.sub-menu li.fill-three-columns.menu-item-fullwidth .widget { width:33.3333%; }
	#main-menu .megamenu-4-columns-group .megamenu-child-container ul.sub-menu li.fill-two-columns.menu-item-fullwidth .widget { width:50%; }
	
	#main-menu .megamenu-4-columns-group .megamenu-child-container > ul.sub-menu > li.fill-two-columns { width:50%; }
	#main-menu .megamenu-4-columns-group .megamenu-child-container > ul.sub-menu > li.fill-three-columns { width:75%; }
	
	#main-menu .megamenu-2-columns-group .megamenu-child-container > ul.sub-menu > li.fill-two-columns.menu-item-fullwidth, #main-menu .megamenu-2-columns-group .megamenu-child-container > ul.sub-menu > li.fill-three-columns.menu-item-fullwidth, #main-menu .megamenu-2-columns-group .megamenu-child-container > ul.sub-menu > li.fill-four-columns.menu-item-fullwidth, /*3col dulwidth*/ #main-menu .megamenu-3-columns-group .megamenu-child-container > ul.sub-menu > li.fill-two-columns.menu-item-fullwidth, #main-menu .megamenu-3-columns-group .megamenu-child-container > ul.sub-menu > li.fill-three-columns.menu-item-fullwidth, #main-menu .megamenu-3-columns-group .megamenu-child-container > ul.sub-menu > li.fill-four-columns.menu-item-fullwidth, /*4col fulwidth*/ #main-menu .megamenu-4-columns-group .megamenu-child-container > ul.sub-menu > li.fill-two-columns.menu-item-fullwidth, #main-menu .megamenu-4-columns-group .megamenu-child-container > ul.sub-menu > li.fill-three-columns.menu-item-fullwidth, #main-menu .megamenu-4-columns-group .megamenu-child-container > ul.sub-menu > li.fill-four-columns.menu-item-fullwidth { width:100%; }
	
	#main-menu .megamenu-3-columns-group .megamenu-child-container > ul.sub-menu > li.fill-three-columns, /*2col*/ #main-menu .megamenu-2-columns-group .megamenu-child-container > ul.sub-menu > li.fill-two-columns, #main-menu .megamenu-2-columns-group .megamenu-child-container > ul.sub-menu > li.fill-three-columns, #main-menu .megamenu-2-columns-group .megamenu-child-container > ul.sub-menu > li.fill-four-columns, /*4col*/ #main-menu .megamenu-4-columns-group .megamenu-child-container > ul.sub-menu > li.fill-four-columns, #main-menu .megamenu-3-columns-group .megamenu-child-container > ul.sub-menu > li.fill-four-columns, #main-menu .megamenu-2-columns-group .megamenu-child-container > ul.sub-menu > li.fill-two-columns { width:100%; }
	
	#main-menu .megamenu-3-columns-group .megamenu-child-container > ul.sub-menu > li.fill-two-columns { width:66.6666%; }
	
	#main-menu .megamenu-2-columns-group .megamenu-child-container ul.sub-menu li.menu-item-fullwidth .widget { width:50%; }
	#main-menu .megamenu-3-columns-group .megamenu-child-container ul.sub-menu li.menu-item-fullwidth .widget { width:33.3333%; }
	
	#main-menu .megamenu-3-columns-group .megamenu-child-container ul.sub-menu li.fill-two-columns.menu-item-fullwidth .widget { width:50%; } 
	
	