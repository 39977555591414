body {
  background-image: url(/public/image/body-bg.jpg);
  background-position: top left;
}

.dt-sc-portfolio-container {
    height: auto;
}

.vc_row {
    margin-top: 20px;
}

#toTop.goTop {
    display: inline;
}

.header-align-center #main-menu ul.menu.menu-toggle-open {
    display: block;
    overflow: visible;
}

.hotline {
    color: #727272
}

.seo-title {
    text-align: center;
    font-size: 18px;
}