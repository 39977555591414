.vc_row {
    margin-left: -15px;
    margin-right: -15px;
}

.vc_column_container {
    padding-left: 0;
    padding-right: 0;
}

.vc_column_container {
    width: 100%;
}

.vc_column-inner::after, .vc_column-inner::before {
    content: " ";
    display: table;
}

@media (min-width: 768px) {
    .vc_col-sm-4 {
        width: 33.33333333%;
    }

    .vc_col-sm-1, .vc_col-sm-10, .vc_col-sm-11, .vc_col-sm-12, .vc_col-sm-2, .vc_col-sm-3, .vc_col-sm-4, .vc_col-sm-5, .vc_col-sm-6, .vc_col-sm-7, .vc_col-sm-8, .vc_col-sm-9 {
        float: left;
    }
}


@media only screen and (max-width: 767px) {
    .wpb_column {
        margin-bottom: 20px;
    }

    .wpb_column, .wpb_column.dt-sc-one-fifth, .wpb_column.dt-sc-two-fifth, .wpb_column.dt-sc-three-fifth {
        float: left;
        width: 100%;
    }

    .dt-sc-contact-info.type3 h6 {
        font-size: 23px;
    }
}


.vc_row:after {
    clear: both;
}

.vc_row:after, .vc_row:before {
    content: " ";
    display: table;
}

.vc_col-lg-1, .vc_col-lg-10, .vc_col-lg-11, .vc_col-lg-12, .vc_col-lg-2, .vc_col-lg-3, .vc_col-lg-4, .vc_col-lg-5, .vc_col-lg-6, .vc_col-lg-7, .vc_col-lg-8, .vc_col-lg-9, .vc_col-md-1, .vc_col-md-10, .vc_col-md-11, .vc_col-md-12, .vc_col-md-2, .vc_col-md-3, .vc_col-md-4, .vc_col-md-5, .vc_col-md-6, .vc_col-md-7, .vc_col-md-8, .vc_col-md-9, .vc_col-sm-1, .vc_col-sm-10, .vc_col-sm-11, .vc_col-sm-12, .vc_col-sm-2, .vc_col-sm-3, .vc_col-sm-4, .vc_col-sm-5, .vc_col-sm-6, .vc_col-sm-7, .vc_col-sm-8, .vc_col-sm-9, .vc_col-xs-1, .vc_col-xs-10, .vc_col-xs-11, .vc_col-xs-12, .vc_col-xs-2, .vc_col-xs-3, .vc_col-xs-4, .vc_col-xs-5, .vc_col-xs-6, .vc_col-xs-7, .vc_col-xs-8, .vc_col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.vc_column_container>.vc_column-inner {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

.dt-sc-contact-info.type3:before {
    border-color: #b2dcaa;
}
