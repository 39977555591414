@charset "utf-8";
/* CSS Document */
/* Table of Content
==================================================
	1.0 - Transition
	2.0 - Logo
	3.0 - Top Bar
	4.0 - Header
	5.0 - Default Color
	6.0 - Sticky Header
	7.0 - Simple Drop Down Menu
	8.0 - Mega Menu
	9.0 - Mobile Menu
	10.0 - Slider
	11.0 - Title & Breadcrumb
	12.0 - Footer
	13.0 - Side Navigation
	14.0 - Style Picker
	15.0 - Loader
	16.0 - Totop
	17.0 - Default Color
	18.0 - DT Responsive */
	
	/*--------------------------------------------------------------
	1.0 - Transition
	--------------------------------------------------------------*/
	.menu-icons-wrapper.rounded-icons .search a span, .menu-icons-wrapper.rounded-icons .cart a span, .menu-icons-wrapper .search a span, .menu-icons-wrapper .cart a span, #main-menu ul li.menu-item-simple-parent ul li a .fa, .megamenu-child-container ul.sub-menu > li > ul li a .fa,
	#main-menu .megamenu-child-container > ul.sub-menu > li > a .menu-item-description, .overlay-header .overlay #main-menu > ul.menu > li > a:after, .left-header.left-header-boxed #main-menu > ul.menu > li > a > span, .main-header .menu-icons-wrapper .overlay-search #searchform, .main-header .menu-icons-wrapper .overlay-search #searchform:before, .menu-icons-wrapper .top-menu-search-container.type3, .main-header .menu-icons-wrapper .top-menu-search-container.type3 #searchform input[type="text"], .left-header #toggle-sidebar span, .overlay-header .overlay .overlay-close:before, .overlay-header .overlay .overlay-close:after, .overlay-header #trigger-overlay:before, .overlay-header #trigger-overlay:after, .overlay-search .overlay-close:before, .overlay-search .overlay-close:after, .left-header.left-header-creative #main-menu > ul.menu > li > a:after, .left-header.left-header-creative #main-menu > ul.menu > li > a:before { -webkit-transition:all 0.3s linear; -moz-transition:all 0.3s linear; -o-transition:all 0.3s linear; -ms-transition:all 0.3s linear; transition:all 0.3s linear; }
	
	.main-header .menu-icons-wrapper .overlay-search #searchform:after { -webkit-transition:all 0.6s linear; -moz-transition:all 0.6s linear; -o-transition:all 0.6s linear; -ms-transition:all 0.6s linear; transition:all 0.6s linear; }
	
	.left-header #toggle-sidebar:before, .left-header #toggle-sidebar:after { -webkit-transition:all 0.1s linear; -moz-transition:all 0.1s linear; -o-transition:all 0.1s linear; -ms-transition:all 0.1s linear; transition:all 0.1s linear; }
	
 
	/*--------------------------------------------------------------
	2.0 - Logo
	--------------------------------------------------------------*/
	#logo { display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-flex: 1; -webkit-flex-grow: 1; flex-grow: 1; align-items: center; -webkit-box-align: center; }
	#logo a { display:block; overflow:hidden; float:left; padding:0px; margin:32px 0px;}
	#logo a img { display: block; height: inherit;  overflow: hidden; }
	#logo a .retina_logo { display:none; }
 	
	
		/*----*****---- << 2.1. Darkbg Logo >> ----*****----*/
		#logo a .darkbg_logo, .dt-sc-dark-bg #logo a .normal_logo { display:none; }
		.dt-sc-dark-bg #logo a .darkbg_logo { display:block; }
		
		.is-sticky #logo a {  height:23px; margin: 0; }
		.is-sticky #logo a img { max-height:100%; width:auto; height:auto; max-width:100%; }
		
		
		/*----*****---- << 2.2. Logo Title >> ----*****----*/
		#logo .logo-title { margin-top: 16px; }
		#logo .logo-title > h1 { line-height: normal; margin-bottom: 0; font-weight:bold; }
		#logo .logo-title h2 { font-size: 20px; display:block; clear:both; }
		#logo .logo-title > h1 a { margin: 0; height: auto; line-height: normal; width: auto; padding:0; }
		.left-header #logo .logo-title { padding:0 20px; }
		
		
		/*----*****---- << 2.3. Split Logo >> ----*****----*/
		.split-header #logo { position:absolute; left:0px; right:0px; top:0px; bottom:0px; margin:auto; width:18%; text-align:center; z-index:9999; }
		.split-header #logo a { max-width:100%; float:none; margin:0 auto; }
		.split-header #logo a img { max-width:100%; max-height:100%; margin:0 auto; }	
		
		
		/*----*****---- << 2.4. Header Align Center >> ----*****----*/
		.header-align-center #logo { width:34%; text-align:center; display:inline-block; }
		.header-align-center .main-header #logo a { padding-top:25px; height:auto; float:none; padding-bottom:20px; }
		.header-align-center .main-header #logo a img { margin:0 auto; }
		
		
		/*----*****---- << 2.5. Header Align Left >> ----*****----*/
		.header-align-center .main-header #logo a img { max-width:100%; }
		.overlay-header #logo a img, .header-align-left .main-header #logo a img { width:50%; }
		
		.header-align-left #logo { display:inline-block; width:24.7%; padding-top:24px; padding-bottom:20px; }
		.header-align-left #logo a { height:auto; }
		
		
		/*----*****---- << 2.6. Left Header >> ----*****----*/
		.left-header #header-wrapper #logo a { display:inline-block; float:none; width:100%; }
		.left-header #header-wrapper #logo a img { margin:0 auto; }
		.left-header.fixed-left-header #header-wrapper #logo a { height:21px; padding-left: 10px; width:auto; } 
		.left-header.fixed-left-header #header-wrapper #logo a img { margin:0; }
		.left-header #logo { margin:35px 0px; text-align:center; width:100%; clear:both; }
		.left-header.left-header-boxed #logo {  padding:40px 0 20px; margin:0px; text-align:center; }
		
		
		/*----*****---- << 2.7. Overlay Header >> ----*****----*/
		.overlay-header #logo a { padding: 20px 0px; height: auto; z-index:999; }
	
	
	@media screen and (-webkit-min-device-pixel-ratio:0) { 
		::i-block-chrome, .main-header, #logo, #logo a { display: -webkit-box;  -webkit-box-align: center; -webkit-box-flex: 1; position:relative; }
		::i-block-chrome, .left-header #header-wrapper #logo a { display:block; }
		
	}
 	
	
	
	/*--------------------------------------------------------------
	3.0 - Top Bar
	--------------------------------------------------------------*/
	.top-bar { float:left; clear:both; margin:0px; padding:0px; width:100%; max-height:50px; line-height:50px; position:relative; z-index:99999; }
	
	.top-bar-left { width:49%; float:left; }
	.top-bar-right { width:49%; float:right; text-align:right; }
	
	.top-bar .dt-sc-vertical-small-separator { position:relative; top:2px; }
	.top-bar-right .dt-sc-vertical-small-separator { margin:0px 15px; }
	
	.top-bar .language { float:left; margin:0px; padding:0px 20px 0px 0px; position:relative; }
	.top-bar .language img { margin:0px 10px 0px 0px; vertical-align:middle; position:relative; top:-1px; }
	.top-bar .language:before { content:"\f107"; position:absolute; font-family:FontAwesome; font-size:14px; right:0px; top:0px; bottom:0px; margin:auto; }
	
	.text-with-icon { display:inline-block; }
	.text-with-icon .icon, .text-with-icon .fa { display:inline-block; font-size:14px; font-weight:bold; margin:0px 5px 0px 0px; position:relative; top:1px; }
	
	.top-bar .dt-sc-sociable { float:right; margin:0px; padding:0px; }
	.top-bar .dt-sc-sociable li { display:inline-block; margin:0px; padding:0px; }
	.top-bar .dt-sc-sociable li a { padding:0px 10px; width:auto; height:auto; line-height:50px; }
	
	.top-bar .dt-sc-button { margin-top:5px; letter-spacing:1px; font-weight:bold; }
	
	
	/*--------------------------------------------------------------
	4.0 - Header
	--------------------------------------------------------------*/
	#header-wrapper { float:left; width:100%; margin:0px; padding:0px; }
	#header { width:100%; margin:0px; padding:0px; float:left; position:relative; }
	
	.header-with-topbar .main-header-wrapper, .header-with-topbar.header-on-slider .main-header-wrapper { top:50px; }
	
	.main-header-wrapper { position:absolute; left:0; top:0; width:100%; }
	.main-header { display:flex; float:left; width:100%; padding:0px; margin:0px; position:relative; }
	
	.standard-header .main-header-wrapper { position:inherit; top:0px; float:left; clear:both; width:100%; }
	
	.site-with-border { padding:80px; }
	.site-with-border .is-sticky .main-header-wrapper { top: 80px; }
	.site-with-border.overlay-header .sticky-wrapper { z-index:99999; } 
	
 	.dt-sc-bordered-site { position: fixed; left: 0; top: 0; width: 100%; height: 100%; border: 80px solid #fff; z-index: 99999; pointer-events: none; }
 	.site-with-border.left-header .main-header-wrapper { left:80px; top:80px; }
	.site-with-border.left-header.left-header-creative #toggle-sidebar { left:16px; top:16px; }
	.site-with-border.left-header.animated-right-header #header-wrapper { right:80px; }
	.site-with-border.left-header.animated-right-header .main-header-wrapper { left:auto; }
	
	.layout-boxed.standard-header .main-header-wrapper { width:1250px; }
	.layout-boxed.left-header .main-header-wrapper { width:100%; }
	
	.header-on-slider.boxed-header .main-header-wrapper { top:50px; }
	.boxed-header .main-header { padding:0px 30px;  }
	
		/*----*****---- << 4.1. Split Header >> ----*****----*/
		.split-header #main-menu .menu-left, .split-header #main-menu .menu-right { width:40%; }
		.split-header #main-menu .menu-right { float:right; }
		.split-header .menu-wrapper, .split-header #main-menu { width:100%; margin:0px; }
		.split-header #main-menu ul.menu > li > a { padding:0px 21px; }
		.split-header .is-sticky .menu-active-with-single-border #main-menu ul.menu > li > a { line-height:66px; }
			
		.split-header #main-menu ul.menu { text-align:left; line-height:0px; }
		.split-header #main-menu ul.menu li { line-height:24px; }
		.split-header #main-menu ul.menu.menu-right { text-align:right; }
		.split-header #main-menu ul.menu > li { float:none; display:inline-block; text-align:center; }
		
		.split-header.transparent-header #main-menu ul.menu > li > a { padding:0px; }
		.split-header.transparent-header #main-menu ul.menu > li { padding:0px 20px; }
		
		.transparent-header .main-header, .transparent-header .main-header-wrapper, .left-header.transparent-header .main-header, .left-header.transparent-header .main-header-wrapper { background:none; }
		
		
		/*----*****---- << 4.2. Two Color Header >> ----*****----*/
		.two-color-header .main-header-wrapper:before { content:""; position:absolute; top:0px; left:-5%; width:39.5%; height:100%; -webkit-transform:skewX(-30deg); transform:skewX(-30deg); }
		.two-color-header .main-header-wrapper:after { content:""; position:absolute; top:0px; right:-5%; width:70%; height:100%; -webkit-transform:skewX(-30deg); transform:skewX(-30deg); }
		.two-color-header .menu-wrapper { position:relative; z-index:1; }
		.two-color-header .dt-sc-dark-bg .main-header-wrapper { background:none; }
		
		.two-color-header.standard-header .main-header-wrapper { box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.2); }
		
		.fullwidth-menu-header .menu-wrapper { width:100%; clear:both; text-align:center; border-top:1px solid; border-bottom:1px solid; margin-left:0px; float:left; }
		.fullwidth-menu-header .dt-sc-dark-bg .menu-wrapper { border-bottom-color: rgba(255,255,255,0.3); border-top-color: rgba(255,255,255,0.3); }
		
		.layout-boxed.header-align-center .menu-wrapper { width:100%; }
		.layout-boxed.header-align-center .sticky-wrapper.is-sticky .menu-wrapper { width:1250px; }
		
		.two-color-header.site-with-fixed-footer #header-wrapper { background-color:#fff !important; }
		
		
		/*----*****---- << 4.3. Header Align Center >> ----*****----*/
		.header-align-center.fullwidth-menu-header #main-menu, .header-align-center #main-menu ul.menu { text-align:center; display:inline-block; float:none; }
		.header-align-center.fullwidth-menu-header #main-menu ul.menu > li, .header-align-center #main-menu ul.menu > li > a { float:none; display:inline-block; }
		.header-align-center.fullwidth-menu-header #main-menu ul.menu > li > a { line-height:70px; }
		
		.header-align-center.fullwidth-menu-header .menu-icons-wrapper { height:auto; display:inline-block; float:none; padding:0; }
		.header-align-center.fullwidth-menu-header .menu-icons-wrapper .search, .header-align-center.fullwidth-menu-header .menu-icons-wrapper .cart { float:none; display:inline-block; }
		.header-align-center.fullwidth-menu-header .menu-icons-wrapper .search a, .header-align-center.fullwidth-menu-header .menu-icons-wrapper .cart a { line-height:50px; }
		
		.header-align-center .header-left, .header-align-center .header-right, .header-align-left .header-right { padding-top: 20px; padding-bottom:20px; }
		.header-align-center .header-left:empty, .header-align-center .header-right:empty, .header-align-left .header-right:empty { display:none; }
		
		.header-align-left.fullwidth-menu-header .dt-sc-dark-bg .is-sticky .menu-wrapper, .header-align-center.fullwidth-menu-header .dt-sc-dark-bg .is-sticky .menu-wrapper, .header-align-left.fullwidth-menu-header.semi-transparent-header .dt-sc-dark-bg .is-sticky .menu-wrapper {  background:rgba(0, 0, 0, 0.9);}
		
 		.header-align-center .header-left, .header-align-center .header-right { width:33%; border-bottom:1px dashed #d9d9d9; letter-spacing: 3px; padding-top: 60px; }
 		.header-align-center .fullwidth-menu-header .menu-wrapper { border-width:0; }
		
		.header-align-center .main-header { display:block; }
		
		.header-align-center .header-left { float:left; }
		.header-align-center .header-right { float:right; text-align:right; }
		
 		.header-align-left .header-right { width:75%; float:right; text-align:right; }
		.header-align-left.fullwidth-menu-header .menu-wrapper { border-bottom:none; }
		
		.header-ad { width:100%; line-height:150px; }
		.header-ad img { max-width:100%; vertical-align:middle; }
		
		.fullwidth-menu-header.header-align-center .main-header #logo a { padding:35px 0; width: 60%; margin: 0 auto; }
		.fullwidth-menu-header.header-align-center .menu-wrapper { border-width:0; }
		.fullwidth-menu-header.header-align-center .is-sticky .menu-wrapper { padding:0; }
	
	
		/*----*****---- << 4.4. Header Search Form >> ----*****----*/
		.main-header #searchform { border:1px solid; padding:13px 15px; max-width:250px; position:relative; margin:0px; }
		.main-header #searchform:before { content:"\f002"; position:absolute; font-family:FontAwesome; font-size:14px; right:15px; top:-4px; bottom:0px; margin:auto; height:22px; }
		.main-header #searchform input[type="text"] { border:none; background:none; padding:0px; text-transform:uppercase; font-size:12px; width:220px; -webkit-appearance:none; }
		.main-header #searchform label span { display:none; }
		.main-header #searchform input[type="submit"] { width:45px; height:42px; margin:0px; padding:0px; position:absolute; right:0px; top:0px; z-index:1; cursor:pointer; background:none; text-indent:-9999px; }
		
		.main-header .menu-icons-wrapper .overlay-search #searchform { max-width:45%; position:absolute; top:0; bottom:0; left:0; right:0; height:45px; margin: auto; padding: 13px 15px; border-width:0 0 1px; border-style:solid; border-color:#000; background: none; }
		.main-header .menu-icons-wrapper .overlay-search #searchform input[type="text"], .main-header .menu-icons-wrapper .overlay-search #searchform:before { color:#000; }
		.main-header .menu-icons-wrapper .overlay-search #searchform input[type="text"] { width:100%; font-size:15px; }
		.main-header .menu-icons-wrapper .overlay-search #searchform:hover:after { width: 100%; }
		.main-header .menu-icons-wrapper .overlay-search #searchform:after { content: ""; width: 0; border-bottom: 2px solid; position: absolute; bottom: -1px; left:0; right:0; margin:0 auto; }
		.main-header .menu-icons-wrapper .overlay-search #searchform:hover { padding:13px 0; }
		.main-header .menu-icons-wrapper .overlay-search #searchform:hover:before { right:0; }
		
		/*----*****---- << 4.5. Menu Icons Wrapper >> ----*****----*/
		.menu-icons-wrapper { float:right; margin:0px 0px 0px 10px; padding:10px 0px; height:70px; }
		.menu-icons-wrapper .search, .menu-icons-wrapper .cart { float:left; margin:0px 10px; }
		.menu-icons-wrapper.rounded-icons .search, .menu-icons-wrapper.rounded-icons .cart { margin:0px 5px; }
		.menu-icons-wrapper div:last-child { margin-right:0px; }
		.menu-icons-wrapper .search a, .menu-icons-wrapper .cart a {  font-size:14px; }
		.menu-icons-wrapper .search a span, .menu-icons-wrapper .cart a span { line-height:70px; }
		.menu-icons-wrapper.rounded-icons .search a span, .menu-icons-wrapper.rounded-icons .cart a span { width:38px; height:38px; border-radius:50%; line-height:40px; text-align:center; }
		
		.menu-icons-wrapper .search { position:relative; }
		.menu-icons-wrapper .top-menu-search-container { display:none; position:absolute; right:-10px; bottom:-52px; z-index:9; }
		.menu-icons-wrapper .top-menu-search-container.show-top-menu-search { display:block; }
		.menu-icons-wrapper .top-menu-search-container .search-form label span { display:none; }
		.menu-icons-wrapper .top-menu-search-container .search-form input[type="submit"] { text-indent:-9999px; }
		.menu-icons-wrapper .top-menu-search-container .search-form input[type="search"] { padding-right:30px; text-transform:none; }	
		.menu-icons-wrapper .cart sup { width:15px; height:15px; line-height:15px; text-align:center; font-size:9px; font-weight:bold; color:#ffffff; border-radius:50%; position:relative; top:-8px; left:-9px; display:inline-block; }
		
		.menu-icons-wrapper .top-menu-search-container.type3 { position:relative; right:0; margin-right:10px; bottom:-12px; float:left; display:block; }
		.is-sticky .menu-icons-wrapper .top-menu-search-container.type3 { bottom:0; }
		.main-header .menu-icons-wrapper .top-menu-search-container.type3 #searchform:before { display:none; }
		.main-header .menu-icons-wrapper .top-menu-search-container.type3 #searchform { border-width:0; padding-left:0; padding-right:0; }
		.main-header .menu-icons-wrapper .top-menu-search-container.type3.show-top-menu-search #searchform { padding-left:15px; padding-right:15px; background-color: rgba(0, 0, 0, 0.05); }
		.main-header .menu-icons-wrapper .top-menu-search-container.type3 #searchform input[type="text"] { color:#000; width:0; }
		.main-header .menu-icons-wrapper .top-menu-search-container.type3.show-top-menu-search #searchform input[type="text"] { width: 220px; }
		.main-header .menu-icons-wrapper .top-menu-search-container.type3 #searchform input[type="submit"] { display:none; }
		.main-header .menu-icons-wrapper .top-menu-search-container.type3.show-top-menu-search #searchform input[type="submit"] { display:block; }
		
		.cart-info { padding:14px 20px 14px 57px; width:auto; display:inline-block; position:relative; margin:28px 0px 0px; }
		.cart-info p { margin:0px; }
		.cart-info:before { content:"\e66c"; position:absolute; font-family:"Stroke-Gap-Icons"; font-size:20px; left:15px; top:18px; }
		.cart-icon span { font-size:12px; line-height:20px; display:inline-block; width:20px; height:20px; border-radius:50%; text-align:center; position:absolute; left:28px; top:9px; }
		.cart-total { font-size:14px; display:inline-block; margin:0px 0px 0px 20px; letter-spacing:0.5px; }	
		
		.main-header .menu-icons-wrapper #searchform { margin:0px; }
		#searchform .dt-search-icon { display:none; }
		
		.live-chat { float:right; margin:32px 0px 0px 10px; }
		.live-chat a { font-size:10px; font-weight:800; padding:10px 15px 9px; text-transform:uppercase; border-radius:5px; }
		.live-chat a span { font-size:14px; display:inline-block; margin:0px 5px 0px 0px; }
		
		
		/*----*****---- << 4.6. Menu Active Style >> ----*****----*/
		.menu-wrapper { float:right; margin:0px 0px 0px 20px; }
		#main-menu, #main-menu ul.menu { float:left; margin:0px; padding:0px; }
		#main-menu ul.menu li { float:left; display:inline; margin:0px; padding:0px; }
		#main-menu > ul.menu > li > a { float:left; padding:0px 30px; line-height:90px; font-weight:bold; font-size:13px; letter-spacing:0.5px; text-transform:uppercase; }
		
		.menu-active-highlight-grey #main-menu ul.menu > li { padding:0px 20px; }
		.menu-active-highlight-grey #main-menu ul.menu > li > a { padding:0px; position:relative; }
		.menu-active-highlight-grey #main-menu ul.menu > li > a:before { content:""; position:absolute; height:2px; width:100%; left:0px; bottom:0px; }
		
		.left-header .menu-active-highlight-grey #main-menu ul.menu > li { padding:0; }
		.left-header #main-menu, .left-header #main-menu ul.menu { width:100%; }
		.left-header .menu-with-slanting-splitter #main-menu ul.menu > li:before { content:none; }
		.left-header #main-menu ul.menu > li > .megamenu-child-container { overflow-y: scroll; height: 400px; }
		
		.left-header .top-bar { float: left; clear: both; padding: 0px; text-align: center; width: 100vw; position: relative; height: auto; left: 0;}
		
		@media screen and (-webkit-min-device-pixel-ratio:0) { 
			/* Safari */
		::i-block-chrome,.left-header .top-bar { left: 100%; width: 1170px; }	
		::i-block-chrome,.left-header .top-bar:before { position: absolute; content: ""; display: block; background: #eee; left: -500px; width: 500px; top: 0; height: 100%; }
		::i-block-chrome,.left-header .top-bar:after { position: absolute; content: ""; display: block; background: #eee; right: -500px; width: 500px; top: 0; height: 100%; }
		
		}
		
		.left-header #header-wrapper .top-bar .container { width: 1170px; }	
		.left-header .menu-active-with-icon.menu-active-highlight #main-menu > ul.menu > li > a:before, .left-header .menu-active-with-icon.menu-active-highlight #main-menu > ul.menu > li > a:after { content:none; }
		
		.left-header .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current_page_item > a:before, .left-header .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current_page_ancestor > a:before, .left-header .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current-menu-item > a:before, .left-header .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current-menu-ancestor > a:before { top:0; bottom:0; margin:auto; transform:rotate(-90deg); right:-26px;  -webkit-transform:rotate(-90deg); -ms-transform:rotate(-90deg); left:auto; }
		
		.menu-active-with-single-border #main-menu ul.menu > li > a { border-bottom:1px solid transparent; }
		.left-header .menu-active-with-single-border #main-menu ul.menu > li > a { border-bottom-color:transparent; }
		.left-header .menu-active-with-single-border #main-menu > ul.menu > li.current-menu-item > a { border-top-color: rgba(0,0,0,0.09); }
		
		.split-header.transparent-header .menu-active-with-icon #main-menu > ul.menu > li.current_page_item > a:before, .split-header.transparent-header .menu-active-with-icon #main-menu > ul.menu > li.current_page_ancestor > a:before, .split-header.transparent-header .menu-active-with-icon #main-menu > ul.menu > li.current-menu-item > a:before, .split-header.transparent-header .menu-active-with-icon #main-menu > ul.menu > li.current-menu-ancestor > a:before { bottom:-2px; }
		
		.split-header.transparent-header .menu-active-with-icon #main-menu > ul.menu > li.current_page_item > a:after, .split-header.transparent-header .menu-active-with-icon #main-menu > ul.menu > li.current_page_ancestor > a:after, .split-header.transparent-header .menu-active-with-icon #main-menu > ul.menu > li.current-menu-item > a:after, .split-header.transparent-header .menu-active-with-icon #main-menu > ul.menu > li.current-menu-ancestor > a:after { bottom:-8px; }
		
		.menu-active-with-icon #main-menu > ul.menu > li.current_page_item > a, .menu-active-with-icon #main-menu > ul.menu > li.current_page_ancestor > a, .menu-active-with-icon #main-menu > ul.menu > li.current-menu-item > a, .menu-active-with-icon #main-menu > ul.menu > li.current-menu-ancestor > a { position:relative; }
		
		.menu-active-with-icon #main-menu > ul.menu > li.current_page_item > a:before, .menu-active-with-icon #main-menu > ul.menu > li.current_page_ancestor > a:before, .menu-active-with-icon #main-menu > ul.menu > li.current-menu-item > a:before, .menu-active-with-icon #main-menu > ul.menu > li.current-menu-ancestor > a:before, .menu-active-with-icon #main-menu > ul.menu > li.current_page_item > a:after, .menu-active-with-icon #main-menu > ul.menu > li.current_page_ancestor > a:after, .menu-active-with-icon #main-menu > ul.menu > li.current-menu-item > a:after, .menu-active-with-icon #main-menu > ul.menu > li.current-menu-ancestor > a:after { content:""; position:absolute; left:0px; right:0px; margin:auto; }
		
		.menu-active-with-icon #main-menu > ul.menu > li.current_page_item > a:before, .menu-active-with-icon #main-menu > ul.menu > li.current_page_ancestor > a:before, .menu-active-with-icon #main-menu > ul.menu > li.current-menu-item > a:before, .menu-active-with-icon #main-menu > ul.menu > li.current-menu-ancestor > a:before { bottom:20px; width:13px; height:1px; }
		
		.menu-active-with-icon #main-menu > ul.menu > li.current_page_item > a:after, .menu-active-with-icon #main-menu > ul.menu > li.current_page_ancestor > a:after, .menu-active-with-icon #main-menu > ul.menu > li.current-menu-item > a:after, .menu-active-with-icon #main-menu > ul.menu > li.current-menu-ancestor > a:after { bottom:14px; height:13px; width:1px; }
		
		.menu-active-with-icon #main-menu > ul.menu > li.current_page_item > a, .menu-active-with-icon #main-menu > ul.menu > li.current_page_ancestor > a, .menu-active-with-icon #main-menu > ul.menu > li.current-menu-item > a, .menu-active-with-icon #main-menu > ul.menu > li.current-menu-ancestor > a { border:none; }
		
		.menu-active-border-with-arrow  #main-menu > ul.menu > li.current_page_item > a, .menu-active-border-with-arrow  #main-menu > ul.menu > li.current_page_ancestor > a, .menu-active-border-with-arrow  #main-menu > ul.menu > li.current-menu-item > a, .menu-active-border-with-arrow  #main-menu > ul.menu > li.current-menu-ancestor > a { position:relative; }
		
		.menu-active-border-with-arrow #main-menu > ul.menu > li.current_page_item > a:before, .menu-active-border-with-arrow #main-menu > ul.menu > li.current_page_ancestor > a:before, .menu-active-border-with-arrow #main-menu > ul.menu > li.current-menu-item > a:before, .menu-active-border-with-arrow #main-menu > ul.menu > li.current-menu-ancestor > a:before, .menu-active-border-with-arrow #main-menu > ul.menu > li.current_page_item > a:after, .menu-active-border-with-arrow #main-menu > ul.menu > li.current_page_ancestor > a:after, .menu-active-border-with-arrow #main-menu > ul.menu > li.current-menu-item > a:after, .menu-active-border-with-arrow #main-menu > ul.menu > li.current-menu-ancestor > a:after { content:""; position:absolute; left:0px; bottom:0px; width:100%; margin:auto; }
		
		.menu-active-border-with-arrow  #main-menu > ul.menu > li.current_page_item > a:before, .menu-active-border-with-arrow  #main-menu > ul.menu > li.current_page_ancestor > a:before, .menu-active-border-with-arrow  #main-menu > ul.menu > li.current-menu-item > a:before, .menu-active-border-with-arrow  #main-menu > ul.menu > li.current-menu-ancestor > a:before { border-left:3px solid transparent; border-right:3px solid transparent; border-bottom:3px solid; right:0px; width:0px; bottom:2px; }
		.menu-active-border-with-arrow  #main-menu > ul.menu > li.current_page_item > a:after, .menu-active-border-with-arrow  #main-menu > ul.menu > li.current_page_ancestor > a:after, .menu-active-border-with-arrow  #main-menu > ul.menu > li.current-menu-item > a:after, .menu-active-border-with-arrow  #main-menu > ul.menu > li.current-menu-ancestor > a:after { height:2px; }
		
		.menu-active-with-two-border #main-menu > ul.menu > li.current_page_item > a, .menu-active-with-two-border #main-menu > ul.menu > li.current_page_ancestor > a, .menu-active-with-two-border #main-menu > ul.menu > li.current-menu-item > a, .menu-active-with-two-border #main-menu > ul.menu > li.current-menu-ancestor > a { position:relative; }
		.menu-active-with-two-border #main-menu > ul.menu > li.current_page_item > a:before, .menu-active-with-two-border #main-menu > ul.menu > li.current_page_ancestor > a:before, .menu-active-with-two-border #main-menu > ul.menu > li.current-menu-item > a:before, .menu-active-with-two-border #main-menu > ul.menu > li.current-menu-ancestor > a:before, .menu-active-with-two-border #main-menu > ul.menu > li.current_page_item > a:after, .menu-active-with-two-border #main-menu > ul.menu > li.current_page_ancestor > a:after, .menu-active-with-two-border #main-menu > ul.menu > li.current-menu-item > a:after, .menu-active-with-two-border #main-menu > ul.menu > li.current-menu-ancestor > a:after { content:""; position:absolute; left:0px; width:100%; height:2px; }
		
		.menu-active-with-two-border #main-menu > ul.menu > li.current_page_item > a:before, .menu-active-with-two-border #main-menu > ul.menu > li.current_page_ancestor > a:before, .menu-active-with-two-border #main-menu > ul.menu > li.current-menu-item > a:before, .menu-active-with-two-border #main-menu > ul.menu > li.current-menu-ancestor > a:before { top:0px; }
		.menu-active-with-two-border #main-menu > ul.menu > li.current_page_item > a:after, .menu-active-with-two-border #main-menu > ul.menu > li.current_page_ancestor > a:after, .menu-active-with-two-border #main-menu > ul.menu > li.current-menu-item > a:after, .menu-active-with-two-border #main-menu > ul.menu > li.current-menu-ancestor > a:after { bottom:0px; }
		
		.menu-active-highlight-with-arrow #main-menu > ul.menu > li.current_page_item > a, .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current_page_ancestor > a, .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current-menu-item > a, .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current-menu-ancestor > a { position:relative; }
		.menu-active-highlight-with-arrow #main-menu > ul.menu > li.current_page_item > a:before, .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current_page_ancestor > a:before, .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current-menu-item > a:before, .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current-menu-ancestor > a:before { content:""; position:absolute; width:0px; height:0px; left:0px; bottom:-9px; border-top:12px solid; border-left:20px solid transparent; border-right:20px solid transparent; margin:0 auto; right:0; }
		
		@media screen and (-webkit-min-device-pixel-ratio:0) { 
			/* Safari */
			::i-block-chrome,.menu-active-highlight-with-arrow #main-menu > ul.menu > li.current_page_item > a:before, .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current_page_ancestor > a:before, .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current-menu-item > a:before, .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current-menu-ancestor > a:before {
			 border-left-width:34px; border-right-width:34px;
			}
		}
		
		.menu-with-small-splitter #main-menu ul.menu > li { position:relative; }
		.menu-with-small-splitter #main-menu ul.menu > li:before { content:""; position:absolute; top:0px; bottom:0px; right:0px; margin:auto; height:20px; width:1px; } 
		.menu-with-small-splitter #main-menu ul.menu > li:last-child:before { background:none; }
		
		.menu-with-slanting-splitter #main-menu ul.menu > li { position:relative; }
		.menu-with-slanting-splitter #main-menu ul.menu > li:before { content:""; position:absolute; top:3px; bottom:0px; right:-6px; margin:auto; height:15px; width:1px; -webkit-transform:rotate(30deg);  -moz-transform-origin:0 0;  -moz-transform:rotate(30deg);  -ms-transform-origin:0 0;  -ms-transform:rotate(30deg);  transform-origin:0 0; transform:rotate(30deg); } 
		.menu-with-slanting-splitter #main-menu ul.menu > li:last-child:before, .dt-sc-dark-bg .menu-with-slanting-splitter #main-menu ul.menu > li:last-child:before { background:none; }
		.overlay-header .menu-with-slanting-splitter #main-menu ul.menu > li:before { bottom: 27px; top:auto;  height: 1px; width:25px; right: 0; left:0; transform:none; }
		
		
		/*----*****---- << 4.7 - Left Header >> ----*****----*/
		html { height:100%; }
		
		.left-header #header-wrapper .main-header { display:inline-block; }
		
		.left-header #header-wrapper .main-header-wrapper, .left-header #header-wrapper .main-header { height:100%; }
		.left-header #header-wrapper { width:260px; height:100%; position:fixed; left:0px; top:0px; z-index:9999; }
		.left-header #header { height:100%; }
		.left-header .main-header-wrapper { position:inherit; }
		.left-header .menu-wrapper { width:100%; clear:both; margin:0; }
		.left-header #header-wrapper .container { width:100%; height:100%; }
		
 		.left-header #main-menu ul.menu li, .left-header #main-menu ul.menu li a { width:100%;  }
		.left-header #main-menu > ul.menu > li > a { line-height:normal; padding:16px 25px 15px; }
  		
		.left-header-footer { clear:both; width:100%; position:absolute; bottom:0px; text-align:center; padding:30px 20px; left:0; }
		.left-header-footer:empty { display:none; }
		.left-header-footer h5 { text-transform:uppercase; }
		.left-header-footer .dt-sc-sociable { width:100%; text-align:center; }
		.left-header-footer .dt-sc-sociable li, .left-header-footer .dt-sc-sociable li a { display:inline-block; float:none; }
		.left-header-footer .dt-sc-sociable li { margin: 0 5px 5px; }
		.left-header-footer .dt-sc-sociable.filled li a { width:32px; height:32px; line-height:32px; }
		.left-header-footer p { font-size:13px; line-height:24px; margin:20px 0px 0px; display:block; clear:both; }
		
		.left-header { overflow-x:hidden; }
		.left-header #main { padding-left:260px; }
		.left-header.layout-boxed #main { padding-left:0; }
		.left-header .menu-icons-wrapper { display:none; }
 		
		.left-header #main-menu ul.menu > li > .megamenu-child-container { left:260px; top:0px; }
		.left-header .main-header-wrapper #main-menu ul li.menu-item-simple-parent ul { left:100%; top:0px; }
		
		.left-header .section-wrapper.vc_row[data-vc-full-width-init] { padding-left:260px; }
		.left-header.left-header-boxed .section-wrapper.vc_row[data-vc-full-width-init] { padding-left:0px; }
		.left-header.left-header-creative .section-wrapper.vc_row[data-vc-full-width-init] { padding-left:0px; }
		
		.left-header.left-header-boxed .left-header-footer .dt-sc-sociable li { margin: 0 0 10px; text-align: center; display:block; width:100%; }
		.left-header.left-header-boxed .left-header-footer { padding:0; }
		
		.left-header.fixed-left-header #main-menu > ul.menu > li > a > span, .left-header.fixed-left-header #main-menu > ul.menu > li > a > i { display: block; margin:0 0 10px; font-size: 22px; color: rgba(0, 0, 0, 0.8); }
		.left-header.fixed-left-header #main-menu { position:absolute; bottom:100px; z-index:9; }
		.left-header.fixed-left-header #main-menu > ul.menu > li > a { font-size:20px; }
		
		.left-header.fixed-left-header .dt-sc-dark-bg #main-menu > ul.menu > li > a > span, .left-header.fixed-left-header .dt-sc-dark-bg #main-menu > ul.menu > li > a > i { color: rgba(255, 255, 255, 0.8); }
		
		
		/*----*****---- << 4.8 - Left Header Creative >> ----*****----*/
		.left-header.left-header-creative #main { padding-left:0px; }
		.left-header.left-header-creative #header-wrapper { width:50%; left:-50%; }
		.left-header.left-header-creative #header-wrapper .main-header { padding:30px; }
 		.left-header.left-header-creative #main-menu, .left-header.left-header-creative #main-menu ul.menu { width: auto; }
		.left-header.left-header-creative #main-menu ul.menu li, .left-header.left-header-creative #main-menu ul.menu li a { width:auto; display:block; clear:both; float:none; text-align:center; text-transform: capitalize; position:relative; }
		.left-header.left-header-creative #main-menu ul.menu li { padding:0; }
		.left-header.left-header-creative #main-menu > ul.menu > li > a:after, .left-header.left-header-creative #main-menu > ul.menu > li > a:before { content: ""; position: absolute; height: 2px; width: 0; background-color: rgba(0, 0, 0, 0.25); margin: auto; bottom: 0; }
		.left-header.left-header-creative #main-menu > ul.menu > li > a:after { right:0; left:auto; }
		.left-header.left-header-creative #main-menu > ul.menu > li > a:before { left:0; }
		.left-header.left-header-creative #main-menu > ul.menu > li.current_page_item > a:after, .left-header.left-header-creative #main-menu > ul.menu > li.current_page_item > a:before, .left-header.left-header-creative #main-menu > ul.menu > li > a:hover:after, .left-header.left-header-creative #main-menu > ul.menu > li > a:hover:before { width:50%; }
		.left-header.left-header-creative .dt-sc-dark-bg #main-menu > ul.menu > li.current_page_item > a:after, .left-header.left-header-creative .dt-sc-dark-bg #main-menu > ul.menu > li.current_page_item > a:before, .left-header.left-header-creative .dt-sc-dark-bg #main-menu > ul.menu > li > a:hover:after, .left-header.left-header-creative .dt-sc-dark-bg #main-menu > ul.menu > li > a:hover:before { background-color:rgba(255, 255, 255, 0.25); }
		
		
		.left-header-creative #main-menu ul.menu > li > .megamenu-child-container, .overlay-header #main-menu ul.menu > li > .megamenu-child-container { left:0; top:0px; width:auto; position:static; background: none; border-width: 0; }
		.left-header-creative .main-header-wrapper #main-menu ul li.menu-item-simple-parent ul, .overlay-header .main-header-wrapper #main-menu ul li.menu-item-simple-parent ul { left:100%; top:0px; }
		.left-header-creative .dt-sc-dark-bg #main-menu .megamenu-child-container ul.sub-menu > li > ul li a, .left-header-creative .dt-sc-dark-bg #main-menu .megamenu-child-container > ul.sub-menu > li > a, .left-header-creative .dt-sc-dark-bg #main-menu .megamenu-child-container > ul.sub-menu > li > .nolink-menu, .overlay-header .dt-sc-dark-bg #main-menu .megamenu-child-container ul.sub-menu > li > ul li a, .overlay-header .dt-sc-dark-bg #main-menu .megamenu-child-container > ul.sub-menu > li > a, .overlay-header .dt-sc-dark-bg #main-menu .megamenu-child-container > ul.sub-menu > li > .nolink-menu { color:#fff; }
		.left-header-creative #main-menu .megamenu-child-container > ul.sub-menu > li, .overlay-header #main-menu .megamenu-child-container > ul.sub-menu > li { border:0; }
		.left-header-creative #main-menu .megamenu-child-container ul.sub-menu > li > ul, .overlay-header #main-menu .megamenu-child-container ul.sub-menu > li > ul { display:none; }
		.left-header.left-header-creative #main-menu ul.menu > li > .megamenu-child-container, .overlay-header #main-menu ul.menu > li > .megamenu-child-container { height:auto; overflow:auto; }
		.left-header.left-header-creative #main-menu > ul.menu > li > a, .overlay-header #main-menu > ul.menu > li > a { padding-left:0; padding-right:0; }
		.overlay-header #main-menu .megamenu-child-container > ul.sub-menu > li { width: auto; display: block; clear: both; float: none; text-align: center; text-transform: capitalize; position: relative; }
		
				
		.left-header.left-header-creative #toggle-sidebar { display:block; left:0; top:0; background-color:rgba(0, 0, 0, 0.75); }
		.left-header #toggle-sidebar { cursor:pointer; width:45px; height:45px; position:fixed; left:20px; top:20px; z-index:3000; }
		
		.left-header #toggle-sidebar:before, .left-header #toggle-sidebar:after { content:""; position:absolute; width:15px; height:14px; left:0px; right:10px; top:0px; bottom:0px; margin:auto; }
		.left-header #toggle-sidebar:before { border-top:2px solid #ffffff; }
		.left-header #toggle-sidebar:after { border-bottom:2px solid #ffffff; width: 20px; right: 5px; }
		.left-header #toggle-sidebar span { content:""; position:absolute; width:26px; height:2px; background:#ffffff; left:0px; right:0px; top:0px; bottom:0px; margin:auto; }
 		.left-header #toggle-sidebar:hover:before { right: 0; left: 11px; }
		.left-header #toggle-sidebar:hover:after { left: 6px; right: 0; }
		.left-header #toggle-sidebar.close-icon span { display:none; }
		
		.left-header.left-header-creative .menu-wrapper { display: flex; flex-grow: 1; flex-direction: column; height: 70%; justify-content: center; top: 0; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; -webkit-box-flex: 1; -webkit-flex-grow: 1; -webkit-flex-direction: column; -ms-flex-direction: column; -webkit-box-pack: center; -webkit-justify-content: center; -ms-flex-pack: center; -webkit-box-align: center; }
		.left-header.left-header-creative #main-menu { margin:0 auto; }
 		
		.left-header.left-header-creative #main-menu ul.menu li a.dt-menu-expand { display:none; }
		.left-header.left-header-creative #main-menu ul li.menu-item-simple-parent ul { background:none; position:relative; left:0; right:0; border:0; float:none; margin:0 auto; width:auto; }
		.left-header.left-header-creative #main-menu ul li.menu-item-simple-parent ul li a { border:0; }
 		.left-header.left-header-creative .dt-sc-dark-bg #main-menu ul li.menu-item-simple-parent ul li a { color:#fff; }
		.left-header.left-header-creative #main-menu ul li.menu-item-simple-parent ul li a { padding:10px; }
		
 		
		
		/*----*****---- << 4.9 Left Header Boxed >> ----*****----*/
		.left-header.left-header-boxed #main { padding-left:0px; }
		
		.left-header.left-header-boxed #header-wrapper { left:0; top:0; bottom:0; width:100px; }	
		
		.left-header.left-header-boxed #main-menu > ul.menu > li > a { text-align:center; }
 		.left-header.left-header-boxed #main-menu > ul.menu > li > a > i { font-size:24px; margin-right:0; }
 		.left-header.left-header-boxed .menu-wrapper { height: 75%; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-flex: 1; -webkit-flex-grow: 1; flex-grow: 1;     -webkit-flex-direction: column; -ms-flex-direction: column; flex-direction: column; -webkit-box-pack: center; -webkit-justify-content: center; -ms-flex-pack: center;  justify-content: center; -webkit-box-align: center; }
		.left-header.left-header-boxed { padding-left:100px; }
		.single.left-header.left-header-boxed { padding-left:0; }
 
		.left-header.left-header-boxed #main-menu > ul.menu > li > a > span { position:absolute; width:150px; opacity: 0; -webkit-transform-origin: 100% 50%; -moz-transform-origin: 100% 50%;-ms-transform-origin: 100% 50%; -o-transform-origin: 100% 50%; transform-origin: 100% 50%; background-color: #fff; left: 0; padding: 21px 0; top:-1px; z-index:-1; }
 		.left-header.left-header-boxed #main-menu > ul.menu > li > a:hover > span { opacity: 1; -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); transform: scale(1); left: 100%; }
		.left-header.left-header-boxed #primary .wpb_revslider_element.wpb_content_element { margin-bottom: 0; }
		
		
		/*----*****---- << 4.9 Left Header Stretched >> ----*****----*/
		.left-header.left-stretched-header #main .container, .left-header.left-stretched-header #footer .container { width: 100%; padding-left: 260px; margin:0; }
 		.left-header.left-stretched-header #main { padding-left:0; }
		.left-header.left-stretched-header #logo { z-index: 9; position: relative; }
		.left-header.left-stretched-header .menu-wrapper { position:absolute; left:0; top:0; height:100%; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-flex: 1; -webkit-flex-grow: 1; flex-grow: 1;     -webkit-flex-direction: column; -ms-flex-direction: column; flex-direction: column; -webkit-box-pack: center; -webkit-justify-content: center; -ms-flex-pack: center;  justify-content: center; -webkit-box-align: center; text-align:center; }
		
		.left-header.left-stretched-header .main-header-wrapper #main-menu ul li.menu-item-simple-parent ul { position:inherit; left:0; top:0; width:100%; text-align:center; border:0; background:inherit; padding-top:0; }
		.left-header.left-stretched-header #main-menu ul.menu li { display:inline-block; float:none; }
		.left-header.left-stretched-header #main-menu ul.menu li .dt-arrow-menu-expand, .left-header.left-header-creative #main-menu ul.menu li .dt-arrow-menu-expand, .overlay-header #main-menu ul.menu li .dt-arrow-menu-expand { width:30px; height:20px; margin-left:5px; }
 		.left-header.left-stretched-header #main-menu ul.sub-menu li a { padding: 10px 0 0; width:auto; display:inline-block; border-bottom:0; position:relative; }
		.left-header.left-stretched-header #main-menu ul.sub-menu li a:after { content:""; position:absolute; left:0; bottom:0; width:0; border:0; border-bottom:1px solid; -webkit-transition: width 0s ease,border .4s ease; -moz-transition: width 0s ease,border .4s ease; transition: width 0s ease,border .4s ease; } 
		.left-header.left-stretched-header #main-menu ul.sub-menu li a:hover:after, .left-header.left-stretched-header #main-menu ul.sub-menu li.current_page_item a:after, .left-header.left-stretched-header #main-menu ul.sub-menu li.current-menu-item a:after { width:100%; -webkit-transition: width .4s ease; -moz-transition: width .4s ease; transition: width .4s ease; }
		.left-header.left-stretched-header #main-menu > ul.menu > li > a { padding:10px; text-transform:capitalize; }
 		
		.left-header.left-stretched-header .dt-sc-dark-bg #main-menu ul.sub-menu li a { color:#fff; }
		.left-header.left-stretched-header #main-menu ul.sub-menu li a.dt-menu-expand { display:none; }
		.left-header.left-stretched-header #main-menu ul.menu > li > .megamenu-child-container { height:auto; background:none; position:relative; left:auto; width:100%; border-width: 0;
 overflow: inherit; }
		.left-header.left-stretched-header #main-menu .megamenu-4-columns-group .megamenu-child-container > ul.sub-menu > li, .left-header.left-stretched-header #main-menu .megamenu-3-columns-group .megamenu-child-container > ul.sub-menu > li, .left-header.left-stretched-header #main-menu .megamenu-2-columns-group .megamenu-child-container > ul.sub-menu > li { width:100%; }
		.left-header.left-stretched-header #main-menu .megamenu-child-container > ul.sub-menu > li { border-width:0; }
		
		/*----*****---- << 4.9 Animated Right Header >> ----*****----*/
		.left-header.animated-right-header #header-wrapper.hideMenu { right:-160px; }
		.left-header.animated-right-header #header-wrapper { right:0; left:auto; -webkit-transition: 0.5s all ease-in-out; -moz-transition: 0.5s all ease-in-out; -o-transition: 0.5s all ease-in-out; -ms-transition: 0.5s all ease-in-out; transition: 0.5s all ease-in-out; width:160px; }
		.left-header.animated-right-header #main { padding-left: 0; }
		.left-header.animated-right-header #main-menu > ul.menu > li > a { text-align:center; }
		
		.left-header.animated-right-header .main-header-wrapper #main-menu ul li.menu-item-simple-parent ul { left:auto; right:100%; }
		.left-header.animated-right-header #main-menu ul.menu > li > .megamenu-child-container { display:none; }
		

		/*----*****---- << 4.9 Animated Nav Header >> ----*****----*/
		.fallin-header .nav-menu-icon { float: right; height: 20px; margin: 33px 0 0 25px; cursor: pointer; position: relative; z-index: 100; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
		.fallin-header .nav-menu-icon span { height: 3px; width: 23px; margin: 3px 0px 3px 0px; background-color: #000; display: block; right: 0; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; -webkit-animation: flipInX 1s linear; -moz-animation: flipInX 1s linear; animation: flipInX 1s linear; }
		.fallin-header .nav-menu-icon span:first-child { width: 12px; margin-right: 11px; }
 		.fallin-header .nav-menu-icon span:nth-child(3) { width: 18px; margin-right: 5px; }
		
		.fallin-header .dt-sc-dark-bg .nav-menu-icon span { background-color:#fff; }
		
		.fallin-header #main-menu > ul.menu > li.menu-item { top:-90px; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
		.fallin-header #main-menu > ul.menu > li.menu-item-show { top:0; }
		.fallin-header .nav-menu-icon .animate-icon { -webkit-animation: flipInY 1s linear; -moz-animation: flipInY 1s linear; animation: flipInY 1s linear;  }
		.fallin-header .nav-menu-icon span.animate-icon:first-child { margin-left: 11px; }
 		.fallin-header .nav-menu-icon span.animate-icon:nth-child(3) { margin-left: 5px; }
		
 
 		/*----*****---- << 4.10 - Overlay Menu >> ----*****----*/
		.overlay-header .overlay nav { text-align:center; position: relative; top: 50%; -webkit-transform: translateY(-50%); transform: translateY(-50%); width:100%; }
		.overlay-header .overlay ul { list-style: none; padding: 0; margin: 0 auto; display: inline-block; height: 100%; position: relative; float:none; }
		.overlay-header .overlay .menu-header-menu-container > ul > li { display: block; height: 16.6666667%; height: calc(100% / 6); min-height: 80px; }
		.overlay-header .overlay #main-menu ul li.menu-item-simple-parent ul { position:relative; top:0; left:0; text-align:center; padding-top:0; background:none; }
		.overlay-header .overlay #main-menu ul.menu li.menu-item-simple-parent ul li a { padding-left:0; font-size: 15px; border-bottom:0; color:#fff; }
		.overlay-header .overlay #main-menu ul li.menu-item-simple-parent ul li { padding:0; }
 		
		.overlay-header .dt-sc-dark-bg .main-header-wrapper { background:none; }
		
		.overlay-hugeinc, .overlay-search { opacity: 0; visibility: hidden; -webkit-transition: opacity 0.5s, visibility 0s 0.5s; transition: opacity 0.5s, visibility 0s 0.5s; }
		.overlay-search { background-color:#fff; }
		.overlay-hugeinc.open, .overlay-search.open { opacity: 1; visibility: visible; -webkit-transition: opacity 0.5s; transition: opacity 0.5s; }
		.overlay-hugeinc nav { -webkit-perspective: 1200px; perspective: 1200px; }
		.overlay-hugeinc nav ul { opacity: 0.4; -webkit-transform: translateY(-25%) rotateX(35deg); transform: translateY(-25%) rotateX(35deg); -webkit-transition: -webkit-transform 0.5s, opacity 0.5s; transition: transform 0.5s, opacity 0.5s; }
		.overlay-hugeinc.open nav ul { opacity: 1; -webkit-transform: rotateX(0deg); transform: rotateX(0deg); }
		.overlay-hugeinc.close nav ul { -webkit-transform: translateY(25%) rotateX(-35deg); transform: translateY(25%) rotateX(-35deg); }
		
		.overlay-header .sticky-wrapper .main-header-wrapper, .overlay-header .main-header-wrapper { z-index:999; }
		.standard-header.overlay-header .is-sticky .main-header-wrapper { border:0; }
		
		.overlay-header #main-menu ul li.menu-item-simple-parent ul { border:0; padding-bottom: 20px; }
		.site-with-border.overlay-header .overlay .overlay-close { right: 40px; top: 40px; }
		
		@media screen and (max-height: 30.5em) { 
			.overlay-header .overlay nav { height:70%; }
			.overlay-header .overlay ul li { min-height:34px; }
		}	
		
		.overlay-header #main-header-wrapper.main-header-wrapper { background: transparent; z-index: auto; }
		.overlay-header .overlay, .overlay-search { position:fixed; width:100%; height:100%; top:0; left:0; z-index:9999; }
		.overlay-header #trigger-overlay, .overlay-header .overlay .overlay-close, .overlay-search .overlay-close { cursor:pointer; width:46px; height:46px; position:fixed; right:20px; top:20px; font-size:0px; z-index:9; }
		.overlay-header .overlay .overlay-close { background:inherit; }
 		.overlay-header #main-header-wrapper.main-header-wrapper > .container { width:100%; }
		.overlay-header #main-header-wrapper.main-header-wrapper #logo { padding-left:30px; pointer-events: none; }
		
		.site-with-border.overlay-header #trigger-overlay { position:relative; right:0; float:right; top:0; margin:auto; height:100%; }
		.site-with-border.overlay-header .menu-wrapper { padding-right:30px; }
		
 		.overlay-header #trigger-overlay:before, .overlay-header #trigger-overlay:after { content:""; position:absolute; width:15px; height:14px; left:0px; right:11px; top:0px; bottom:0px; margin:auto; }
		.overlay-header #trigger-overlay:before { border-top:2px solid #000; }
		.overlay-header #trigger-overlay:after { border-bottom:2px solid #000; width: 20px; right: 6px; }
		.overlay-header #trigger-overlay span { content:""; position:absolute; width:26px; height:2px; background:#000; left:0px; right:0px; top:0px; bottom:0px; margin:auto; }
		.overlay-header #trigger-overlay:hover:before, .overlay-header #trigger-overlay:hover:after { right: 0; width:26px; }
 		
		.overlay-header .overlay .overlay-close:before, .overlay-header .overlay .overlay-close:after, .left-header.left-header-creative #toggle-sidebar.close-icon:before, .left-header.left-header-creative #toggle-sidebar.close-icon:after, .overlay-search .overlay-close:before, .overlay-search .overlay-close:after { content:""; position:absolute; width:26px; height:2px; background:#000; left:0px; right:0px; top:0px; bottom:0px; margin:auto; }
		.overlay-header .overlay.open .overlay-close:before, .left-header.left-header-creative #toggle-sidebar.close-icon:before, .left-header.left-header-creative #toggle-sidebar.close-icon:after, .overlay-search .overlay-close:before { -webkit-transform:rotate(45deg); -moz-transform:rotate(45deg); -ms-transform:rotate(45deg); transform:rotate(45deg); }
		.overlay-header .overlay.open .overlay-close:after, .left-header.left-header-creative #toggle-sidebar.close-icon:after, .overlay-search .overlay-close:after { -webkit-transform:rotate(-45deg); -moz-transform:rotate(-45deg); -ms-transform:rotate(-45deg); transform:rotate(-45deg); }
		.overlay-header .dt-sc-dark-bg .overlay .overlay-close { background:rgba(0, 0, 0, 0.1); }
		.left-header.left-header-creative #toggle-sidebar.close-icon:before, .left-header.left-header-creative #toggle-sidebar.close-icon:after { background:#fff; }
			
		.overlay-header .overlay #main-menu, .overlay-header .overlay #main-menu ul.menu { float:none; text-align:center; }
		.overlay-header .overlay #main-menu > ul.menu > li { clear:both; text-align:center; float:none; display:block; width:100%; }
		.overlay-header .overlay #main-menu > ul.menu > li > a { font-size:30px; line-height:normal; float:none; display:block; padding:0px; padding-bottom:10px; margin-bottom:20px; text-transform:capitalize; position:relative; }
		.overlay-header .overlay #main-menu > ul.menu > li > a.dt-menu-expand { display:none; }
		.overlay-header .dt-sc-dark-bg .overlay #main-menu > ul.menu > li > a { color:rgba(255,255,255,0.6); }
		.overlay-header .dt-sc-dark-bg .overlay #main-menu > ul.menu > li.current-menu-item > a, .overlay-header .dt-sc-dark-bg .overlay #main-menu > ul.menu > li.current_page_item > a, .overlay-header .dt-sc-dark-bg .overlay #main-menu > ul.menu > li > a:hover { color:#fff; }
		
		.overlay-header .dt-sc-dark-bg .overlay #main-menu ul li.menu-item-megamenu-parent:hover > a, .overlay-header .dt-sc-dark-bg .overlay #main-menu ul > li.menu-item-simple-parent:hover > a { color:#ffffff; }
		
		.overlay-header .menu-active-with-icon #main-menu > ul.menu > li.current_page_item > a:before, .overlay-header .menu-active-with-icon #main-menu > ul.menu > li.current_page_ancestor > a:before, .overlay-header .menu-active-with-icon #main-menu > ul.menu > li.current-menu-item > a:before, .overlay-header .menu-active-with-icon #main-menu > ul.menu > li.current-menu-ancestor > a:before { width:auto; height:auto; position:inherit; }
		
		.overlay-header .overlay #main-menu > ul > li.current_page_item, .overlay-header .overlay #main-menu > ul > li.current_page_ancestor, .overlay-header .overlay #main-menu > ul > li.current-menu-item, .overlay-header .overlay #main-menu ul > li.current-menu-ancestor, .overlay-header .overlay #main-menu > ul > li.current_page_item > a, .overlay-header .overlay #main-menu > ul > li.current_page_ancestor > a, .overlay-header .overlay #main-menu > ul > li.current-menu-item > a, .overlay-header .overlay #main-menu ul > li.current-menu-ancestor > a { background:none; border:none; }	
		.overlay-header .overlay { background:#fff; }
		
 		.overlay-header .overlay #main-menu > ul.menu > li > a:after { width:0; border-bottom:1px solid; content:""; position:absolute; left:0; bottom:0; right:0; margin:auto; }
		.overlay-header .overlay #main-menu > ul.menu > li > a:hover:after, .overlay-header .overlay #main-menu > ul > li.current_page_item > a:after, .overlay-header .overlay #main-menu > ul > li.current_page_ancestor > a:after, .overlay-header .overlay #main-menu > ul > li.current-menu-item > a:after, .overlay-header .overlay #main-menu ul > li.current-menu-ancestor > a:after { width:50px; }  
		
		.overlay-header .header-bottom, .overlay-header .header-top { position:absolute; bottom:20px; left:0; right:0; margin:auto; text-align:center; }
		.overlay-header .header-top { top:20px; bottom:auto; }
		.overlay-header .header-top .dt-sc-sociable li a:hover { background-color:#000; }
		.overlay-header .menu-links { float:left; width:100%; }
		.overlay-header .overlay ul.menu-links li { display: inline-block; min-height: inherit; padding: 0 10px 0; }
		.overlay-header .overlay ul.menu-links li a { color:#000; font-size:16px; font-weight:500; }
		.overlay-header .menu-wrapper { margin:0; float:none; }
		
		.overlay-header #main-menu ul.menu > li > .megamenu-child-container { display:none; }
		#main-menu ul li.menu-item-simple-parent ul li { padding:0; }
		
	
	/*--------------------------------------------------------------
	5.0 - Default Color
	--------------------------------------------------------------*/
	#main-menu ul.menu > li > a { color:#000000; }
	.dt-sc-dark-bg #main-menu ul.menu > li > a { color:#ffffff; }
	
	.menu-active-highlight #main-menu > ul.menu > li.current_page_item > a, .menu-active-highlight #main-menu > ul.menu > li.current_page_ancestor > a, .menu-active-highlight #main-menu > ul.menu > li.current-menu-item > a, .menu-active-highlight #main-menu > ul.menu > li.current-menu-ancestor > a, .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current_page_item > a, .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current_page_ancestor > a, .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current-menu-item > a, .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current-menu-ancestor > a,  .left-header .menu-active-highlight #main-menu > ul.menu > li.current_page_item > a, .left-header .menu-active-highlight #main-menu > ul.menu > li.current_page_ancestor > a, .left-header .menu-active-highlight #main-menu > ul.menu > li.current-menu-item > a, .left-header .menu-active-highlight #main-menu > ul.menu > li.current-menu-ancestor > a, .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current_page_item:hover > a, .left-header .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current_page_item > a, .left-header .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current_page_ancestor > a, .left-header .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current-menu-item > a, .left-header .menu-active-highlight-with-arrow #main-menu > ul.menu > li.current-menu-ancestor > a { color:#ffffff; }

	.menu-active-highlight-grey #main-menu > ul.menu > li.current_page_item, .menu-active-highlight-grey #main-menu > ul.menu > li.current_page_ancestor, .menu-active-highlight-grey #main-menu > ul.menu > li.current-menu-item, .menu-active-highlight-grey #main-menu > ul.menu > li.current-menu-ancestor { background:#eeeeee; }
	
	.menu-active-with-icon #main-menu > ul.menu > li.current_page_item > a:before, .menu-active-with-icon #main-menu > ul.menu > li.current_page_ancestor > a:before, .menu-active-with-icon #main-menu > ul.menu > li.current-menu-item > a:before, .menu-active-with-icon #main-menu > ul.menu > li.current-menu-ancestor > a:before, .menu-active-with-icon #main-menu > ul.menu > li.current_page_item > a:after, .menu-active-with-icon #main-menu > ul.menu > li.current_page_ancestor > a:after, .menu-active-with-icon #main-menu > ul.menu > li.current-menu-item > a:after, .menu-active-with-icon #main-menu > ul.menu > li.current-menu-ancestor > a:after { background:#000000; }
	
	.menu-active-highlight.menu-active-with-icon #main-menu > ul.menu > li.current_page_item > a:before, .menu-active-highlight.menu-active-with-icon #main-menu > ul.menu > li.current_page_ancestor > a:before, .menu-active-highlight.menu-active-with-icon #main-menu > ul.menu > li.current-menu-item > a:before, .menu-active-highlight.menu-active-with-icon #main-menu > ul.menu > li.current-menu-ancestor > a:before,  .menu-active-highlight.menu-active-with-icon #main-menu > ul.menu > li.current_page_item > a:after, .menu-active-highlight.menu-active-with-icon #main-menu > ul.menu > li.current_page_ancestor > a:after, .menu-active-highlight.menu-active-with-icon #main-menu > ul.menu > li.current-menu-item > a:after, .menu-active-highlight.menu-active-with-icon #main-menu > ul.menu > li.current-menu-ancestor > a:after { background:#ffffff; }
	
	.menu-with-small-splitter #main-menu ul.menu > li:before { background:rgba(0, 0, 0, 0.1); }	
	.menu-with-slanting-splitter #main-menu ul.menu > li:before { background:rgba(0, 0, 0, 0.3);  }	
 	.left-header .dt-sc-dark-bg #main-menu > ul.menu > li > a, .left-header .dt-sc-dark-bg #main-menu > ul.menu > li:last-child > a { border-color:rgba(255, 255, 255, 0.2); }
	
	.dt-sc-dark-bg .menu-with-slanting-splitter #main-menu ul.menu > li:before { background:rgba(255, 255, 255, 0.8); }
	
	
	/*--------------------------------------------------------------
	6.0 - Sticky Header
	--------------------------------------------------------------*/
	
	.sticky-wrapper { width:100%; z-index:9999; float:left; position:relative; }
	.sticky-wrapper .main-header-wrapper, .main-header-wrapper { z-index:9999; }
	
	.header-align-center.fullwidth-menu-header .is-sticky .menu-wrapper { left:0px; }
	.header-align-left.fullwidth-menu-header .is-sticky .menu-wrapper, .header-align-center.fullwidth-menu-header .is-sticky .menu-wrapper, .standard-header .is-sticky .main-header-wrapper, .header-on-slider .is-sticky .main-header-wrapper, .fullwidth-header.semi-transparent-header.header-on-slider .is-sticky .main-header-wrapper, .header-align-left.fullwidth-menu-header.semi-transparent-header .is-sticky .menu-wrapper, .header-align-left.transparent-header .is-sticky .menu-wrapper, .transparent-header .is-sticky .main-header-wrapper, .fullwidth-header.transparent-header .is-sticky .main-header-wrapper { background:rgba(255, 255, 255, 0.9); border-bottom:1px solid rgba(0, 0, 0, 0.05); }
	
	.two-color-header .is-sticky .main-header-wrapper { background:none; border:none; }
	.overlay-header.header-on-slider .is-sticky .main-header-wrapper { border-bottom:0; }
	
	.header-align-left .main-header { display:block; }
	
 	.header-align-left.fullwidth-menu-header .is-sticky .menu-wrapper { left:0px; border-radius:0px; }
	.header-align-left.fullwidth-menu-header .is-sticky #main-menu, .header-align-left.fullwidth-menu-header .is-sticky #main-menu ul.menu { text-align:center; display:inline-block; float:none; }
	.header-align-left.fullwidth-menu-header .is-sticky .menu-icons-wrapper { display:inline-block; float:none; height:39px; }
	.header-align-left.fullwidth-menu-header .is-sticky .menu-icons-wrapper .search a span, .header-align-left.fullwidth-menu-header .is-sticky .menu-icons-wrapper .cart a span { line-height:50px; }
	.header-align-left.fullwidth-menu-header .is-sticky #main-menu ul.menu > li, .header-align-left.fullwidth-menu-header .is-sticky #main-menu ul.menu > li > a { float:none; text-align:center; display:inline-block; }
	
	
	.is-sticky #main-menu > ul.menu > li > a { line-height:55px; }
	.is-sticky .menu-active-with-single-border #main-menu ul.menu > li > a { line-height:51px; }
	
	.is-sticky .menu-icons-wrapper { height:50px; }
	.is-sticky .menu-icons-wrapper .search a span, .is-sticky .menu-icons-wrapper .cart a span { line-height:50px; }
	.fallin-header .is-sticky .nav-menu-icon { margin:25px 0 0 25px; }
	
	#main-menu ul.menu > li > a > i { margin-right:5px; }
	
	.header-on-slider .sticky-wrapper { position:absolute; }
	.fullwidth-menu-header.header-on-slider .sticky-wrapper { position: inherit; }

	
	#header-wrapper.dt-sc-dark-bg .is-sticky .main-header-wrapper { background:rgba(0, 0, 0, 0.9); border:none; }
	.two-color-header #header-wrapper.dt-sc-dark-bg .is-sticky .main-header-wrapper { background:transparent; }
	
	.boxed-header .is-sticky .main-header, .boxed-header.semi-transparent-header .is-sticky .main-header { background:none; }	
	
	.is-sticky .menu-active-with-icon #main-menu > ul.menu > li.current_page_item > a:before, .is-sticky .menu-active-with-icon #main-menu > ul.menu > li.current_page_ancestor > a:before, .is-sticky .menu-active-with-icon #main-menu > ul.menu > li.current-menu-item > a:before, .is-sticky .menu-active-with-icon #main-menu > ul.menu > li.current-menu-ancestor > a:before { bottom:10px; }
	.is-sticky .menu-active-with-icon #main-menu > ul.menu > li.current_page_item > a:after, .is-sticky .menu-active-with-icon #main-menu > ul.menu > li.current_page_ancestor > a:after, .is-sticky .menu-active-with-icon #main-menu > ul.menu > li.current-menu-item > a:after, .is-sticky .menu-active-with-icon #main-menu > ul.menu > li.current-menu-ancestor > a:after { bottom:5px; }
	
	.split-header.transparent-header .is-sticky #main-menu ul.menu > li > a { line-height:66px; }
	
	
	/*--------------------------------------------------------------
	7.0 - Simple Drop Down Menu
	--------------------------------------------------------------*/
	#main-menu ul.menu > li { position:relative; }
	
	#main-menu ul li.menu-item-simple-parent ul, #main-menu ul li.menu-item-simple-parent ul li ul, #main-menu ul li.menu-item-simple-parent ul li ul li ul { display:none; text-align:left; z-index:9999; }
	
	#main-menu ul li.menu-item-simple-parent ul { width:260px; background:#ffffff; padding:10px 0px 10px; position:absolute; top:100%; left:0px; float:left; margin-top:0; border: 1px solid #f5f5f5; border-bottom-width: 5px; }
	
	#main-menu ul li.menu-item-simple-parent ul li { display:block; padding:0px 15px; position:relative; width:100%; } 
	#main-menu ul li.menu-item-simple-parent ul li a { color:#000000; position:relative; padding:10px 0px 10px 10px; line-height:24px; display:block; letter-spacing:0.5px; border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
	#main-menu ul li.menu-item-simple-parent ul li:last-child > a { border:none; }
	
	#main-menu ul li.menu-item-simple-parent ul li ul { left:100%; top:0px; padding-top:5px; }
	.fullwidth-header.stretched-header #main-menu ul li.menu-item-simple-parent ul li ul { left:auto; right:100%; }
	
	#main-menu ul li.menu-item-simple-parent:last-child ul, #main-menu ul li.menu-item-simple-parent:nth-child(6) ul, #main-menu ul li.menu-item-simple-parent:nth-child(7) ul { right:0px; left:auto; }
	#main-menu ul li.menu-item-simple-parent:last-child ul li ul, #main-menu ul li.menu-item-simple-parent:nth-child(6) ul li ul, #main-menu ul li.menu-item-simple-parent:nth-child(7) ul li ul { right:100%; left:auto; }
	
	#main-menu ul li.menu-item-simple-parent ul li a .fa { margin-right:10px; position:relative; top:-1px; }
	
	#main-menu ul li.menu-item-simple-parent.dt-sc-dark-bg ul li a, #main-menu ul li.menu-item-simple-parent.dt-sc-dark-bg ul li a .fa { color:#ffffff; }
	
	
	/*--------------------------------------------------------------
	8.0 - Mega Menu
	--------------------------------------------------------------*/
	#main-menu ul.menu > li > .megamenu-child-container { display:none; z-index:9999; }
	
	#main-menu .megamenu-child-container { background:#ffffff; padding:0px; position:absolute; top:100%; left:0px; float:left; border: 1px solid #f5f5f5; border-bottom-width: 5px; }
	
	#main-menu .megamenu-child-container img { max-width:100%; }	
	
	
	/*----*****---- << New Menu >> ----*****----*/
	
	#main-menu .megamenu-child-container > ul.sub-menu { padding:0px; margin:0px; text-align:left; }
	#main-menu .megamenu-child-container > ul.sub-menu > li > a, #main-menu .megamenu-child-container > ul.sub-menu > li > .nolink-menu { text-transform: uppercase; font-weight: bold; padding: 10px 0 0; margin-bottom: 15px; display: block; font-size: 14px; color: #000; width: 100%; float: left; position: relative; }
	
	#main-menu .megamenu-child-container ul.sub-menu li a { position: relative; }
	#main-menu .megamenu-child-container ul.sub-menu li a:before, #main-menu .megamenu-child-container ul.sub-menu li .nolink-menu:before { position: absolute; content: ""; display: block; top: 50%; right: 10px; width: 25px; height: 12px; background-repeat: no-repeat; transform: translateY(-50%); -moz-transform: translateY(-50%); -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); }

#main-menu .megamenu-child-container ul.sub-menu li li a:before, #main-menu .megamenu-child-container ul.sub-menu li li .nolink-menu:before { display: none; }
	
	#main-menu .megamenu-child-container > ul.sub-menu > li a .fa, #main-menu .megamenu-child-container > ul.sub-menu > li .fa { margin-right: 5px; }
	#main-menu .megamenu-child-container > ul.sub-menu > li .featured-product .featured-tag a .fa, #main-menu .megamenu-child-container > ul.sub-menu > li .featured-product .featured-tag .fa { margin-right: 0; }
	
	#main-menu .megamenu-child-container > ul.sub-menu > li > a .menu-item-description, #main-menu .menu-item-description { color:#696969; display:block; font-weight:normal; padding:0px; text-transform:none; font-size:12px; position:relative; top:-3px; line-height:normal; }
	.dt-megamenu-custom-content { display:block; padding:10px 0px 0px; }
	
	#main-menu .megamenu-child-container ul.sub-menu > li > ul { padding:0px; margin:0px; }
	#main-menu .megamenu-child-container ul.sub-menu > li > ul li { display:block; padding:0px; width: 100%; float: left; }
	
	#main-menu .megamenu-child-container ul.sub-menu > li > ul li, #main-menu .megamenu-child-container ul.sub-menu > li > ul li a { clear:both; }
	
	#main-menu .megamenu-child-container ul.sub-menu > li > ul li a { color:#000000; position:relative; padding:10px 0px; font-size:14px; line-height:24px; display:block; border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
	#main-menu .megamenu-child-container ul.sub-menu > li > ul li:last-child a { border:none; }
	#main-menu .megamenu-child-container ul.sub-menu > li > ul li a .fa { margin-right:10px; position:relative; top:-1px; }
	
	#main-menu .megamenu-child-container ul.sub-menu > li > ul > li > ul { padding-left:20px; }
	
	#main-menu .megamenu-child-container > ul.sub-menu > li.fulwidth-image-link > .nolink-menu { padding:0px; }
	#main-menu .megamenu-child-container > ul.sub-menu > li.fulwidth-image-link ul.sub-menu { padding-top:0px; }
	#main-menu .megamenu-child-container > ul.sub-menu > li.fulwidth-image-link img { max-width:100%; }
	
	#main-menu .megamenu-child-container.dt-sc-dark-bg > ul.sub-menu > li > a, #main-menu .megamenu-child-container.dt-sc-dark-bg > ul.sub-menu > li > .nolink-menu, #main-menu .megamenu-child-container.dt-sc-dark-bg ul.sub-menu > li > ul li a, #main-menu .megamenu-child-container.dt-sc-dark-bg ul.sub-menu > li > ul li a .fa { color:#ffffff; }
	#main-menu .megamenu-child-container.dt-sc-dark-bg > ul.sub-menu > li > a .menu-item-description, #main-menu .dt-sc-dark-bg .menu-item-description { color:rgba(255, 255, 255, 0.5); }	
	
	.dt-sc-dark-bg ul.menu li ul, .dt-sc-dark-bg ul.menu li h2, .dt-sc-dark-bg ul.menu li h3, .dt-sc-dark-bg ul.menu li h4, .dt-sc-dark-bg ul.menu li h5, .dt-sc-dark-bg ul.menu li h6 { color:#000000; }
	
	
	
	/*--------------------------------------------------------------
	9.0 - Mobile Menu
	--------------------------------------------------------------*/
	.dt-menu-toggle { display:none; color:#ffffff; font-size:16px; font-weight:bold; text-transform:uppercase; padding:10px 15px; cursor:pointer; position:relative; z-index:1; }
	.dt-menu-toggle-icon { background:#ffffff; display:inline-block; height:2px; position:absolute; text-align:left; width:20px; right:15px; top:0px; bottom:0px; margin:auto; }
	.dt-menu-toggle-icon:before, .dt-menu-toggle-icon:after { content:""; position:absolute; background:#ffffff; height:2px; width:20px; left:0px; top:-5px; }
	.dt-menu-toggle-icon:after { top:5px; }
	
	#main-menu ul.menu li a.dt-menu-expand { border:none !important; cursor:pointer; display: none; font-family:FontAwesome; font-weight:normal; font-size:20px; height:38px; line-height:38px; margin:auto; padding: 0 !important; position:absolute !important; right: 0; top: 0; text-align:center; width:38px !important; }
	.header-align-left.fullwidth-menu-header .is-sticky #main-menu ul.menu > li > a.dt-menu-expand { display:none; }
	#main-menu ul li.menu-item-simple-parent.menu-links-with-bg ul > li > a.dt-menu-expand, #main-menu ul li.menu-item-simple-parent.menu-links-with-arrow.single ul > li > a.dt-menu-expand:before { display:none; }
	#main-menu .menu-item-megamenu-parent.menu-title-with-bg a.dt-menu-expand { height:44px; line-height:44px; margin:0px; }
	#main-menu ul.menu li a.dt-menu-expand.dt-mean-clicked { font-family:monospace; }
	
	
	#main-menu ul li.menu-item-simple-parent.with-box-shadow ul, #main-menu .menu-item-megamenu-parent.with-box-shadow .megamenu-child-container { -webkit-box-shadow:0px 0px 10px 0px rgba(0, 0, 0, 0.1); -moz-box-shadow:0px 0px 10px 0px rgba(0, 0, 0, 0.1); box-shadow:0px 0px 10px 0px rgba(0, 0, 0, 0.1); }
	
	/*----*****---- << Mega Menu Title With Background >> ----*****----*/
	#main-menu .menu-item-megamenu-parent.menu-title-with-bg .megamenu-child-container > ul.sub-menu > li > a, #main-menu .menu-item-megamenu-parent.menu-title-with-bg .megamenu-child-container > ul.sub-menu > li > .nolink-menu { padding:10px 15px; margin-bottom:10px;  }
	
	#main-menu .megamenu-child-container.menu-hasbg { background-color:#ffffff; background-position:right bottom; background-repeat:no-repeat; }
	
	/*----*****---- << Mega Menu Title With Border >> ----*****----*/
	#main-menu .menu-item-megamenu-parent.menu-title-with-border .megamenu-child-container > ul.sub-menu > li > a, #main-menu .menu-item-megamenu-parent.menu-title-with-border .megamenu-child-container > ul.sub-menu > li > .nolink-menu { padding-bottom:10px; }
	
	/*----*****---- << Mega Menu Links With Background >> ----*****----*/
	#main-menu .menu-item-megamenu-parent.menu-links-with-bg .megamenu-child-container ul.sub-menu > li > ul > li, #main-menu .menu-item-megamenu-parent.menu-links-with-border .megamenu-child-container ul.sub-menu > li > ul > li { width:100%; }
	#main-menu .menu-item-megamenu-parent.menu-links-with-bg .megamenu-child-container ul.sub-menu > li > ul > li > a, #main-menu ul li.menu-item-simple-parent.menu-links-with-bg ul > li > a { padding:7px 12px; margin-bottom:7px;  float:left; width:100%; display:inline-block; }
	
	/*----*****---- << Mega Menu Links With Border >> ----*****----*/
	#main-menu .menu-item-megamenu-parent.menu-links-with-border .megamenu-child-container ul.sub-menu > li > ul > li > a, #main-menu ul li.menu-item-simple-parent.menu-links-with-border ul > li > a { padding-bottom:10px; }
	#main-menu .menu-item-megamenu-parent.menu-links-with-border .megamenu-child-container ul.sub-menu > li > ul > li:last-child > a, #main-menu ul li.menu-item-simple-parent.menu-links-with-border ul > li:last-child > a { border:none !important; }
	#main-menu .menu-item-megamenu-parent.menu-links-with-border .megamenu-child-container ul.sub-menu > li > ul > li > a {  float:left; width:100%; display:inline-block; } 
	
	/*----*****---- << Mega Menu Links With Arrow >> ----*****----*/
	#main-menu .menu-item-megamenu-parent.menu-links-with-arrow .megamenu-child-container ul.sub-menu > li > ul > li > a, #main-menu ul li.menu-item-simple-parent.menu-links-with-arrow ul > li > a { padding-left:15px; }
	#main-menu .menu-item-megamenu-parent.menu-links-with-arrow .megamenu-child-container ul.sub-menu > li > ul > li > a:before, #main-menu ul li.menu-item-simple-parent.menu-links-with-arrow ul > li > a:before { position:absolute; left:0px; font-family:FontAwesome; font-size:14px; }
	#main-menu .menu-item-megamenu-parent.menu-links-with-arrow.single .megamenu-child-container ul.sub-menu > li > ul > li > a:before, #main-menu ul li.menu-item-simple-parent.menu-links-with-arrow.single ul > li > a:before { content:"\f105"; }
	#main-menu .menu-item-megamenu-parent.menu-links-with-arrow.double .megamenu-child-container ul.sub-menu > li > ul > li > a:before, #main-menu ul li.menu-item-simple-parent.menu-links-with-arrow.double ul > li > a:before { content:"\f101"; }
	#main-menu .menu-item-megamenu-parent.menu-links-with-arrow.disc .megamenu-child-container ul.sub-menu > li > ul > li > a:before, #main-menu ul li.menu-item-simple-parent.menu-links-with-arrow.disc ul > li > a:before { content:"\f111"; font-size:8px; }	
	
	#main-menu .menu-item-megamenu-parent.menu-links-with-bg.menu-links-with-arrow .megamenu-child-container ul.sub-menu > li > ul > li > a, #main-menu ul li.menu-item-simple-parent.menu-links-with-bg.menu-links-with-arrow ul > li > a { padding-left:27px; }
	#main-menu .menu-item-megamenu-parent.menu-links-with-bg.menu-links-with-arrow .megamenu-child-container ul.sub-menu > li > ul > li > a:before, #main-menu ul li.menu-item-simple-parent.menu-links-with-bg.menu-links-with-arrow ul > li > a:before { left:12px; }
   
	
	#main-menu ul li ul.children, #main-menu ul li ul li ul.children, #main-menu ul li ul li ul li ul.children { display:none; }
	#main-menu ul li:hover > ul.children, #main-menu ul li ul li:hover ul.children, #main-menu ul li ul li ul li:hover ul.children { display:block; }
	
	#main-menu ul li ul.children { background: #ffffff; float: left; left: 0; padding: 10px 0; position: absolute; top: 100%; width: 260px; box-shadow: 0 0 3px rgba(0, 0, 0, 0.1); margin:0; text-align:left; }
	#main-menu ul li ul.children li { box-sizing: border-box; display: block; padding: 0 15px; position: relative; width: 100%; }
	#main-menu ul li ul.children li a { color: #000000; display: block; font-size: 12px; font-weight:bold; line-height: 24px; padding: 10px 0 10px 10px; position: relative; letter-spacing:0.5px; } 
	#main-menu ul li ul li ul.children { left: 100%; padding-top: 5px;  top: -10px;  }
	
	.left-header #main-menu ul li ul.children { left:100%; top:-10px; }
	
	
	/*--------------------------------------------------------------
	10.0 - Slider
	--------------------------------------------------------------*/
	
	#slider { float:left; clear:both; width:100%; margin:0px; padding:0px; display:block; }
	#slider img { max-width:100%; }
	
	
	/*--------------------------------------------------------------
	11.0 - Title & Breadcrumb
	--------------------------------------------------------------*/
	
	.section-wrapper { float:left; clear:both; width:100%; margin-bottom:0px; padding:0px; }
	.section-wrapper.fullwidth-section > .container { width:100%; }
	.section-wrapper.fullwidth-section { position:relative;  }
	.dark-overlay, .extend-bg-fullwidth-left, .extend-bg-fullwidth-right, .light-overlay { position:relative; }
	.dark-overlay:before, .light-overlay:before { position:absolute; content:""; width:100%; height:100%; left:0px; top:0px; display:inline !important; z-index:0; }
	
	.section-wrapper .container { z-index:9; }
		
	.main-title-section-wrapper { float:left; width:100%; margin:0px 0px 65px; padding:40px 0px 43px; clear:both; background-color:rgba(0, 0, 0, 0.05); }
	.main-title-section-wrapper.dt-sc-dark-bg { background-color:rgba(0, 0, 0, 0.7); }
	
 	.header-on-slider .main-title-section-wrapper { background-size: cover !important; padding-top:130px; }
	.home #header-wrapper { margin-bottom: 50px; }
	.home.site-with-fixed-footer #header-wrapper { margin-bottom: 0; }
	.page-with-slider.header-below-slider #slider, .home.overlay-header #header-wrapper, .home.header-on-slider #header-wrapper { margin-bottom:0; }
	.page-with-slider.overlay-header #slider, .page-with-slider.left-header #slider { margin-bottom:50px; }
	
	.left-header.header-on-slider .main-title-section-wrapper { padding: 60px 0; }
	
 	.home.header-on-slider.page-with-slider .main-title-section-wrapper { padding-top:90px; }
	.header-on-slider.no-breadcrumb #main { margin-top:90px; }
	.left-header.header-on-slider.no-breadcrumb #main, .header-on-slider.no-breadcrumb.remove-top-spaces #main { margin-top:0; }
	.home.header-on-slider.page-with-slider #main, .header-on-slider.page-with-slider #main { margin-top:0; }
	
	#main.with-fixed-footer { background-color: #fff; position: relative; z-index: 1; }
 	#main.with-fixed-footer.footer-darkbg:after { content:""; position:absolute; left:0; bottom: auto; top: 100%; width:100%; height:100px; background: rgba(0,0,0,0.5);
		background: -moz-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.5)), color-stop(100%, rgba(255,255,255,0)));
		background: -webkit-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%);
		background: -o-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%);
		background: -ms-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff', GradientType=0 ); }
 	
	.main-title-section { float:left; width:100%; margin:0px; padding:0px; position:relative; }
	.main-title-section h1 {-ms-word-wrap: break-word; word-wrap: break-word; font-size:30px; font-weight:700; color:#000000; margin-bottom:0; }	
 	
	.breadcrumb {-ms-word-wrap: break-word; word-wrap: break-word; clear:both; float:left; width:100%; margin:0px; padding:0px; font-size:13px; font-weight:500; color:rgba(0, 0, 0, 0.6); }
	.breadcrumb a { color:#000000; }	
	.breadcrumb .fa { display:inline-block; margin:0px 4px; padding:0px; }
	.breadcrumb span.current { color:rgba(0, 0, 0, 0.5); }
	
	.breadcrumb .fa.default { display:inline-block; height:14px; width:1px; -webkit-transform:rotate(30deg);  -moz-transform-origin:0 0;  -moz-transform:rotate(30deg);  -ms-transform-origin:0 0;  -ms-transform:rotate(30deg);  transform-origin:0 0; transform:rotate(30deg); background:#000000; top:3px; position:relative; margin:0px 5px 0px 10px; }
	
	.main-title-section.aligncenter { text-align:center; }
	.main-title-section.alignright { text-align:right; }
	
	.main-title-section-wrapper.breadcrumb-left .breadcrumb { clear:none; float:left; width:35%; padding-right:20px;  margin-top:13px; }
	.main-title-section-wrapper.breadcrumb-left .main-title-section { float:right; width:65%; text-align:right; }
	.main-title-section-wrapper.breadcrumb-right .breadcrumb { clear:none; float:right; text-align:right; width:35%; padding-left:20px;  margin-top:13px; }
	.main-title-section-wrapper.breadcrumb-right .main-title-section { float:left; width:65%; }
	
	.main-title-section-wrapper.breadcrumb-top-left-title-center .main-title-section { width:100%; text-align:center; }
	.main-title-section-wrapper.breadcrumb-top-left-title-center .breadcrumb { margin-top:-70px; width:100%; float:left; }
	
	.main-title-section-wrapper.breadcrumb-top-right-title-center .main-title-section { width:100%; text-align:center; }
	.main-title-section-wrapper.breadcrumb-top-right-title-center .breadcrumb { margin-top:-70px; width:100%; float:right; text-align:right; }
	
	.dt-sc-dark-bg .breadcrumb { color:rgba(255, 255, 255, 0.6); }
	.dt-sc-dark-bg .breadcrumb a, .dt-sc-dark-bg .main-title-section h1 { color:#ffffff; }	
	.dt-sc-dark-bg .breadcrumb a:hover, .dt-sc-dark-bg .breadcrumb span.current { color:rgba(255, 255, 255, 0.6); }
	.dt-sc-dark-bg .breadcrumb .fa.default { background:#ffffff; }
	
	.page.header-on-slider.transparent-header .dt-sc-dark-bg .main-header-wrapper { background:transparent; }
	.home-page.page.header-on-slider.transparent-header .dt-sc-dark-bg .main-header-wrapper { background:transparent; }
	
	.fullwidth-header.semi-transparent-header .dt-sc-dark-bg .main-header-wrapper, .boxed-header.semi-transparent-header .dt-sc-dark-bg .main-header, .left-header.semi-transparent-header .dt-sc-dark-bg .main-header-wrapper, .left-header.semi-transparent-header .dt-sc-dark-bg .main-header, .icon-menu-header.semi-transparent-header .dt-sc-dark-bg .main-header-wrapper { background:rgba(0, 0, 0, 0.7); }
	
	.remove-top-spaces .main-title-section-wrapper, .remove-top-spaces.home #header-wrapper { margin:0; }

	
	/*--------------------------------------------------------------
	12.0 - Footer
	--------------------------------------------------------------*/
	
	#footer { width:100%; float:left; clear:both; margin:0px; }
	.footer-widgets { padding:0; width:100%; float:left; clear:both; margin:0px; background-color:#fff; }
	.fixed-footer .fixed-footer-container { position:fixed; left:0; bottom:0; right:0; padding:0; margin:0; }
	.fixed-footer { position:relative; }
  
	#footer .container { z-index:9; }
	
	.footer-copyright .alignright img { float:right; }
	
	.footer-copyright { float:left; margin:0px; padding:30px 0px; width:100%; clear:both; }
	.copyright-left { width:48%; float:left; margin:0px; padding:0px; }
	.copyright-right { float:right; margin:0px; padding:0px; width:48%; text-align:right; }
	
	.footer-copyright .menu-links, #footer .menu-links { text-align:right; margin:0px; padding:0px; }
	.footer-copyright .menu-links li, #footer .menu-links li { margin:0px 0px 0px 29px; display:inline-block; padding:0px; float:none; }
	
	.aligncenter .menu-links, #footer .aligncenter .menu-links { text-align:center; }
	.aligncenter .menu-links li, #footer .aligncenter .menu-links li { float:none; display:inline-block; text-align:center; margin:0 15px; }
	
	.menu-links.with-splitter li { margin:0px; }
	.footer-copyright .menu-links.with-splitter li a { padding:0px 15px; border-right:1px solid; }
	.footer-copyright .menu-links.with-splitter li:last-child a { border-right:0px; }
	
	.copyright-align-center { text-align:center; }
	.copyright-align-center .copyright-left, .copyright-align-center .copyright-right, .copyright-align-center .dt-sc-sociable, .copyright-align-center .copyright-right { text-align:center; width:100%; float:left; clear:both; margin:10px 0px; }
	.copyright-align-center .menu-links, .copyright-align-center .dt-sc-sociable { text-align:center; }
	.copyright-align-center .menu-links li, .copyright-align-center .dt-sc-sociable li, .copyright-align-center .dt-sc-sociable li a { float:none; display:inline-block; text-align:center; }
	
	.custom-social li a span { margin:0px 10px 0px 0px; font-size:18px; width:20px; text-align:center; }
	
	.copyright-right .dt-sc-sociable { text-align:right; width:100%; }
	.copyright-right .dt-sc-sociable li { float:none; display:inline-block; text-align:right; }	
	
	.dt_ajax_subscribe_msg .success-msg { color: #15d401; }
	.dt_ajax_subscribe_msg .error-msg { color:#bd0404; }
	
	
	/*--------------------------------------------------------------
	13.0 - Side Navigation
	--------------------------------------------------------------*/
	
	.side-navigation { width:27%; float:left; margin:0px 2% 0px 0px; padding:0px; }
	.side-navigation-content { width:71%; float:left; margin:0px; padding:0px; }
	
	.side-navigation-content img { max-width:100%; }
	
	.side-nav-container { float:left; margin:0px; padding:0px; width:98%; }
	ul.side-nav { border:1px solid; border-bottom:0px; margin:0px; padding:0px; float:left; width:100%; }
	ul.side-nav li { float:left; width:100%; display:block; padding:0px; border-bottom:1px solid; }
	ul.side-nav li a { color:#2d2d29; padding:12px 0px 11px 60px; display:block; position:relative; }
	ul.side-nav li a:before { content:"\f0da"; font-family:FontAwesome; font-style:normal; font-weight:normal; color:#444446; position:absolute; left:0; top:0; margin:auto; width:45px; height:100%; text-align:center; line-height:47px; -webkit-transition:all 0.3s linear; -moz-transition:all 0.3s linear; -o-transition:all 0.3s linear; -ms-transition:all 0.3s linear; transition:all 0.3s linear; }
	
	/* ul.side-nav > li.current_page_item > a:after, ul.side-nav > li > ul > li.current_page_item > a:after { content:""; position:absolute; background:url(/image/side-nav-active.png) no-repeat; width:11px; height:46px; right:-11px; top:0px; } */

	ul.side-nav li a:hover:before, ul.side-nav > li.current_page_item > a:before, ul.side-nav > li > ul > li.current_page_item > a:before, ul.side-nav > li > ul > li > ul > li.current_page_item > a:before { color:#ffffff; }
	
	ul.side-nav li ul { border-top:1px solid; margin:0px; padding-left:0px; }
	ul.side-nav li ul li:last-child { border:none; padding-bottom:0px; }
	ul.side-nav li ul li a { padding-left:85px; }
	ul.side-nav ul ul li a { padding-left:120px; }
	
	ul.side-nav li a:before, ul.side-nav > li > a:hover, ul.side-nav > li > ul > li > a:hover, ul.side-nav > li > ul > li > ul > li > a:hover, ul.side-nav > li.current_page_item > a, ul.side-nav > li > ul > li.current_page_item > a, ul.side-nav > li > ul > li > ul > li.current_page_item > a { background:#ededed; }
	ul.side-nav, ul.side-nav li, ul.side-nav li ul { border-color:#e3e3e3; }
	
	ul.side-nav li a:before, ul.side-nav > li > a:hover, ul.side-nav > li > ul > li > a:hover, ul.side-nav > li > ul > li > ul > li > a:hover { color:#2d2d29; }
	
	/*--------------------------------------------------------------
	14.0 - Style Picker
	--------------------------------------------------------------*/
	.dt-style-picker-wrapper { position:fixed; z-index:99999; left:0px; top:150px; }
	#dt-style-picker { background:#f9f9f9; width:225px; float:left; position:absolute; top:0px; z-index:1; margin:0px; padding:0px; text-align:center; }
	#dt-style-picker h2 { font-size:17px; font-weight:normal; line-height:48px; text-transform:capitalize; width:100%; color:#fff; margin:0px; padding:0px; background-color:#1a1a1a; }
	#dt-style-picker h3 { font-size:15px; font-weight:normal; padding:15px 0; width:100%; color:#000; margin:0px; display:inline-block; }
	#dt-style-picker ul { float:left; margin:0px; padding:15px 0px; text-align:center; width:225px; background-image:linear-gradient(to bottom, #f7f4f4, #ffffff); border-top:1px solid rgba(23, 24, 26, 0.08); }
	#dt-style-picker ul li { display:inline-block; display:inline; list-style-type:none; padding:0px; margin:0px 4px 6px 4px; }
	#dt-style-picker ul li a { background:#f9f9f9; border:1px solid #ffffff; padding:3px; display:inline-block; margin:0px; line-height:0; }
	#dt-style-picker ul li a.selected, #dt-style-picker ul li a:hover { border:1px solid #d7d7d7; }
	#dt-style-picker ul li a img, .style-picker-ico > img{border:1px solid #f0f0f0;}
	#dt-style-picker ul.scheme-picker li a { padding:7px; }
	#dt-style-picker ul.scheme-picker li a img { height:auto; border:0; }
	#dt-style-picker .hr { width:100%; height:10px; display:block; float:left; clear:both; position:relative; z-index:1; margin:3px 0px; }
	.style-picker-ico { background:#1a1a1a; height:48px; left:224px; position:absolute; text-align:center; top:0px; width:50px; border-left:0; }
	.style-picker-ico > i { color:#fff; font-size:27px; line-height:47px; }
	.style-picker-ico.control-open > i:before { content: "\eb69"; font-family: 'IcoMoon-Ultimate'; }
	#dt-style-picker h3.color-scheme { background:#1a1a1a; color:#fff; }
	
		
	/*--------------------------------------------------------------
	15.0 - Loader
	--------------------------------------------------------------*/
	body .loader { height: 100%; left: 0; position: fixed; top: 0; width: 100%; z-index: 999991; background:#fff; }
	.loader-inner { display: flex; flex-grow: 1; flex-direction: column; width: 100%; justify-content: center; height: 100%; }
	.loader-icon { width:60px; height:60px; background:#8e2f9e; border-radius:50%; overflow:hidden; position:absolute; margin:auto; top:0; right:0; bottom:0; left:0; animation: loader-icon 1s infinite; -webkit-animation: loader-icon 1s infinite; -moz-animation: loader-icon 1s infinite; -ms-animation: loader-icon 1s infinite; }

	.loader-icon .loader-hexagon { width:32px; height:20px; background:#fff; position:absolute; left:50%; margin-left:-16px; top:50%; margin-top:-10px; }
	.loader-icon .loader-hexagon::before { content:''; position:absolute; width:0; height:0; border-bottom:8px solid #fff; border-left:16px solid transparent; border-right:16px solid transparent; top:-8px; }
	.loader-icon .loader-hexagon::after { content:''; position:absolute; width:0; height:0; border-top:8px solid #fff; border-left:16px solid transparent; border-right:16px solid transparent; bottom:-8px; }
	.loader-icon .loader-lines { position:absolute; width: 40px; height:50px; background:transparent; border-top:4px solid #fff; border-radius:12px; }
	.loader-icon .loader-lines:nth-child(2) { transform:rotate(30deg); -webkit-transform:rotate(30deg); -moz-transform:rotate(30deg); -ms-transform:rotate(30deg); left:-4px; top:7px; }
	.loader-icon .loader-lines:nth-child(3) { transform:rotate(150deg); -webkit-transform:rotate(150deg); -moz-transform:rotate(150deg); -ms-transform:rotate(150deg); left:14px; top:-7px; }
	.loader-icon .loader-lines:nth-child(4) { transform:rotate(270deg); -webkit-transform:rotate(270deg); -moz-transform:rotate(270deg); -ms-transform:rotate(270deg); left:19px; top:15px; }

	@keyframes loader-icon {
	  to { transform:rotate(360deg); -webkit-transform:rotate(360deg); -moz-transform:rotate(360deg); -ms-transform:rotate(360deg); }
	}


	/*--------------------------------------------------------------
	16.0 - Totop
	--------------------------------------------------------------*/
	#toTop { position:fixed; display:none; text-decoration:none; bottom:30px; right:30px; overflow:hidden; width:62px; height:50px; border:none; text-indent: -9999px; z-index:999; opacity:0.9; border-radius: 50%; line-height: 16px; font-size: 10px; text-align: center; /*background-image:url(../image/to-top.png); background-repeat:no-repeat;*/ }
	#toTop:hover { opacity:1; }
	#toTop:after { content:""; background-image:url(/public/image/top.png); background-repeat:no-repeat; background-position:center center; position:absolute; top:0; bottom:0; left:0; right:0; margin:auto; background-size:50px; -webkit-animation: pulse 0.9s infinite linear; -moz-animation: pulse 0.9s infinite linear; animation: pulse 0.9s infinite linear; }
	#toTop:active, #toTop:focus { outline:none; }
	
	.uiToTop #toTop { position:relative; right:auto; bottom:auto; display:inline-block; }
	.uiToTop { vertical-align:middle; }
	#toTop.type2 { background:#bdc3c7; width:45px; height:45px; border-radius: 50%; }
	#toTop.type2:after { content: "\f106"; font-family: fontawesome; font-size: 32px; color: red; background: none; z-index: 9; text-indent: 0; line-height: 41px; -webkit-animation: flipInX 1s infinite linear; -moz-animation: flipInX 1s infinite linear; animation: flipInX 1s infinite linear; color:#000; text-align:center; }


	/*--------------------------------------------------------------
	17.0 - Default Color
	--------------------------------------------------------------*/
	
	.main-header #searchform { border-color:#e5e5e5; }
	.main-header #searchform:before { color:#c2c2c2; }
	.main-header #searchform input[type="text"], .cart-info:before, .cart-total:before { color:#aaaaaa; }
	
	.fullwidth-menu-header .menu-wrapper { border-top-color:#eeeeee; border-bottom-color:#eeeeee; }
	.boxed-header .main-header, .left-header .main-header-wrapper, .main-header #searchform { background:#ffffff; }
	
	
	/*----*****---- << Top bar >> ----*****----*/
	.top-bar { background:#eeeeee; }
	.dt-sc-dark-bg.top-bar a { color:#ffffff; }
	
	
	/*----*****---- << Fullwidth Header >> ----*****----*/
	.transparent-header .main-header-wrapper, .transparent-header .menu-wrapper, .transparent-header .dt-sc-dark-bg .main-header-wrapper, .fullwidth-header.transparent-header .main-header-wrapper  { background:none; }
	
	.main-header-wrapper { background:rgba(255,255,255,1); }
	.dt-sc-dark-bg .main-header-wrapper { background:rgba(0,0,0,1); }
 	
	.fullwidth-header.stretched-header .main-header-wrapper > .container { width:100%; }
	.fullwidth-header.stretched-header .main-header-wrapper { padding: 0 30px; }
	
	
	/*----*****---- << Two Color Header >> ----*****----*/
	.two-color-header.semi-transparent-header .dt-sc-dark-bg .main-header-wrapper:after, .two-color-header.transparent-header .dt-sc-dark-bg .is-sticky .main-header-wrapper:after { background:rgba(0,0,0,0.7); }
	
	.two-color-header.semi-transparent-header .main-header-wrapper:after, .two-color-header.transparent-header .is-sticky .main-header-wrapper:after, .fullwidth-header.semi-transparent-header .main-header-wrapper, .left-header.semi-transparent-header .main-header-wrapper, .left-header.semi-transparent-header .main-header { background:rgba(255,255,255,0.7); }
	.two-color-header .dt-sc-dark-bg .main-header-wrapper:after { background:#000000; }
	
	.two-color-header.semi-transparent-header #main-header-wrapper.main-header-wrapper, .two-color-header.transparent-header .main-header-wrapper, .two-color-header.transparent-header .main-header-wrapper:after, .two-color-header.transparent-header .main-header-wrapper:before, .two-color-header.standard-header .is-sticky .main-header-wrapper, .two-color-header .main-header-wrapper { background:none; }
	
	.two-color-header .main-header-wrapper:after { background:rgba(255,255,255,1); }
	
	/*----*****---- << Boxed Header >> ----*****----*/
	.boxed-header.semi-transparent-header .main-header, .semi-transparent-header.boxed-header .is-sticky .main-header-wrapper { background:rgba(255,255,255,0.7); }
	
	.page.header-on-slider.boxed-header.transparent-header .dt-sc-dark-bg .is-sticky .main-header, .boxed-header.transparent-header .dt-sc-dark-bg .main-header, .boxed-header.transparent-header .dt-sc-dark-bg .is-sticky .main-header, .boxed-header .dt-sc-dark-bg .main-header-wrapper, .boxed-header .dt-sc-dark-bg .is-sticky .main-header, .boxed-header .main-header-wrapper, .semi-transparent-header.boxed-header .main-header-wrapper, .transparent-header.boxed-header .main-header, .page.header-on-slider.boxed-header.transparent-header .dt-sc-dark-bg .main-header-wrapper, .boxed-header.transparent-header .dt-sc-dark-bg .main-header-wrapper, .boxed-header.semi-transparent-header .dt-sc-dark-bg .is-sticky .main-header { background:none; }
	
	.boxed-header .main-header { background:rgba(255,255,255,1); }
	.boxed-header .dt-sc-dark-bg .main-header { background:rgba(0,0,0,1); }

	/*----*****---- << Left Header >> ----*****----*/
	.left-header-footer .dt-sc-sociable.filled li a:hover, .left-header-creative .left-header-footer p { color:#000000; }
	.dt-sc-dark-bg .left-header-footer .dt-sc-sociable.filled li a:hover, .left-header-creative .dt-sc-dark-bg .left-header-footer p, .dt-sc-dark-bg .left-header-footer .dt-sc-sociable li a:hover { color:#fff; }
	.left-header-footer p { color:rgba(0, 0, 0, 0.4); }
	.left-header-footer .dt-sc-sociable.filled li a { background:#000000 !important;}
	.left-header-footer .dt-sc-sociable.filled li a:hover { background:rgba(0, 0, 0, 0.1) !important; }
	
	.transparent-header.left-header .main-header-wrapper, .transparent-header.left-header .main-header, .left-header.transparent-header #header-wrapper, .left-header.transparent-header .dt-sc-dark-bg .main-header-wrapper, .left-header.transparent-header .dt-sc-dark-bg .main-header { background:none; }
	
 	
	/*----*****---- << Header Menu Icons Wrapper >> ----*****----*/
	.cart-info { background:#f3f3f3; }
	.menu-icons-wrapper.rounded-icons .search a span, .menu-icons-wrapper.rounded-icons .cart a span, .dt-sc-vertical-small-separator, .cart-icon span, .live-chat a:hover { background:#000000; }
	
	.menu-icons-wrapper .search a, .menu-icons-wrapper .cart a { color:#000000; }
	.dt-sc-dark-bg .menu-icons-wrapper .search a, .dt-sc-dark-bg .menu-icons-wrapper .cart a, .cart-icon span, .menu-icons-wrapper.rounded-icons .search a span, .menu-icons-wrapper.rounded-icons .cart a span { color:#ffffff; }
	
	.menu-icons-wrapper .top-menu-search-container .search-form { background:#ffffff; }
	
	/*----*****---- << Footer >> ----*****----*/
	.footer-copyright .menu-links.with-splitter li a { border-right-color:rgba(0, 0, 0, 0.2); }
	#footer .tweet_list li { border-color:rgba(255, 255, 255, 0.15); }
	.dt-sc-dark-bg.footer-copyright .menu-links.with-splitter li a { border-right-color:rgba(255, 255, 255, 0.2); }
	.dt-sc-dark-bg.footer-copyright, #footer .dt-sc-dark-bg.footer-copyright a { color:#fff; }
	
	#footer .mailchimp-form input[type="submit"], #footer .dt-sc-sociable.filled li a, #footer .dt-sc-sociable.rounded-border.filled li a:hover { color:#ffffff; }
	#footer .mailchimp-form input[type="email"] { color:rgba(255, 255, 255, 0.5); }
	.footer-copyright { background:#202020; }
	#footer ul li i { margin-right:5px; }
	
 
 	.dt-sc-button { font-size: 12px; text-transform: uppercase; padding: 8px 15px; line-height: normal; display: inline-block; margin: 10px 0px 0px; cursor: pointer; outline: none; position: relative; z-index: 1; }

.dt-sc-button.filled { outline: 2px solid; border: 2px solid #fff; }

/*************************/
.woocommerce .cart .actions .button{ margin-top: 0;}
table { border-collapse:separate; border-spacing:0; margin-bottom:20px; width:100%; clear:both; border-top:1px solid rgba(0, 0, 0, 0.1);  }
th { color:#ffffff; border-bottom:1px solid rgba(0, 0, 0, 0.1); padding:15px; font-size:14px; line-height:normal; font-weight:600; text-transform:uppercase; text-align:center; }
td, tbody th { border-bottom:1px solid rgba(0, 0, 0, 0.1); font-size:14px; line-height:normal; text-align:center; padding:13px 15px; }
tbody tr:nth-child(2n) { background:rgba(0, 0, 0, 0.03); }
tbody td .dt-sc-button.small { padding:7px 14px; font-size:12px; margin:0px; }
th a, th a:hover { color:#ffffff; }
th a:hover, td a:hover { text-decoration:underline; }

.blog-entry .entry-body tbody th { background: none; font-weight: 400; }
.blog-entry .entry-body tbody th, .blog-entry .entry-body tbody th a { color: inherit; }

body:not(.vc_transform) tbody td, body:not(.vc_transform) tbody td a,
body:not(.vc_transform) .commententries tbody td, body:not(.vc_transform) .commententries tbody td a { font-weight: 400; color: inherit; }
.blog-entry .entry-body tbody th a { text-transform: none; }

body:not(.vc_transform) tbody td a:hover, body:not(.vc_transform) .commententries tbody td a:hover { text-decoration: underline}
	
html:not(.vc_transform ) .transparent-header .main-header-wrapper, 
html:not(.vc_transform ) .transparent-header .dt-sc-dark-bg .main-header-wrapper, 
html:not(.vc_transform ) .fullwidth-header.transparent-header .main-header-wrapper{ background:rgba(0, 0, 0, 0.9) !important; }

html:not(.vc_transform ) .home.header-on-slider #header-wrapper{ margin-bottom: 40px;}
html:not(.vc_transform ) .header-on-slider .sticky-wrapper{ position: relative;}
html:not(.vc_transform ) .is-sticky #logo a { margin: 16px 0;}
html:not(.vc_transform ) .is-sticky #site-description{ display: none;}
html:not(.vc_transform ) .is-sticky #logo .logo-title{ margin-top: 0;}

html:not(.vc_transform ) .header-on-slider .is-sticky .main-header-wrapper{ height: auto;}
html:not(.vc_transform ) .entry-body p.has-large-font-size, html:not(.vc_transform) .entry-body p.has-large-font-size + p {line-height: normal;}

html:not(.vc_transform) #primary{ padding-bottom: 80px;}

.size-full.alignright {
    clear: both;
    margin-top: 20px;
}