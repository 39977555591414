	/* Common Styles for the devices below 479px width */
    @media only screen and (max-width: 479px) {
		
		/* Base */		
		.commententries .nav-previous, .commententries .nav-next { width:100%; text-align:center; }
		
		/*----*****---- << PrettyPhoto >> --****--*/
		.pp_pic_holder { width: 90% !important; left: 5% !important; overflow: hidden; }		
		.pp_content { width: 98%!important; height: auto!important; }
		.pp_fade { width: 100%!important; height: 100%!important; }			
		a.pp_expand, a.pp_contract, .pp_hoverContainer, .pp_gallery { display: none!important; }			
		#pp_full_res img { width: 100%!important; height: auto!important; }
		.pp_details { width:100%!important; position:static!important; min-height:25px; }
		.pp_details a.pp_close{ right:25px; }
		div.pp_default .pp_content_container .pp_details { margin-top:15px; }
		
		/*----*****---- << Tooltip >> --****--*/
		#tiptip_content { font-size: 11px; }
		
		
		/* Widget */
		
		.widget_text ul.split-list { width:100%; margin:0; }
		
		.widget.woocommerce .woocommerce-product-search input[type="submit"] { float:right; min-height:50px; margin-top:10px; position:inherit; }
		.woo-type20 .widget.woocommerce .woocommerce-product-search input[type="submit"] { border-radius: 5px; }
		.widget.woocommerce .woocommerce-product-search input[type="search"] { padding-right: 10px; }
		.tagcloud a { margin:0 4px 10px 0; }
		.secondary-sidebar .type12 .widget ul li { font-size: 13px; }
		
		.textwidget .dt-sc-contact-info span { width:20px; margin:7px 6px 0 0; font-size:15px; }
		
				
		/* Layout */
		
		/*----*****---- << Header >> ----*****----*/
		.top-bar { line-height:35px; padding-bottom:10px; }
		.top-bar .dt-sc-vertical-separator, .top-bar .dt-sc-vertical-small-separator { display:none; }
		.top-bar .dt-sc-sociable { float:none; }
		.top-bar .text-with-icon { margin:0px 5px; }
		
		
		/*----*****---- << Breadcrump >> ----*****----*/
		.main-title-section h1 { font-size:23px; }	
	
		/*----*****---- << Header >> ----*****----*/
		.main-header #searchform { max-width: 100%; width: 100%; box-sizing: border-box; }
		
		.header-align-left .header-right .text-with-icon { line-height:40px; margin:0px 5px; }
		.header-align-left .header-right .dt-sc-vertical-small-separator { display:none; }
		
		.header-align-center .header-left .dt-sc-vertical-small-separator { display:none; }
		.header-align-center .header-left .text-with-icon { margin:0px 5px; }
		.main-header .menu-icons-wrapper .overlay-search #searchform { max-width:80%; }
		.left-header.standard-header .menu-wrapper { width:99%; margin:0 auto; }
		.site-with-border.overlay-header .menu-wrapper .overlay .overlay-close { right: 40px; top: 50px; }
		
		.fullwidth-menu-header.header-align-center .main-header #logo a { width:100%; }
		
		/*----*****---- << Footer >> ----*****----*/
		.footer-copyright .vc_col-sm-6 { width: 100% !important; margin-bottom:15px !important; text-align:center; padding:0; }
		.footer-copyright .dt-sc-sociable li { display: inline-block; float: none; }
		.footer-copyright .dt-sc-sociable.diamond-square-border li { margin:0 20px 0 0; }
		.alignright .dt-sc-sociable { width:100%; text-align:center; }
		
		.footer-copyright .menu-links { text-align:center; }
		.footer-copyright .menu-links li { margin:0 0 0 10px; }
		.footer-copyright .menu-links.with-splitter li { margin: 0; }
		.footer-copyright .menu-links li:first-child { margin: 0; }
		.footer-copyright .alignright img { display: inline-block; float: none; }
		
		
		/* Blog */
		
		.blog-entry .entry-format a { width: 60px; height: 60px; line-height: 45px; }
		.blog-entry.blog-medium-style .entry-format a { width: 55px; height: 55px; line-height: 45px; }
		.blog-entry .entry-format a:before { line-height: 45px; font-size: 22px; }
		.blog-entry.blog-medium-style .entry-format a:before { line-height:50px; }
		
		
 		/* latest News */
		.blog-entry.blog-thumb-style { margin-bottom:30px; }
		.blog-entry.blog-thumb-style .entry-thumb { width:100%; margin-right:0; margin-bottom:15px; padding:0; text-align:center; }
		.blog-entry.blog-thumb-style .entry-thumb img { margin:0 auto; width:100%; margin-bottom:5px; }
		.blog-entry.blog-thumb-style .entry-details { width:100%; }
		
 
  		/* Portfolio */		
		.page-template-tpl-fullwidth .dt-sc-portfolio-container .portfolio.no-space.dt-sc-one-fourth, .page-template-tpl-portfolio .dt-sc-portfolio-container .portfolio.no-space.dt-sc-one-fourth, .portfolio-fullwidth-container .dt-sc-one-third, .portfolio-fullwidth-container .portfolio.dt-sc-one-fourth { width:100%; }
		
		.portfolio.hover-modern-title .image-overlay-details { display:none; }
		.portfolio.hover-title-icons-overlay .image-overlay .links, .with-both-sidebar .portfolio.hover-title-icons-overlay .image-overlay .links, .page-with-sidebar .portfolio.hover-title-icons-overlay.dt-sc-one-third .image-overlay .links, .page-template-tpl-portfolio .portfolio.hover-title-icons-overlay.dt-sc-one-fourth .image-overlay .links, .page-with-sidebar.with-both-sidebar .portfolio.hover-title-icons-overlay.dt-sc-one-fourth .image-overlay .links { bottom:0; }
		.portfolio.hover-title-icons-overlay .image-overlay .links a { width:50px; height:50px; }
		.portfolio.hover-title-icons-overlay .image-overlay .links a span { line-height:50px; font-size:20px; }
 		.portfolio.hover-title-overlay .image-overlay-details { right:15px; }
		.portfolio.hover-icons-only .image-overlay .links { bottom:0; }
		
		.portfolio.type1 .image-overlay .links { text-align:center; }
		.portfolio.type1 .image-overlay .links a, .page-with-sidebar .portfolio.type1 .image-overlay .links a, .page-template-tpl-portfolio .portfolio.type1.dt-sc-one-third .image-overlay .links a, .page-template-tpl-portfolio .portfolio.type1.dt-sc-one-fourth .image-overlay .links a { margin-top:88px; margin-left:5px; margin-right:5px; }
		.page-template-tpl-portfolio .portfolio-fullwidth-container .portfolio.type1.dt-sc-one-third .image-overlay .links a, .page-template-tpl-portfolio .portfolio-fullwidth-container .portfolio.type1.dt-sc-one-fourth .image-overlay .links a { margin-top:150px; }
		.portfolio.hover-classic:hover .image-overlay .links a:first-child, .portfolio.hover-classic:hover .image-overlay .links a:last-child { bottom:0; }
		
		.portfolio.hover-girly .image-overlay .links, .page-with-sidebar .portfolio.hover-girly.dt-sc-one-fourth .image-overlay .links { top:0; }
		
		.post-nav-container.type3 .post-prev-link, .post-nav-container.type3 .post-next-link, .post-nav-container.type3 .post-archive-link-wrapper { width:100%; }
		.post-nav-container.type3 .post-archive-link-wrapper { margin:20px 0; }
		
		.dt-sc-portfolio-fullwidth-container.gallery-list .dt-sc-fixed-content #footer .wpb_text_column.wpb_content_element p { font-size: 9px; }
	
		.dt-sc-portfolio-fullwidth-container.gallery-list .post-nav-container.type3 .post-prev-link i { position: static; top: 0; }
		.dt-sc-portfolio-fullwidth-container.gallery-list .post-nav-container.type3 .post-next-link i { position: absolute; bottom: -42px; top: auto; right: 0; left: 0; margin: 0 auto; }
 		.dt-sc-portfolio-fullwidth-container.gallery-list .post-nav-container.type3 .post-prev-link,
		.dt-sc-portfolio-fullwidth-container.gallery-list .post-nav-container.type3 .post-next-link { padding: 0; text-align: center; }
		.dt-sc-portfolio-single-fullscreen-slider.fullpage-wrapper .slick-dotted.slick-slider { width: 95%; }
		.dt-portfolio-single-slider-wrapper .bx-controls-direction a { font-size:22px; width: 40px; height: 30px; line-height: 35px; }
		.dt-sc-portfolio-fullwidth-container.fixed-gallery .dt-sc-fixed-content .dt-portfolio-single-slider-wrapper .bx-controls-direction a.bx-prev i, .dt-sc-portfolio-fullwidth-container.fixed-gallery .dt-sc-fixed-content .dt-portfolio-single-slider-wrapper .bx-controls-direction a.bx-next i { line-height:30px; } 
		.project-details.type2 li { width:100%; }
		
		.portfolio.hover-with-gallery-list .image-overlay-container > ul, .portfolio.hover-with-gallery-thumb .image-overlay ul { display:none; }
		.portfolio.hover-with-gallery-thumb .image-overlay-details { width:100%; }
		.portfolio.hover-grayscale:hover .image-overlay-details { right:0; }
		
		.portfolio.hover-with-details .image-overlay-details { bottom:0; }
		.portfolio.hover-with-details .dt-sc-gallery-link { display:none; }
		
		.dt-sc-portfolio-fullwidth-container.gallery-list .dt-sc-fixed-content h2 { font-size: 28px; }
		.post-password-required .post-password-form label { width:100%; }
		#sb_instagram .sb_instagram_header { left: 0; top: 0; }
		
 		/* Infinite Scroll */
		.portfolio-modern-fullwidth { width: 290px; }
		.portfolio-modern-fullwidth h2 { font-size: 30px; line-height: 30px; }
		.portfolio-modern-fullwidth h3 { font-size: 16px; line-height: 30px; }
		.portfolio-modern-fullwidth h5 { font-size: 14px; }
		
				
		/* Contact */
		
		/** Attorney **/
		.attorney-contact-form { width:100%; }
		
		/** Wedding **/
		.rsvp-form { padding: 65px 35px; width: 100%; }
		.rsvp-form form:before, .rsvp-form form:after { content: none; }
		
		
		/* Custom Class */
		
		/*----*****---- << Event location page >> ----*****----*/
		.event-contact-info.wpb_column { width:100%; margin:20px 0px 0px 0px; }
		.event-contact-info:before { left:0px; width:100%; background-size:100%; }
		.event-contact-info .dt-sc-sociable li { margin-bottom:20px; }
	
		/*----*****---- << Custom Class >> ----*****----*/
		.rs_padding_left-override.vc_row .vc_column-inner, .rs_padding_left-override.wpb_column .vc_column-inner { padding-left:15px !important; }
		
		/*----*****---- << Attorney >> ----*****----*/
		.dt-sc-tabs-vertical-frame-container.rs_align_middle ul.dt-sc-tabs-vertical-frame, .dt-sc-tabs-vertical-frame-container.rs_align_middle .dt-sc-tabs-vertical-frame-content { margin:0; }
		.dt-sc-tabs-vertical-frame-container.rs_align_middle ul.dt-sc-tabs-vertical-frame > li > a.current, .dt-sc-tabs-vertical-frame-container.rs_align_middle ul.dt-sc-tabs-vertical-frame > li > a { border-right:1px solid rgba(0, 0, 0, 0.1); }
		
		
		
		/*--------------------------------------------------------------
			Woocommerce
		--------------------------------------------------------------*/
		
		/* Default */
		.woocommerce div.product .woocommerce-tabs ul.tabs:before { content: none; }
		.woocommerce div.product .woocommerce-tabs ul.tabs li { border-bottom: 1px solid; display: block; padding: 0; text-align: center; }
		.woocommerce div.product .woocommerce-tabs ul.tabs li, .woocommerce div.product .woocommerce-tabs ul.tabs li.active { border-color: #d3ced2; }
		.woocommerce div.product .woocommerce-tabs ul.tabs li.active a:after { bottom: -1px; }		
		
		.woocommerce div.product .woocommerce-tabs .panel { text-align: center; }
		.woocommerce #review_form #respond p { text-align: left; }
		
		.woocommerce .star-rating { top: 6px; }		
		
		
		.woocommerce.single-product div.product { float: left; }	   
		
		.woocommerce .woocommerce-product-rating .star-rating { margin-top: 2px; }
		
		.woocommerce table.shop_attributes tr:last-child th { border-bottom-color: rgba(0,0,0,.1); }	   
		
		
		.woocommerce.single-product div.images .thumbnails.slider .caroufredsel_wrapper { width: 100% !important; }		
		
		
		.woocommerce #reviews #comments ol.commentlist li img.avatar { display: block; float: none; margin: 0 auto; position: static; text-align: center; width: 60px; }
		.woocommerce #reviews #comments ol.commentlist li .comment-text { margin: 20px 0 40px; text-align: center; }
		.woocommerce #reviews #comments ol.commentlist li .comment-text .star-rating { display: block; float: none; font-size: 10px; margin: 0 auto 20px; }
		   
		
		.woocommerce form.checkout_coupon, .woocommerce form.login, .woocommerce form.register { margin: 0 0 2em; }
			
		.woocommerce form.checkout_coupon .button, .woocommerce form.login .button, .woocommerce form.register .button { display: block; float: none; margin: 10px auto; }
		.woocommerce form.checkout_coupon .button { margin-bottom: 0; }			   
		
		.woocommerce .woocommerce-error, .woocommerce .woocommerce-info, .woocommerce .woocommerce-message { float: right; width: 74%; }
		.woocommerce .woocommerce-error .button, .woocommerce .woocommerce-info .button, .woocommerce .woocommerce-message .button { float: none; display: block; text-align: center; }
			
		.woocommerce .shop_table.cart .button, .woocommerce-page .shop_table.cart .button, .woocommerce .shop_table.cart input.button, .woocommerce-page .shop_table.cart input.button { margin: 5px 0 0 ; }					
		.woocommerce table.cart td.actions .button.alt, .woocommerce-page table.cart td.actions .button.alt, .woocommerce #content table.cart td.actions .button.alt, .woocommerce-page #content table.cart td.actions .button.alt { margin-left: 0; }	
		
		
		.woocommerce .shop_table.woocommerce-checkout-review-order-table th:first-child, .woocommerce .shop_table.woocommerce-checkout-review-order-table td:first-child, 
		.woocommerce .shop_table.order_details th:first-child, .woocommerce .shop_table.order_details td:first-child, 
		.woocommerce .shop_table.customer_details th:first-child, .woocommerce .shop_table.customer_details td:first-child,
		.woocommerce table.shop_table th, .woocommerce-page table.shop_table th { width: 110px; }
		
		.woocommerce table.shop_table td, .woocommerce-page table.shop_table td, 
		.woocommerce table.wishlist_table tbody td, .woocommerce-page table.wishlist_table tbody td { border-bottom-width: 0; height: 51px; text-align: center; vertical-align: middle; width: 181px; }
		
		.woocommerce table.shop_table.cart tbody tr td.actions .coupon .input-text { width: 135px; }	
		.woocommerce table.wishlist_table td[colspan="6"], .woocommerce table.wishlist_table tbody td.wishlist-empty { width: 220px; }   
		
		.woocommerce-cart .cart-collaterals .cart_totals table th, .woocommerce-cart .cart-collaterals .cart_totals table td,
		.woocommerce table.shop_attributes th, .woocommerce table.shop_attributes td, .woocommerce-page table.shop_attributes th, .woocommerce-page table.shop_attributes td, 
		.woocommerce form.checkout_coupon, .woocommerce form.login, .woocommerce form.register { float: left; width: 100%; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -ms-box-sizing: border-box; -o-box-sizing: border-box; box-sizing: border-box; }	
		
		.woocommerce table.shop_attributes, .woocommerce-cart .cart-collaterals .cart_totals table { border-top-width: 0; }
			
			
		.woocommerce table.shop_attributes tr:first-child td, .woocommerce table.shop_attributes tr:first-child th, 
		.woocommerce-cart .cart-collaterals .cart_totals table tr:first-child td, .woocommerce-cart .cart-collaterals .cart_totals table tr:first-child th,
		.woocommerce-cart .cart-collaterals .cart_totals table tbody:first-child tr:first-child td, .woocommerce-cart .cart-collaterals .cart_totals table tbody:first-child tr:first-child th { border-top-width: 1px; }
				
		
		.woocommerce-cart .wc-proceed-to-checkout { float: none; }
		
		.woocommerce-checkout #payment ul.payment_methods li label { display: inline; }	
		
		
		.woocommerce table.my_account_orders .order-actions, .woocommerce-page table.my_account_orders .order-actions { text-align: center; }
		.woocommerce table.my_account_orders .order-actions .button, .woocommerce-page table.my_account_orders .order-actions .button { margin-top: 7px; }
		
		
		/* Type 1 - Fashion */
		
		.woo-type1 h1.product_title, .woo-type1 .related-products-container h2, .woo-type1 .upsell-products-container h2, .woo-type1 .cross-sells h2, .woo-type1 .cart_totals h2, .woo-type1 .shipping_calculator h2, .woo-type1.woocommerce-checkout h2, .woo-type1 div.product .woocommerce-tabs ul.tabs li a { font-size: 14px; line-height: 32px; }
	   
		.woo-type1 .related-products-container h2 span:before, .woo-type1 .related-products-container h2 span:after, .woo-type1 .upsell-products-container h2 span:before, .woo-type1 .upsell-products-container h2 span:after { content: none; }
	   
		.woo-type1 ul.products li.product .product-details .product-rating-wrapper { margin: 0 0 25px; }
		
		
		/* Type 2 - Jewel */
		.woo-type2 ul.products li.product .product-details .product-rating-wrapper { margin: 0 0 30px; }
		.woo-type2 div.product .woocommerce-tabs ul.tabs li { width: 90%; }
		   
		.woo-type2 div.product .woocommerce-tabs ul.tabs li,
		.woo-type2 div.product .woocommerce-tabs ul.tabs li:first-child { margin-left: auto; margin-right: auto; }
		   
		.woo-type2 div.product .woocommerce-tabs ul.tabs li:last-child { border: none; }
		   
		.woo-type2 div.product .woocommerce-tabs ul.tabs li a { display: block; margin: 0; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -ms-box-sizing: border-box; -o-box-sizing: border-box; box-sizing: border-box; }	
		   
		.woo-type2 div.product .woocommerce-tabs .panel { padding: 30px 15px; }	
		
		
		/* Type 3 - Business */
		.woo-type3 ul.products li.product .product-details .product-rating-wrapper .star-rating { margin: 0; }	
		
		
		/* Type 5 - Spa */
		.woo-type5.woocommerce table.shop_attributes, .woo-type5.woocommerce-cart .cart-collaterals .cart_totals table { border-top-width: 0; }	
			
		.woo-type5.woocommerce table.shop_attributes th, .woo-type5.woocommerce-cart .cart-collaterals .cart_totals table th, 
		.woo-type5.woocommerce table.shop_attributes tr:first-child td, .woo-type5.woocommerce table.shop_attributes tr:first-child th, 
		.woo-type5.woocommerce-cart .cart-collaterals .cart_totals table tr:first-child td, .woo-type5.woocommerce-cart .cart-collaterals .cart_totals table tr:first-child th,
		.woo-type5.woocommerce-cart .cart-collaterals .cart_totals table tbody:first-child tr:first-child td, .woo-type5.woocommerce-cart .cart-collaterals .cart_totals table tbody:first-child tr:first-child th { border-bottom-width: 0; border-top-width: 2px; }
		
		
		/* Type 16 - Photography */
		.woo-type16.woocommerce table.shop_attributes, .woo-type16.woocommerce-cart .cart-collaterals .cart_totals table { border-top-width: 0; }
		
		.woo-type16.woocommerce table.shop_attributes th, .woo-type16.woocommerce-cart .cart-collaterals .cart_totals table th, 
		.woo-type16.woocommerce table.shop_attributes tr:first-child td, .woo-type16.woocommerce table.shop_attributes tr:first-child th, 
		.woo-type16.woocommerce-cart .cart-collaterals .cart_totals table tr:first-child td, .woo-type16.woocommerce-cart .cart-collaterals .cart_totals table tr:first-child th,
		.woo-type16.woocommerce-cart .cart-collaterals .cart_totals table tbody:first-child tr:first-child td, .woo-type16.woocommerce-cart .cart-collaterals .cart_totals table tbody:first-child tr:first-child th { border-bottom-width: 0; border-top-width: 3px; }
		
		
		/* Type 18 - Event */
		.woo-type18 ul.products li.product .product-details .product-rating-wrapper .star-rating,
		.woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-fourth .product-details .product-rating-wrapper .star-rating, 
		.woo-type18 .page-with-sidebar .woocommerce.columns-4 ul.products li.product .dt-sc-one-fourth .product-details .product-rating-wrapper .star-rating { font-size: 9px; margin: 5px 0 0; }	
		
		
		/* Shortcodes */
		
		/* Blockquote */
		blockquote.type2 cite:before { width: calc(50% + 22px); }
		
		
		/* Carousel */
		.dt-sc-images-wrapper { margin-top: 40px; }
		
		
		/** Contact Info **/
		.dt-sc-contact-info.type2 { padding-left: 85px; }
		.dt-sc-contact-info.type2 span { width: 65px; }
		
		.dt-sc-contact-info.type7 { padding-left: 90px; }
		.dt-sc-contact-info.type7 span { line-height: 50px; width: 50px; }
		.dt-sc-contact-info.type7 span:after { height: 50px; width: 50px; }	
		
		.dt-sc-contact-info.type4 { border-right-width: 1px; }
		
		
		/** Fancy Boxes **/
		.dt-sc-error-box:before, .dt-sc-warning-box:before, .dt-sc-success-box:before, .dt-sc-info-box:before { top: 17px; }
		
		
		/** Hexagons Images **/
		.dt-sc-content-with-hexagon-shape { padding-left:30px; padding-right:30px; }
		.dt-sc-content-with-hexagon-shape .hexagon-border { width:82%; }
		.dt-sc-single-hexagon.hexagon-left-margin, .dt-sc-single-hexagon { margin-left:17%; }
		
		
		/** Icon Box **/		
		.dt-sc-icon-box.type5 { padding-left: 95px; }
		.dt-sc-icon-box.type5.alignright { padding-right: 95px; }
		
		.dt-sc-icon-box.type5.rounded { padding-left: 110px; }
		.dt-sc-icon-box.type5.rounded.alignright { padding-left: 0; padding-right: 110px; }		
		
		.dt-sc-icon-box.type6 .icon-wrapper { height: 75px; margin-right: 10px; width: 75px; }
		.dt-sc-icon-box.type6 .icon-wrapper span { font-size: 32px; line-height: 55px; }
		.dt-sc-icon-box.type6 .icon-content h4 { font-size: 15px; margin-top: 100px; }
		
		.dt-sc-icon-box.type13 { float: right; width: 96%; }
		
		.dt-sc-icon-box.type14 { min-height: 237px; }
		
		
		/** Image Caption **/
		.dt-sc-image-caption .dt-sc-image-wrapper .icon-wrapper::before { border-bottom-width: 100px; border-left-width: 100px; }
		.dt-sc-image-caption .dt-sc-image-wrapper .icon-wrapper::after { left: 7px; }
		.dt-sc-image-caption .dt-sc-image-wrapper .icon-wrapper span { font-size: 30px; margin: 0 15px 15px 0; }
				
		.dt-sc-image-caption.type5 h3 { font-size: 22px; }		
				
		.dt-sc-image-caption.type6 { min-height: 190px; padding-bottom: 0; }
		
		.dt-sc-image-caption.type6 .dt-sc-image-wrapper { padding: 35px 0 0; }
		.dt-sc-image-caption.type6 .dt-sc-image-wrapper img { max-height: 190px; }
		
		.dt-sc-image-caption.type6 .dt-sc-image-content { margin-top: 20px; }
		.dt-sc-image-caption.type6 .dt-sc-image-content p { display: none; }
		
		.dt-sc-image-caption.type6:hover:before { top: -14px; }
		.dt-sc-image-caption.type6:hover .dt-sc-image-wrapper img { top: -15px; }
		
		.dt-sc-image-caption.type7 .dt-sc-image-wrapper, .dt-sc-image-caption.type7 .dt-sc-image-content { margin-right:0; width:100%; }
		.dt-sc-image-caption.type7 .dt-sc-image-wrapper, .dt-sc-image-caption.type7.left-content .dt-sc-image-wrapper, .dt-sc-image-caption.type7.bottom-content .dt-sc-image-content { margin: 0 0 30px; }		
		.dt-sc-image-caption.type7.bottom-content .dt-sc-image-content { margin-top: 40px; }
		.dt-sc-image-caption.type9 .dt-sc-image-overlay p { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
		
		
		/** Newsletter **/
		.dt-sc-newsletter-section .dt-sc-subscribe-frm input[type="submit"] { padding: 18px 10px 15px; }
	
		.dt-sc-newsletter-section.type1 h2 { text-align: center; }
		.dt-sc-newsletter-section.type1 h2:before, .dt-sc-newsletter-section.type1 h2:after, .dt-sc-newsletter-section.type1 form:before, .dt-sc-newsletter-section.type1 form:after { width: 250%; }	
		.dt-sc-newsletter-section.type1 form:before { width: 250%; transform: none; -moz-transform: none; -webkit-transform: none; -ms-transform: none; left: -100%; }
		.dt-sc-newsletter-section.type1 .dt-sc-subscribe-frm { text-align: center; }
		.dt-sc-newsletter-section.type1 .dt-sc-subscribe-frm, .dt-sc-newsletter-section.type1 .dt-sc-subscribe-frm input[type="email"], .dt-sc-newsletter-section.type1 .dt-sc-subscribe-frm input[type="text"] { width: 100%; float: none; display: inline-block; }
		.dt-sc-newsletter-section.type1 .dt-sc-subscribe-frm input[type="submit"] { float: none; display: inline-block; padding: 18px 20px 15px; }
		
		.dt-sc-newsletter-section.type2 .dt-sc-subscribe-frm input[type="submit"],
		.dt-sc-newsletter-section.type3 .dt-sc-subscribe-frm input[type="submit"],
		.dt-sc-newsletter-section.type6 .dt-sc-subscribe-frm input[type="submit"] { margin-top: 10px; }
		
		
		/** Progress Bars **/
		.dt-sc-bar-text { margin: -25px 0 0; }
		.dt-sc-progress .dt-sc-bar-text > span { margin: 10px 0 0; text-align: right; width: 100%; }
		.dt-sc-progress-wrapper .dt-sc-bar-text > span { margin: 0; width: auto; }
		
		.dt-sc-progress-wrapper .dt-sc-bar-title, .dt-sc-progress-wrapper .dt-sc-progress { width: 100%; }
		.dt-sc-progress-wrapper .dt-sc-bar-title { padding: 0 0 20px; }
		
		
		/** Tabs **/
		ul.dt-sc-tabs-horizontal, ul.dt-sc-tabs-horizontal-frame { border: none; }
		ul.dt-sc-tabs-horizontal li, ul.dt-sc-tabs-horizontal-frame li { border-bottom: 1px solid rgba(0,0,0,0.1); text-align: center; width: 100%; }
		ul.dt-sc-tabs-horizontal > li > a, ul.dt-sc-tabs-horizontal-frame > li > a { display: inline-block; float: none; }
		
		ul.dt-sc-tabs-horizontal-frame li { margin-bottom: 10px; }
		ul.dt-sc-tabs-horizontal-frame li:last-child { margin-bottom: 0; }
		
		.dt-sc-tabs-horizontal-frame-container.type3 ul.dt-sc-tabs-horizontal-frame > li { margin: 0; }
		.dt-sc-tabs-horizontal-frame-container.type3 ul.dt-sc-tabs-horizontal-frame > li > a { width: 98%; }
		
		.dt-sc-tabs-vertical-frame-container.type3 ul.dt-sc-tabs-vertical-frame > li { width: 95%; }
				
		.dt-sc-tabs-horizontal-frame-container.type5 ul.dt-sc-tabs-horizontal-frame > li > a { font-size: 16px; }
		.dt-sc-tabs-horizontal-frame-container.type5 .dt-sc-tabs-horizontal-frame-content { padding: 30px 30px 0; }
		
		.type7 ul.dt-sc-tabs-horizontal { padding-left:0px; }
		.type7 ul.dt-sc-tabs-horizontal > li { float:left; width:100%; }
		.type7 .dt-sc-tabs-horizontal:before, .type7 .dt-sc-tabs-horizontal > li:after { content:none; }
		
		ul.dt-sc-tabs-vertical-frame > li > a { font-size: 13px; }
		
		.dt-sc-tabs-vertical-frame-container.type2 ul.dt-sc-tabs-vertical-frame, 
		.dt-sc-tabs-vertical-frame-container.type3 ul.dt-sc-tabs-vertical-frame, 
		.dt-sc-tabs-vertical-frame-container.type4 ul.dt-sc-tabs-vertical-frame, 
		.dt-sc-tabs-vertical-frame-container.type2 .dt-sc-tabs-vertical-frame-content,
		.dt-sc-tabs-vertical-frame-container.type3 .dt-sc-tabs-vertical-frame-content,
		.dt-sc-tabs-vertical-frame-container.type4 .dt-sc-tabs-vertical-frame-content { width: 100%; }
		
		.dt-sc-tabs-vertical-frame-container.type2 .dt-sc-tabs-vertical-frame-content { padding: 30px 0 0 0; }
		
		.dt-sc-tabs-vertical-frame-container.type3 .dt-sc-tabs-vertical-frame-content,
		.dt-sc-tabs-vertical-frame-container.type4 .dt-sc-tabs-vertical-frame-content { padding: 50px 0 0 0; }
		
		
		.rs_min_height_team_col .wpb_column { min-height: 440px !important; }
		
		.dt-sc-special-testimonial-container .extend-bg-fullwidth-left.vc_col-sm-6 { padding-left:20px; padding-right:20px; }
		.dt-sc-special-testimonial-container .vc_col-sm-6 { padding-left:15px; padding-right:15px; }
		
	
		/*----*****---- << Testimonial >> ----*****----*/
		.dt-sc-testimonial.type6 .dt-sc-testimonial-author::before { height: 220px; width: 220px; }
		.dt-sc-testimonial.type6 .dt-sc-testimonial-author { text-align: center; }
		.dt-sc-testimonial.type6 .dt-sc-testimonial-author img { display: block; float: none; margin: 0 auto; }
		
		.dt-sc-testimonial.type8 .dt-sc-testimonial-quote { padding: 0; }
		
		
		/** Timeline **/
		.dt-sc-hr-timeline-section.type2 .dt-sc-hr-timeline.bottom .dt-sc-hr-timeline-thumb { top: -4px; }
		.dt-sc-hr-timeline-section.type2 .dt-sc-hr-timeline.bottom .dt-sc-hr-timeline-content p::after { bottom: calc(50% + 10px); }
		
		.dt-sc-timeline-section { display: block; float: none; margin-left: auto; margin-right: auto; width: 95%; }
		.dt-sc-timeline-section.type5 { width: 90%; }
		
		
		/** Headings - Titles **/
		.dt-sc-title.with-two-color-bg h2 { font-size: 22px; }
		.dt-sc-ribbon-title { font-size: 20px; height: auto; line-height: 32px; padding: 0 0 30px; }
		
		
		/** Toggles **/		
		.dt-sc-toggle-frame h5.dt-sc-toggle-accordion::before, .dt-sc-toggle-frame h5.dt-sc-toggle::before { left: 20px; }
		.dt-sc-toggle-frame h5.dt-sc-toggle-accordion::after, .dt-sc-toggle-frame h5.dt-sc-toggle::after { left: 13px; }
		
		.dt-sc-toggle-frame h5.dt-sc-toggle-accordion a, .dt-sc-toggle-frame h5.dt-sc-toggle a { padding: 12px 0 12px 50px; }
		.type2 .dt-sc-toggle-frame h5.dt-sc-toggle-accordion a, .type2 .dt-sc-toggle-frame h5.dt-sc-toggle a { padding-top: 15px; padding-bottom: 15px; }
		
		.dt-sc-toggle-frame h5.dt-sc-toggle-accordion a::before, .dt-sc-toggle-frame h5.dt-sc-toggle a::before { width: 40px; }
		
		.type2 .dt-sc-toggle-frame h5.dt-sc-toggle-accordion, .type2 .dt-sc-toggle-frame h5.dt-sc-toggle { font-size: 13px; }
		.type2 .dt-sc-toggle-frame h5.dt-sc-toggle-accordion::before, .type2 .dt-sc-toggle-frame h5.dt-sc-toggle::before { height: 20px; right: 25px; }
		.type2 .dt-sc-toggle-frame h5.dt-sc-toggle-accordion::after, .type2 .dt-sc-toggle-frame h5.dt-sc-toggle::after { right: 15px; width: 20px; }
		
		.type2 h5.dt-sc-toggle-accordion, .type2 h5.dt-sc-toggle { font-size: 16px; }
		
		.type2 h5.dt-sc-toggle-accordion::before, .type2 h5.dt-sc-toggle::before { height: 20px; left: 8px; }
		.type2.alignright h5.dt-sc-toggle-accordion::before, .type2.alignright h5.dt-sc-toggle::before { left: auto; right: 8px; }
		
		.type2 h5.dt-sc-toggle-accordion::after, .type2 h5.dt-sc-toggle::after { width: 20px; }
		.type2 > h5.dt-sc-toggle-accordion a, .type2 > h5.dt-sc-toggle a, .type2 > .dt-sc-toggle-content { padding-left: 35px; }
		.type2.alignright > h5.dt-sc-toggle-accordion a, .type2.alignright > h5.dt-sc-toggle a, .type2.alignright > .dt-sc-toggle-content { padding-right: 35px; }	
		   
		.type3 .dt-sc-toggle-frame h5.dt-sc-toggle-accordion, .type3 .dt-sc-toggle-frame h5.dt-sc-toggle { font-size: 14px; }
		.type2 .dt-sc-toggle-frame h5.dt-sc-toggle-accordion a, .type2 .dt-sc-toggle-frame h5.dt-sc-toggle a { padding-right: 35px; }
		
		
		/*----*****---- << Content Shortcodes >> ----*****----*/
		.dt-sc-triangle-img-crop { height: 275px; width: 275px; }
		.dt-sc-triangle-img-crop a { height: 250px; margin-top: 20px; margin-left: 20px; width: 250px; }
		
		.dt-sc-triangle-content { margin-top: -240px; padding: 50px 0 0 0; left: 44px; width: 220px; }
		.dt-sc-triangle-wrapper.alter .dt-sc-triangle-content { padding: 105px 0 0; left: 22px; }
		
		.dt-sc-triangle-content:before { border-left-width: 110px; border-right-width: 110px; border-top-width: 180px; }
		.dt-sc-triangle-wrapper.alter .dt-sc-triangle-content:before { border-top: 0; border-bottom-width: 180px; }
		
		.dt-sc-triangle-wrapper.alter .dt-sc-triangle-img { left: 178px; top: -125px; }
		
		.dt-sc-triangle-content h4 { font-size: 12px; }
		.dt-sc-triangle-content h5 { font-size: 10px; }	
		
		
		/* Tribe Events */
		.type1.tribe_events .event-image-wrapper .event-datetime, .type1.tribe_events .event-image-wrapper .event-venue { padding:0 10px; margin-bottom:0; }
		.type1.tribe_events .event-image-wrapper .date-wrapper { position:inherit; left:0; top:0; }
		.type1.tribe_events .event-image-wrapper .event-datetime > span { left:0; top:106px; }
		
  	}


	/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */
	@media only screen and (min-width: 320px) and (max-width: 479px) {
		
		/* Base */		
		
		/*----*****---- << Miscellaneous >> --****--*/
		th, td, tbody th { font-size: 10px; }
		th { padding: 10px 6px; }
		td, tbody th { padding: 13px 6px; }
		
		
 		/* Grid */		
		
		/*----*****---- << Container >> ----*****----*/	
		.container, .layout-boxed .vc_row .dt-sc-stretch-row-content { width:290px; }
		.container.fill-container, .page-template-tpl-fullwidth .section-wrapper.fullwidth-section .container.fill-stretch-row { width:320px; }
		.layout-boxed .wrapper { width:100%; }
		#primary.with-left-sidebar, #primary.with-right-sidebar, #primary.with-both-sidebar { width:290px; }

		.layout-boxed .vc_row.vc_row-no-padding { width:100% !important; left:0px !important; }
		
		
 		/* Widget */
		
		.secondary-sidebar .flickr-widget div { width:auto; }
		.secondary-sidebar .type8 .widget { padding:0 17px 15px; }
		.secondary-sidebar .type5 .widget ul li { font-size:16px; }
		
		
		/* Layout */
		
		/*----*****---- << footer >> ----*****----*/
		.footer-copyright .menu-links.with-splitter li a { padding:0 7px; }
		
		.page-template-tpl-portfolio .portfolio-fullwidth-container .portfolio.type1.dt-sc-one-third .image-overlay .links a, .page-template-tpl-portfolio .portfolio-fullwidth-container .portfolio.type1.dt-sc-one-fourth .image-overlay .links a { margin-top:125px; }
		.portfolio.hover-icons-only .image-overlay-details { display: none; }
		.portfolio.hover-icons-only .image-overlay .links { height:50px; }
		.dt-sc-bordered-site { border-width:8px; }
		
		.post-nav-container .post-prev-link, .post-nav-container .post-next-link { display:block; }
		.post-nav-container .post-archive-link-wrapper { margin:20px 0; }
		.post-nav-container.type2 .post-prev-link, .post-nav-container.type2 .post-next-link { display:inline-block; }
		.project-details.type2 li { padding:0 0 20px 0; }
		
 		
		/* Custom Class */
		
		/*----*****---- << Hosting >> ----*****----*/
		.hosting-table th { text-transform:capitalize; font-size:12px; padding:13px 6px; }
		.hosting-table tr td, .hosting-table tbody tr:nth-child(2n+1) td { font-size:12px; padding:22px 8px; }
		
		/*----*****---- << Miscellaneous >> ----*****----*/
		.rs_fontsize_small { font-size: 40px !important; }		
						
		.rs_padding_override.vc_row, 
		.rs_padding_override.wpb_column .vc_column-inner { padding-left: 30px !important; padding-right: 30px !important; }
		
		/*----*****---- << Business >> ----*****----*/	
		.business-contact-social li { margin: 0 15px; }	
	
		
		/*--------------------------------------------------------------
			Woocommerce
		--------------------------------------------------------------*/
		
		/* Default */
		.woocommerce.single-product div.product .images .thumbnails .yith_magnifier_gallery li,
		.woocommerce.single-product .page-with-sidebar div.product .images .thumbnails .yith_magnifier_gallery li,
		.woocommerce.single-product .page-with-sidebar.with-both-sidebar div.product .images .thumbnails .yith_magnifier_gallery li { width: 90px !important; }	
		
 		
		/* Type 1 - Fashion */
		.woo-type1.woocommerce.single-product .featured-tag { right: 0; }
		
		
		/* Type 13 - Architecture */
		.woo-type13.woocommerce table.shop_attributes, .woo-type13.woocommerce-cart .cart-collaterals .cart_totals table { border-top-width: 0; }	

		.woo-type13.woocommerce table.shop_attributes th, .woo-type13.woocommerce-cart .cart-collaterals .cart_totals table th, 
		.woo-type13.woocommerce table.shop_attributes tr:first-child td, .woo-type13.woocommerce table.shop_attributes tr:first-child th, 
		.woo-type13.woocommerce-cart .cart-collaterals .cart_totals table tr:first-child td, .woo-type13.woocommerce-cart .cart-collaterals .cart_totals table tr:first-child th,
		.woo-type13.woocommerce-cart .cart-collaterals .cart_totals table tbody:first-child tr:first-child td, .woo-type13.woocommerce-cart .cart-collaterals .cart_totals table tbody:first-child tr:first-child th { border-bottom-width: 0; border-top-width: 2px; }	   
	
	 
		.woo-type13 ul.products li.product .product-details .product-rating-wrapper .star-rating { margin: 3px 0 0; }
		
		
		/* Type 18 - Event */
		.woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-fourth .product-wrapper, .woo-type18 .page-with-sidebar .woocommerce.columns-4 ul.products li.product .dt-sc-one-fourth .product-wrapper, .woo-type18 .with-both-sidebar ul.products li.product .dt-sc-one-third .product-wrapper, .woo-type18 .with-both-sidebar .woocommerce.columns-3 ul.products li.product .dt-sc-one-third .product-wrapper { padding: 20px 25px 0; }
		
		.woo-type18 ul.products li.product .dt-sc-one-fourth .product-thumb:after, .woo-type18 ul.products li.product .dt-sc-one-fourth .product-thumb:before, 
		.woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-fourth .product-thumb:after, .woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-fourth .product-thumb:before,
		.woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-fourth .product-thumb:after, .woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-fourth .product-thumb:before,
		
		.woo-type18 ul.products li.product .dt-sc-one-third .product-thumb:after, .woo-type18 ul.products li.product .dt-sc-one-third .product-thumb:before, 
		.woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-third .product-thumb:after, .woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-third .product-thumb:before,
		.woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-third .product-thumb:after, .woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-third .product-thumb:before,
		
		.woo-type18 ul.products li.product .dt-sc-one-half .product-thumb:after, .woo-type18 ul.products li.product .dt-sc-one-half .product-thumb:before, 
		.woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-half .product-thumb:after, .woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-half .product-thumb:before,
		.woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-half .product-thumb:after, .woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-half .product-thumb:before { top: -58px; }
		
				
		.woo-type18 ul.products li.product .dt-sc-one-fourth .product-thumb .image:before, .woo-type18 ul.products li.product .dt-sc-one-fourth .product-thumb .image:after,
		.woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-fourth .product-thumb .image:before, .woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-fourth .product-thumb .image:after,
		.woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-fourth .product-thumb .image:before, .woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-fourth .product-thumb .image:after, 	
			
		.woo-type18 ul.products li.product .dt-sc-one-third .product-thumb .image:before, .woo-type18 ul.products li.product .dt-sc-one-third .product-thumb .image:after,
		.woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-third .product-thumb .image:before, .woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-third .product-thumb .image:after,
		.woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-third .product-thumb .image:before, .woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-third .product-thumb .image:after,
		
		.woo-type18 ul.products li.product .dt-sc-one-half .product-thumb .image:before, .woo-type18 ul.products li.product .dt-sc-one-half .product-thumb .image:after,
		.woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-half .product-thumb .image:before, .woo-type18 .page-with-sidebar ul.products li.product .dt-sc-one-half .product-thumb .image:after,
		.woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-half .product-thumb .image:before, .woo-type18 .page-with-sidebar.with-both-sidebar ul.products li.product .dt-sc-one-half .product-thumb .image:after { bottom: -58px; }
		
		
		/* Tribe Events */
		.tribe-events-calendar th { font-size:13px; text-transform:capitalize; }
		.tribe-events-calendar td div[id*="tribe-events-daynum-"], .tribe-events-calendar td div[id*="tribe-events-daynum-"] a { font-size:14px; }
		.tribe-events-list .tribe-events-photo-event-wrap .tribe-events-event-image { padding-top:0px; }
		
		
		.type2.tribe_events .dt-sc-one-fourth.column.right-calc > a { font-size:16px; }
		
		.type3.tribe_events .event-date { font-size:20px; }
		.type3.tribe_events .event-date span { font-size:30px; }
		.type3.tribe_events h4 { font-size:20px; text-transform:capitalize; }
		
		.single-tribe_events .type3.tribe_events .tribe-events-sub-nav li a span { display:none; }
		
		.type4.tribe_events .vc_col-sm-6.wp_column { width:100%; }
		
		/**** Events Detail page ****/
		.event-meta-tab ul.dt-sc-tabs-horizontal-frame > li > a { text-transform:capitalize; padding:10px; }
		
		
		/* Type 20 - Yoga */
		.woo-type20 ul.products li.product .product-details .product-rating-wrapper .star-rating { margin: 4px 0 0; }
		
		
		/* Type 21 - Styleshop */
		.woo-type21.woocommerce.single-product div.product .woocommerce-tabs ul.tabs li { width: 100%; }
		.woo-type21.woocommerce.single-product div.product .woocommerce-tabs ul.tabs li a { display: block; }
		
		
		/* Shortcodes */
		
		/* Buttons */
		.dt-sc-colored-big-buttons { font-size: 16px; }
		
		
 		/** Event **/
		.dt-sc-content-with-hexagon-shape .hexagon-border { width:87%; }
		
		
		/** Event Shortcodes **/
		.dt-sc-hexagons li:first-child, .dt-sc-hexagons li:nth-child(2), .dt-sc-hexagons li:nth-child(3), .dt-sc-hexagons li:nth-child(4), .dt-sc-hexagons li:nth-child(5), .dt-sc-hexagons li:last-child { left:19%; }
		.dt-sc-hexagon-image-overlay h3 { font-size:15px; }
		.dt-sc-hexagon-image-overlay h2 { font-size:18px; }
		.dt-sc-hexagon-image-overlay h2::before { height:31px; }
		.dt-sc-hexagon-image-overlay { height:103px; }
		
		
		/** Newsletter **/		
		.page-with-sidebar .vc_row-no-padding .dt-sc-newsletter-section.type7 { max-width:290px; }
		
		
		/** Testimonial **/	
		.dt-sc-testimonial-images li { width:49%; min-height:117px; }
		.dt-sc-testimonial-images { max-width:237px; }
		
		
		/** Headings - Titles **/
		.dt-sc-title.with-two-border h2 { font-size: 20px; }
		.dt-sc-title.with-right-border-decor .dt-sc-button.large { font-size: 15px; }
		
		.dt-sc-title.with-boxed h2 { font-size: 24px; height: 100px; }
		.dt-sc-title.with-boxed h2 span { font-size: 40px; }		
		
		.dt-sc-triangle-title { height: 450px; }
		.dt-sc-triangle-title:before { height: 250px; top: 100px; width: 250px; }
		.dt-sc-triangle-title:after { height: 230px; top: 110px; width: 230px; }
		.dt-sc-triangle-title h2 { margin: 170px 0 0; }	
		
	}
	
	
	@media screen and (max-width: 479px) and (-webkit-min-device-pixel-ratio:0) { 
	
			/* Safari only override */
			::i-block-chrome, .woo-type9 ul.products li.product:hover .product-thumb a.add_to_cart_button, .woo-type9 ul.products li.product:hover .product-thumb a.button.product_type_simple, .woo-type9 ul.products li.product:hover .product-thumb a.button.product_type_variable, .woo-type9 ul.products li.product:hover .product-thumb a.added_to_cart.wc-forward { left: 75px; }
			
			::i-block-chrome, .woo-type9 ul.products li.product:hover .product-thumb a.add_to_wishlist, .woo-type9 ul.products li.product:hover .product-thumb .yith-wcwl-wishlistaddedbrowse a, .woo-type9 ul.products li.product:hover .product-thumb .yith-wcwl-wishlistexistsbrowse a { right: 75px; }
	
	}