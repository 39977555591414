.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.menu-icons-wrapper .search a:hover,
.menu-icons-wrapper .cart a:hover,
.portfolio .image-overlay .links a:hover,
#footer a:hover,
#footer .footer-copyright .menu-links li a:hover,
#footer .footer-copyright .copyright-left a:hover,
.top-bar a:hover,
#footer .dt-sc-dark-bg .recent-posts-widget li .entry-meta a:hover,
#footer .dt-sc-dark-bg .entry-title h4 a:hover,
#footer .dt-sc-dark-bg a:hover,
.dt-sc-sociable.diamond-square-border li:hover a,
.available-domains li span,
.blog-entry.entry-date-left .entry-date a:hover,
.dt-sc-menu .image-overlay .price,
.dt-sc-menu .image-overlay h6 a:hover,
.dt-sc-menu .menu-categories a:hover,
.dt-sc-title.with-sub-title h3,
.blog-entry .entry-meta a:hover,
.dt-sc-image-with-caption h3 a,
.blog-entry.entry-date-author-left .entry-date-author .comments:hover,
.blog-entry.entry-date-author-left .entry-date-author .comments:hover i,
.dt-sc-model-sorting a:hover,
.dt-sc-model-sorting a.active-sort,
.left-header-footer .dt-sc-sociable.filled li a,
.entry-meta-data p a:hover,
.blog-entry.entry-date-author-left .entry-date-author .entry-author a:hover,
.blog-entry.entry-date-author-left .entry-date-author .comments a:hover,
.widget #wp-calendar td a:hover,
.dt-sc-dark-bg .widget #wp-calendar td a:hover,
.project-details li a:hover,
.portfolio-categories a:hover,
.portfolio-tags a:hover,
.dt-portfolio-single-slider-wrapper #bx-pager a.active:hover:before,
.dt-portfolio-single-slider-wrapper #bx-pager a,
.breadcrumb a:hover,
.secondary-sidebar .widget ul li>a:hover,
.dt-sc-practices-list li:before,
.secondary-sidebar .type15 .widget.widget_recent_reviews ul li .reviewer,
.secondary-sidebar .type15 .widget.widget_top_rated_products ul li .amount.amount,
.dt-sc-team.type2 .dt-sc-team-social.rounded-border li a:hover,
.dt-sc-team.type2 .dt-sc-team-social.rounded-square li a:hover,
.dt-sc-team.type2 .dt-sc-team-social.square-border li a:hover,
.dt-sc-team.type2 .dt-sc-team-social.hexagon-border li a:hover,
.dt-sc-team.type2 .dt-sc-team-social.diamond-square-border li a:hover,
.dt-sc-team.hide-social-role-show-on-hover .dt-sc-team-social.rounded-square li a,
.dt-sc-toggle-frame-set>.dt-sc-toggle-accordion.active>a,
.dt-sc-toggle-group-set .dt-sc-toggle.active>a,
#footer .widget #bp-login-widget-form span.bp-login-widget-register-link>a,
#footer .dt-sc-dark-bg .widget #bp-login-widget-form span.bp-login-widget-register-link>a,
.dt-sc-portfolio-sorting a.active-sort,
.dt-sc-portfolio-sorting a:hover,
.dt-sc-fixed-content .dt-portfolio-single-slider-wrapper .bx-controls-direction a:hover,
.dt-sc-portfolio-details .post-nav-container .post-archive-link-wrapper a:hover,
.post-nav-container.type3 a:hover,
.portfolio.hover-presentation .image-overlay .links a,
.section9 .color-red,
.section9 .color-red,
.section21 .stats-number,
.section5 .stats-block .stats-number,
.section5 .stats-block .stats-number,
.section7 h2,
.section35 .dt-sc-testimonial.type3 .dt-sc-testimonial-author cite,
#footer .dt-sc-sociable.rounded-border li a:hover {
  color: #b2dcaa;
}

#main-menu ul.menu li a:hover,
#main-menu>ul.menu>li.current_page_item>a,
#main-menu>ul.menu>li.current_page_ancestor>a,
#main-menu>ul.menu>li.current-menu-item>a,
#main-menu ul.menu>li.current-menu-ancestor>a,
#main-menu ul.menu li.menu-item-simple-parent ul>li.current_page_item>a,
#main-menu ul.menu li.menu-item-simple-parent ul>li.current_page_ancestor>a,
#main-menu ul.menu li.menu-item-simple-parent ul>li.current-menu-item>a,
#main-menu ul.menu li.menu-item-simple-parent ul>li.current-menu-ancestor>a,
.left-header .menu-active-highlight-grey #main-menu>ul.menu>li.current_page_item>a,
.left-header .menu-active-highlight-grey #main-menu>ul.menu>li.current_page_ancestor>a,
.left-header .menu-active-highlight-grey #main-menu>ul.menu>li.current-menu-item>a,
.left-header .menu-active-highlight-grey #main-menu>ul.menu>li.current-menu-ancestor>a,
.overlay-header .overlay ul.menu-links li a:hover,
.left-header.left-header-creative .dt-sc-dark-bg #main-menu ul li.menu-item-simple-parent ul li a:hover {
  color: #b2dcaa;
}

#main-menu ul.menu li.menu-item-simple-parent ul li a:hover,
#main-menu ul.menu li.menu-item-megamenu-parent:hover>a,
#main-menu ul.menu>li.menu-item-simple-parent:hover>a,
#main-menu ul.menu li.menu-item-simple-parent ul li:hover>a {
  color: #b2dcaa;
}

#main-menu .megamenu-child-container ul.sub-menu>li>ul li a:hover {
  color: #b2dcaa;
}

#main-menu .megamenu-child-container ul.sub-menu>li.current_page_item>a,
#main-menu .megamenu-child-container ul.sub-menu>li.current_page_ancestor>a,
#main-menu .megamenu-child-container ul.sub-menu>li.current-menu-item>a,
#main-menu .megamenu-child-container ul.sub-menu>li.current-menu-ancestor>a,
#main-menu .megamenu-child-container ul.sub-menu>li.current_page_item>span,
#main-menu .megamenu-child-container ul.sub-menu>li.current_page_ancestor>span,
#main-menu .megamenu-child-container ul.sub-menu>li.current-menu-item>span,
#main-menu .megamenu-child-container ul.sub-menu>li.current-menu-ancestor>span {
  color: #b2dcaa;
}

.menu-active-highlight-grey #main-menu>ul.menu>li.current_page_item>a:before,
.menu-active-highlight-grey #main-menu>ul.menu>li.current_page_ancestor>a:before,
.menu-active-highlight-grey #main-menu>ul.menu>li.current-menu-item>a:before,
.menu-active-highlight-grey #main-menu>ul.menu>li.current-menu-ancestor>a:before {
  background: #b2dcaa;
}

.menu-active-with-single-border #main-menu>ul.menu>li.current_page_item>a,
.menu-active-with-single-border #main-menu>ul.menu>li.current_page_ancestor>a,
.menu-active-with-single-border #main-menu>ul.menu>li.current-menu-item>a,
.menu-active-with-single-border #main-menu>ul.menu>li.current-menu-ancestor>a {
  border-color: #b2dcaa;
}

#main-menu ul li.menu-item-simple-parent ul,
#main-menu .megamenu-child-container,
.dt-sc-icon-box.type11 .icon-wrapper {
  border-bottom-color: #b2dcaa;
}

.menu-active-highlight-grey #main-menu>ul.menu>li.current_page_item>a:before,
.menu-active-highlight-grey #main-menu>ul.menu>li.current_page_ancestor>a:before,
.menu-active-highlight-grey #main-menu>ul.menu>li.current-menu-item>a:before,
.menu-active-highlight-grey #main-menu>ul.menu>li.current-menu-ancestor>a:before,
.left-header .menu-active-highlight #main-menu>ul.menu>li.current_page_item>a,
.left-header .menu-active-highlight #main-menu>ul.menu>li.current_page_ancestor>a,
.left-header .menu-active-highlight #main-menu>ul.menu>li.current-menu-item>a,
.left-header .menu-active-highlight #main-menu>ul.menu>li.current-menu-ancestor>a {
  background: #b2dcaa;
}

.menu-active-highlight #main-menu>ul.menu>li.current_page_item,
.menu-active-highlight #main-menu>ul.menu>li.current_page_ancestor,
.menu-active-highlight #main-menu>ul.menu>li.current-menu-item,
.menu-active-highlight #main-menu>ul.menu>li.current-menu-ancestor,
.menu-active-with-icon #main-menu>ul.menu>li.current_page_item>a:before,
.menu-active-with-icon #main-menu>ul.menu>li.current_page_ancestor>a:before,
.menu-active-with-icon #main-menu>ul.menu>li.current-menu-item>a:before,
.menu-active-with-icon #main-menu>ul.menu>li.current-menu-ancestor>a:before,
.menu-active-with-icon #main-menu>ul.menu>li.current_page_item>a:after,
.menu-active-with-icon #main-menu>ul.menu>li.current_page_ancestor>a:after,
.menu-active-with-icon #main-menu>ul.menu>li.current-menu-item>a:after,
.menu-active-with-icon #main-menu>ul.menu>li.current-menu-ancestor>a:after,
.menu-active-border-with-arrow#main-menu>ul.menu>li.current_page_item>a:after,
.menu-active-border-with-arrow#main-menu>ul.menu>li.current_page_ancestor>a:after,
.menu-active-border-with-arrow#main-menu>ul.menu>li.current-menu-item>a:after,
.menu-active-border-with-arrow#main-menu>ul.menu>li.current-menu-ancestor>a:after,
.menu-active-with-two-border #main-menu>ul.menu>li.current_page_item>a:before,
.menu-active-with-two-border #main-menu>ul.menu>li.current_page_ancestor>a:before,
.menu-active-with-two-border #main-menu>ul.menu>li.current-menu-item>a:before,
.menu-active-with-two-border #main-menu>ul.menu>li.current-menu-ancestor>a:before,
.menu-active-with-two-border #main-menu>ul.menu>li.current_page_item>a:after,
.menu-active-with-two-border #main-menu>ul.menu>li.current_page_ancestor>a:after,
.menu-active-with-two-border #main-menu>ul.menu>li.current-menu-item>a:after,
.menu-active-with-two-border #main-menu>ul.menu>li.current-menu-ancestor>a:after,
.menu-active-highlight-with-arrow #main-menu>ul.menu>li.current_page_item>a,
.menu-active-highlight-with-arrow #main-menu>ul.menu>li.current_page_ancestor>a,
.menu-active-highlight-with-arrow #main-menu>ul.menu>li.current-menu-item>a,
.menu-active-highlight-with-arrow #main-menu>ul.menu>li.current-menu-ancestor>a {
  background: #b2dcaa;
}

.two-color-header .main-header-wrapper:before {
  background: #b2dcaa;
}

.menu-active-border-with-arrow#main-menu>ul.menu>li.current_page_item>a:before,
.menu-active-border-with-arrow#main-menu>ul.menu>li.current_page_ancestor>a:before,
.menu-active-border-with-arrow#main-menu>ul.menu>li.current-menu-item>a:before,
.menu-active-border-with-arrow#main-menu>ul.menu>li.current-menu-ancestor>a:before {
  border-bottom-color: #b2dcaa;
}

.menu-active-highlight-with-arrow #main-menu>ul.menu>li.current_page_item>a:before,
.menu-active-highlight-with-arrow #main-menu>ul.menu>li.current_page_ancestor>a:before,
.menu-active-highlight-with-arrow #main-menu>ul.menu>li.current-menu-item>a:before,
.menu-active-highlight-with-arrow #main-menu>ul.menu>li.current-menu-ancestor>a:before {
  border-top-color: #b2dcaa;
}

#main-menu .menu-item-widget-area-container .widget ul li>a:hover,
#main-menu .megamenu-child-container.dt-sc-dark-bg>ul.sub-menu>li>a:hover,
#main-menu .megamenu-child-container.dt-sc-dark-bg ul.sub-menu>li>ul li a:hover,
#main-menu .megamenu-child-container.dt-sc-dark-bg ul.sub-menu>li>ul li a:hover .fa,
#main-menu .dt-sc-dark-bg .menu-item-widget-area-container .widget ul li>a:hover,
#main-menu .dt-sc-dark-bg .menu-item-widget-area-container .widget_recent_posts .entry-title h4 a:hover,
#main-menu ul li.menu-item-simple-parent.dt-sc-dark-bg ul li a:hover,
#main-menu .menu-item-widget-area-container .widget li:hover:before {
  color: #b2dcaa;
}

.main-header .menu-icons-wrapper .overlay-search #searchform:after {
  border-color: #b2dcaa;
}

.dt-menu-toggle {
  background: #b2dcaa;
}

.dt-sc-sociable.rounded-border li a:hover,
.dt-sc-dark-bg .dt-sc-sociable.rounded-border li a:hover,
.dt-sc-dark-bg .dt-sc-sociable.square-border li a:hover,
.dt-sc-sociable.diamond-square-border li:hover,
.widget .dt-sc-newsletter-section.boxed,
.widget .dt-sc-newsletter-section.boxed .dt-sc-subscribe-frm input[type="submit"],
.tagcloud a:hover,
.dt-sc-dark-bg .tagcloud a:hover,
.blog-entry.entry-date-left .entry-date span,
.dt-sc-menu-sorting a.active-sort,
.dt-sc-menu .image-overlay .price,
.hotel-search-container form input[type="submit"],
.pagination ul li a:hover,
.pagination ul li span,
.blog-entry.blog-medium-style:hover .entry-format a,
ul.commentlist li .reply a:hover,
.dt-sc-team .dt-sc-team-social.diamond-square-border li:hover,
.dt-sc-team-social.hexagon-border li:hover,
.dt-sc-team-social.hexagon-border li:hover:before,
.dt-sc-team-social.hexagon-border li:hover:after,
.dt-sc-single-hexagon:hover,
.dt-sc-single-hexagon:hover:before,
.dt-sc-single-hexagon:hover:after,
.portfolio.hover-presentation .image-overlay .links a:before,
.section21 .stats-block {
  border-color: #b2dcaa;
}

.entry-format a,
.menu-icons-wrapper.rounded-icons .search a span:hover,
.menu-icons-wrapper.rounded-icons .cart a span:hover,
.live-chat a,
input[type="submit"],
button,
input[type="reset"],
.widget .dt-sc-newsletter-section.boxed .dt-sc-subscribe-frm input[type="submit"]:hover,
.tagcloud a:hover,
.dt-sc-dark-bg .tagcloud a:hover,
.widgettitle:before,
.domain-search-container .domain-search-form,
.dt-sc-icon-box.type6 .icon-wrapper,
.skin-highlight,
.menu-icons-wrapper .cart sup,
.page-link>span,
.page-link a:hover,
.post-edit-link:hover,
.vc_inline-link:hover,
.pagination ul li a:hover,
.pagination ul li span,
.widget.widget_categories ul li>a:hover span,
.widget.widget_archive ul li>a:hover span,
#footer .dt-sc-dark-bg .widget.widget_categories ul li>a:hover span,
#footer .dt-sc-dark-bg .widget.widget_archive ul li>a:hover span,
.blog-entry.blog-medium-style:hover .entry-format a,
.blog-entry.blog-medium-style.dt-blog-medium-highlight.dt-sc-skin-highlight,
.blog-entry.blog-medium-style.dt-blog-medium-highlight.dt-sc-skin-highlight .entry-format a,
ul.commentlist li .reply a:hover,
.dt-sc-dark-bg .widget.widget_categories ul li>a:hover span,
th,
.dt-bmi-inner-content tbody th,
.dt-bmi-inner-content tbody tr:nth-child(2n+1) th,
.loader,
.dt-sc-counter.type3:hover .icon-wrapper,
.mz-title .mz-title-content h2,
.mz-title-content h3.widgettitle,
.mz-title .mz-title-content:before,
.mz-blog .comments a,
.mz-blog div.vc_gitem-post-category-name,
.mz-blog .ico-format,
.dt-sc-team-social.hexagon-border li:hover,
.dt-sc-team .dt-sc-team-social.diamond-square-border li:hover,
.dt-sc-team.hide-social-role-show-on-hover .dt-sc-team-social.rounded-square li:hover a,
.dt-sc-single-hexagon .dt-sc-single-hexagon-overlay,
.portfolio.hover-title-icons-overlay .image-overlay .links a:hover,
.portfolio.hover-minimal-icons .image-overlay .links a:hover,
.portfolio.hover-presentation .image-overlay-details .categories a:before,
.portfolio.hover-presentation .image-overlay .links a:hover:before,
.dt-portfolio-single-slider-wrapper .bx-default-pager a.active,
.dt-portfolio-single-slider-wrapper .bx-default-pager a:hover,
.dt-sc-onepage-navigation-title-holder.boxed>li>a.active,
.dt-sc-onepage-navigation-title-holder.default>li>a.active,
.section9 .content-area:hover,
.section2 .title2,
.section2 .aio-icon,
.section2 .aio-icon,
.section35 .slick-next:hover,
.section35 .slick-prev:hover,
.portfolio.hover-Bottom .image-overlay:after {
  background: #b2dcaa;
}

.overlay-header .dt-sc-dark-bg .overlay {
  background: rgba(178, 220, 170, 0.9);
}

.portfolio .image-overlay,
.recent-portfolio-widget ul li a:before,
.dt-sc-image-caption.type2:hover .dt-sc-image-content {
  background: rgba(178, 220, 170, 0.9);
}

.two-color-header.semi-transparent-header .main-header-wrapper:before,
.two-color-header.transparent-header .is-sticky .main-header-wrapper:before {
  background: rgba(178, 220, 170, 0.7);
}

.available-domains li .tdl:before,
.available-domains li:hover .dt-sc-button,
.dt-sc-highlight.extend-bg-fullwidth-left:after,
.dt-sc-highlight.extend-bg-fullwidth-right:after,
.dt-sc-menu .menu-categories a:before,
.hotel-search-container form input[type="submit"]:hover,
.hotel-search-container .selection-box:after {
  background: #b2dcaa;
}

.dt-sc-up-arrow:before,
.dt-sc-image-caption .dt-sc-image-wrapper .icon-wrapper:before,
.dt-sc-triangle-wrapper.alter:hover .dt-sc-triangle-content:before {
  border-bottom-color: #b2dcaa;
}

.dt-sc-triangle-wrapper:hover .dt-sc-triangle-content:before {
  border-top-color: #b2dcaa;
}

.secondary-sidebar .type5 .widgettitle {
  border-color: rgba(178, 220, 170, 0.5);
}

.secondary-sidebar .type3 .widgettitle,
.secondary-sidebar .type6 .widgettitle,
.secondary-sidebar .type13 .widgettitle:before,
.secondary-sidebar .type16 .widgettitle {
  border-color: #b2dcaa;
}

.secondary-sidebar .type12 .widgettitle {
  background: rgba(178, 220, 170, 0.2);
}

.dt-sc-icon-box.type1 .icon-wrapper .icon,
.dt-sc-icon-box.type2 .icon-wrapper .icon,
.skin-highlight .dt-sc-button.rounded-border:hover,
.skin-highlight .dt-sc-button.bordered:hover,
.dt-sc-icon-box.type4 .icon-wrapper span,
.dt-sc-icon-box.type5:hover .icon-content h4 a,
.dt-sc-testimonial-special-wrapper:after,
.dt-sc-popular-procedures .details .duration,
.dt-sc-popular-procedures .details .price,
.dt-sc-testimonial.type4 .dt-sc-testimonial-author cite,
ul.dt-sc-tabs-vertical-frame>li>a:hover,
ul.dt-sc-tabs-vertical-frame>li.current a,
.dt-sc-text-with-icon span,
.dt-sc-testimonial.type5 .dt-sc-testimonial-author cite,
ul.dt-sc-tabs-horizontal-frame>li>a.current,
.dt-sc-title.script-with-sub-title h2,
.dt-sc-image-caption.type3 .dt-sc-image-content h3,
.dt-sc-menu-sorting a:hover,
.dt-sc-menu-sorting a.active-sort,
.dt-sc-team.rounded .dt-sc-team-details .dt-sc-team-social li a:hover,
.dt-sc-dark-bg .blog-medium-style.white-highlight .dt-sc-button.fully-rounded-border,
.dt-sc-contact-info.type3 span,
.dt-sc-timeline .dt-sc-timeline-content h2 span,
.dt-sc-title.with-two-color-stripe h2,
.dt-sc-hr-timeline-section.type2 .dt-sc-hr-timeline-content:hover h3,
.dt-sc-training-details h6,
.dt-sc-icon-box.type5.no-icon-bg .icon-wrapper span,
.dt-sc-icon-box.type5.no-icon-bg:hover .icon-wrapper span,
.dt-sc-counter.type3.diamond-square h4,
.dt-sc-icon-box.type10:hover .icon-content h4,
.dt-sc-counter.type6:hover h4,
.dt-sc-button.with-shadow.white,
.dt-sc-pr-tb-col.type2 .dt-sc-buy-now a,
.dt-sc-event-image-caption .dt-sc-image-content h3,
.dt-sc-events-list .dt-sc-event-title h5 a,
.dt-sc-sociable.hexagon-border li:hover a,
.dt-sc-icon-box.type10 .icon-wrapper span,
.dt-sc-button.fully-rounded-border,
.dt-sc-button.rounded-border,
.dt-sc-button.bordered,
ul.dt-sc-tabs-horizontal>li>a.current,
ul.dt-sc-tabs-vertical>li>a.current,
ul.dt-sc-tabs-horizontal>li>a:hover,
ul.dt-sc-tabs-vertical>li>a:hover,
ul.dt-sc-tabs-horizontal-frame>li>a:hover,
.dt-sc-dark-bg.skin-color .dt-sc-button.fully-rounded-border:hover,
.dt-sc-sociable.hexagon-with-border li:hover a,
.type7 ul.dt-sc-tabs-horizontal-frame>li>a.current,
.dt-sc-fitness-diet:hover .dt-sc-fitness-diet-details h5,
table.fit-Diet-table th strong,
blockquote.type4>cite,
.dt-sc-hotel-room-single-metadata ul li,
.dt-sc-hotel-room .dt-sc-hotel-room-details ul li,
.dt-sc-team.rounded.team_rounded_border:hover .dt-sc-team-details h4,
.woocommerce-MyAccount-navigation ul>li.is-active>a,
.business-contact-social li a:hover,
.dt-sc-contact-info.type3 h6 {
  color: #b2dcaa;
}

.dt-sc-menu-sorting a {
  color: rgba(178, 220, 170, 0.6);
}

.dt-sc-small-separator,
.dt-sc-button.filled,
.dt-sc-button:hover,
.dt-sc-highlight,
.dt-sc-icon-box.type1 .icon-content h4:before,
.dt-sc-counter.type1 .icon-wrapper:before,
.dt-sc-testimonial-wrapper .dt-sc-testimonial-bullets a:hover,
.dt-sc-testimonial-wrapper .dt-sc-testimonial-bullets a.active,
.dt-sc-team-social.rounded-border li a:hover,
.dt-sc-icon-box.type3 .icon-wrapper span,
.dt-sc-team-social.rounded-square li a,
.dt-sc-team.hide-social-show-on-hover:hover .dt-sc-team-details,
.dt-sc-button.rounded-border:hover,
.dt-sc-button.bordered:hover,
.carousel-arrows a:hover,
.dt-sc-diamond-separator,
.dt-sc-icon-box.type5:hover .icon-wrapper:before,
.dt-sc-pr-tb-col.minimal:hover .dt-sc-price,
.dt-sc-pr-tb-col.minimal.selected .dt-sc-price,
.dt-sc-pr-tb-col:hover .dt-sc-buy-now a,
.dt-sc-pr-tb-col.selected .dt-sc-buy-now a,
.dt-sc-pr-tb-col.minimal:hover .icon-wrapper:before,
.dt-sc-pr-tb-col.minimal.selected .icon-wrapper:before,
.dt-sc-icon-box.type7:hover .icon-wrapper span,
.dt-sc-team-social.square-border li a:hover,
.two-color-section:before,
.dt-sc-contact-info.type2:hover span,
.dt-sc-colored-big-buttons:hover,
.dt-sc-colored-big-buttons span,
.dt-sc-team.rounded:hover .dt-sc-team-thumb:after,
.dt-sc-button.fully-rounded-border:hover,
.dt-sc-dark-bg .blog-medium-style.white-highlight .dt-sc-button.fully-rounded-border:hover,
.dt-sc-title.with-two-color-bg:after,
.dt-sc-hr-timeline-section.type1:before,
.dt-sc-hr-timeline-section.type1 .dt-sc-hr-timeline .dt-sc-hr-timeline-content:after,
.dt-sc-hr-timeline-section.type1 .dt-sc-hr-timeline-wrapper:before,
.dt-sc-hr-timeline-section.type1 .dt-sc-hr-timeline-wrapper:after,
.dt-sc-hr-timeline-section.type2 .dt-sc-hr-timeline-content h3:before,
.dt-sc-hr-timeline-section.type2 .dt-sc-hr-timeline:hover .dt-sc-hr-timeline-thumb:before,
.dt-sc-training-details-overlay,
.dt-sc-classes:hover .dt-sc-classes-details,
.dt-sc-icon-box.type5.rounded:hover .icon-wrapper,
.dt-sc-image-caption.type4:hover .dt-sc-button,
.dt-sc-timeline-section.type2:before,
.dt-sc-counter.type5:hover:after,
.dt-sc-triangle-title:after,
.diamond-narrow-square-border li:hover:before,
.dt-sc-title.with-right-border-decor:after,
.dt-sc-icon-box.type10:hover .icon-wrapper:before,
.dt-sc-icon-box.type10 .icon-content h4:before,
.dt-sc-title.with-right-border-decor:before,
.dt-sc-team.hide-social-role-show-on-hover:hover .dt-sc-team-details,
.dt-sc-team.hide-social-role-show-on-hover .dt-sc-team-social li:hover,
.dt-sc-counter.type6 h4:before,
.dt-sc-counter.type6:hover .dt-sc-couter-icon-holder:before,
.dt-sc-contact-info.type4 span:after,
.dt-sc-contact-info.type4:before,
.dt-sc-pr-tb-col.type2 .dt-sc-tb-header .dt-sc-tb-title:before,
.dt-sc-pr-tb-col.type2 .dt-sc-tb-content:before,
.dt-sc-pr-tb-col.type2 .dt-sc-tb-content li .highlight,
.dt-sc-pr-tb-col.type2:hover .dt-sc-price:before,
.dt-sc-pr-tb-col.type2.selected .dt-sc-price:before,
.dt-sc-event-image-caption:hover,
.dt-sc-content-with-hexagon-shape,
.dt-sc-sociable.hexagon-with-border li,
.dt-sc-contact-info.type5 .dt-sc-contact-icon,
.dt-sc-title.with-boxed,
.dt-sc-readmore-plus-icon:hover:before,
.dt-sc-readmore-plus-icon:hover:after,
.dt-sc-icon-box.type11:before,
.dt-sc-hexagons li .dt-sc-hexagon-overlay,
.dt-sc-counter.type3.diamond-square .dt-sc-couter-icon-holder .icon-wrapper:before,
.dt-sc-titled-box h6.dt-sc-titled-box-title,
.dt-sc-pr-tb-col.type2:hover .dt-sc-buy-now a,
.dt-sc-counter.type4:hover .dt-sc-couter-icon-holder,
.dt-sc-images-wrapper .carousel-arrows a:hover,
.type8 ul.dt-sc-tabs-horizontal-frame>li>a.current,
.type8 ul.dt-sc-tabs-horizontal-frame>li>a:hover,
.dt-sc-icon-box.type5.alter .icon-wrapper:before,
.dt-sc-toggle-frame-set.type2>h5.dt-sc-toggle-accordion.active:after,
.dt-sc-skin-highlight,
.dt-sc-skin-highlight.extend-bg-fullwidth-left:after,
.dt-sc-skin-highlight.extend-bg-fullwidth-right:after,
.dt-sc-newsletter-section.type1 h2:before,
.dt-sc-newsletter-section.type1 h2:after,
.dt-sc-newsletter-section.type6 .dt-sc-subscribe-frm:after,
#footer .dt-sc-sociable.rounded-border.filled li a:hover {
  background: #b2dcaa;
}

.dt-sc-contact-info.type5:hover,
.dt-sc-contact-info.type6,
.dt-sc-video-wrapper .video-overlay-inner a,
.nicescroll-rails.dt-sc-skin,
.dt-sc-video-item:hover .dt-sc-vitem-detail,
.dt-sc-video-item.active .dt-sc-vitem-detail,
.type2 .dt-sc-video-item:hover,
.type2 .dt-sc-video-item.active,
.ballSettings {
  background-color: #b2dcaa;
}

.dt-sc-team.type2 .dt-sc-team-thumb .dt-sc-team-thumb-overlay,
.dt-sc-hexagon-image span:before {
  background: rgba(178, 220, 170, 0.9);
}

.dt-sc-testimonial-wrapper .dt-sc-testimonial-bullets a:hover,
.dt-sc-testimonial-wrapper .dt-sc-testimonial-bullets a.active,
.dt-sc-testimonial-wrapper .dt-sc-testimonial-bullets a.active:before,
.dt-sc-testimonial-wrapper .dt-sc-testimonial-bullets a.active:hover:before,
.dt-sc-team-social.rounded-border li a:hover,
.carousel-arrows a:hover,
.dt-sc-testimonial-images li.selected div,
.dt-sc-team-social.square-border li a:hover,
.dt-sc-image-caption.type2 .dt-sc-image-content,
.dt-sc-text-with-icon.border-bottom,
.dt-sc-text-with-icon.border-right,
.dt-sc-testimonial.type5 .dt-sc-testimonial-quote,
.dt-sc-contact-info.type2:hover,
.dt-sc-newsletter-section.type2 .dt-sc-subscribe-frm input[type="text"],
.dt-sc-colored-big-buttons,
.dt-sc-button.fully-rounded-border,
.dt-sc-button.fully-rounded-border:hover,
.dt-sc-dark-bg .blog-medium-style.white-highlight .dt-sc-button.fully-rounded-border,
.dt-sc-button.rounded-border.black:hover,
.dt-sc-button.bordered.black:hover,
.dt-sc-button.bordered:hover,
.dt-sc-button.rounded-border:hover,
.dt-sc-hr-timeline-section.type1 .dt-sc-hr-timeline .dt-sc-hr-timeline-content:before,
.dt-sc-image-caption.type4,
.dt-sc-image-caption.type4:hover .dt-sc-button,
.dt-sc-timeline-section.type2 .dt-sc-timeline-image-wrapper,
.dt-sc-timeline-section.type2 .dt-sc-timeline .dt-sc-timeline-content:after,
.dt-sc-timeline-section.type2:after,
.dt-sc-counter.type3.diamond-square,
.dt-sc-icon-box.type5.no-icon .icon-content h4,
.dt-sc-icon-box.type5.no-icon,
.dt-sc-counter.type5:hover:before,
.dt-sc-counter.type5:hover:after,
.diamond-narrow-square-border li:before,
.dt-sc-title.with-right-border-decor h2:before,
.dt-sc-icon-box.type10 .icon-wrapper:before,
.dt-sc-icon-box.type10,
.dt-sc-counter.type6,
.dt-sc-contact-info.type4,
.last .dt-sc-contact-info.type4,
.dt-sc-pr-tb-col.type2 .dt-sc-tb-header:before,
.dt-sc-hexagons li:hover,
.dt-sc-hexagons li:hover:before,
.dt-sc-hexagons li:hover:after,
.dt-sc-hexagons li,
.dt-sc-hexagons li:before,
.dt-sc-hexagons li .dt-sc-hexagon-overlay:before,
.dt-sc-hexagons li:after,
.dt-sc-hexagons li .dt-sc-hexagon-overlay:after,
ul.dt-sc-tabs-horizontal>li>a.current,
ul.dt-sc-tabs-vertical>li>a.current,
.dt-sc-team.team_rounded_border.rounded:hover .dt-sc-team-thumb:before,
.vc_custom_carousel .slick-slider .slick-dots,
.vc_custom_carousel .slick-slider:before,
.dt-sc-contact-info.type3:before {
  border-color: #b2dcaa;
}

.dt-sc-button.filled {
  outline-color: #b2dcaa;
}

.dt-sc-pr-tb-col.type2 .dt-sc-tb-content:after,
.dt-sc-content-with-hexagon-shape:after,
.type7 ul.dt-sc-tabs-horizontal-frame>li>a.current:before,
.type7 ul.dt-sc-tabs-horizontal-frame>li>a.current:after {
  border-top-color: #b2dcaa;
}

.dt-sc-content-with-hexagon-shape:before {
  border-bottom-color: #b2dcaa;
}

.dt-sc-event-image-caption:hover .dt-sc-image-content:before {
  border-left-color: #b2dcaa;
}

.dt-sc-toggle-frame h5.dt-sc-toggle-accordion.active a,
.dt-sc-toggle-frame h5.dt-sc-toggle.active a {
  color: #b2dcaa;
}

.dt-sc-toggle-frame h5.dt-sc-toggle-accordion.active a:before,
h5.dt-sc-toggle-accordion.active a:before,
.dt-sc-toggle-frame h5.dt-sc-toggle.active a:before,
h5.dt-sc-toggle.active a:before,
.type2 .dt-sc-toggle-frame h5.dt-sc-toggle-accordion.active,
.type2 .dt-sc-toggle-frame h5.dt-sc-toggle.active {
  background: #b2dcaa;
}

.type2 .dt-sc-toggle-frame h5.dt-sc-toggle-accordion.active,
.type2 .dt-sc-toggle-frame h5.dt-sc-toggle.active {
  border-color: #b2dcaa;
}

ul.side-nav li a:hover,
.dt-sc-tabs-vertical-frame-container.type2 ul.dt-sc-tabs-vertical-frame>li>a.current:before,
.dt-sc-image-caption.type8:hover .dt-sc-image-content h3 a:hover,
.dt-sc-icon-box.type13 .icon-content h4,
.dt-sc-testimonial.type8 .dt-sc-testimonial-quote blockquote q:before,
.dt-sc-testimonial.type8 .dt-sc-testimonial-quote blockquote q:after,
.dt-sc-hexagon-title h2 span,
.dt-sc-toggle-panel h2 span,
.dt-sc-testimonial.type7 .dt-sc-testimonial-quote blockquote cite,
.dt-sc-timeline-section.type4 .dt-sc-timeline:hover .dt-sc-timeline-content h2,
.dt-sc-icon-box.type14 .icon-content h4 {
  color: #b2dcaa;
}

.dt-sc-timeline-section.type3 .dt-sc-timeline .dt-sc-timeline-content h2:before,
ul.side-nav li a:hover:before,
ul.side-nav>li.current_page_item>a:before,
ul.side-nav>li>ul>li.current_page_item>a:before,
ul.side-nav>li>ul>li>ul>li.current_page_item>a:before,
.dt-sc-contact-info.type7 span:after,
.dt-sc-tabs-horizontal-frame-container.type4 ul.dt-sc-tabs-horizontal-frame>li>a.current>span:after,
.dt-sc-icon-box.type3.dt-sc-diamond:hover .icon-wrapper:after,
.dt-sc-icon-box.type5.rounded-skin .icon-wrapper,
.dt-sc-image-caption.type8 .dt-sc-image-content:before,
.dt-sc-tabs-horizontal-frame-container.type5 ul.dt-sc-tabs-horizontal-frame>li>a.current,
.dt-sc-icon-box.type13:hover,
.dt-sc-contact-info.type8:hover span,
.dt-sc-toggle-icon,
.dt-sc-timeline-section.type4 .dt-sc-timeline .dt-sc-timeline-content h2:before,
.dt-sc-timeline-section.type4 .dt-sc-timeline:hover .dt-sc-timeline-thumb:before,
.dt-sc-icon-box.type12,
.dt-sc-tabs-horizontal-frame-container.type6 ul.dt-sc-tabs-horizontal-frame>li>a,
.dt-sc-icon-box.type14:hover,
.dt-sc-tabs-vertical-frame-container.type3 ul.dt-sc-tabs-vertical-frame>li>a:hover,
.dt-sc-tabs-vertical-frame-container.type3 ul.dt-sc-tabs-vertical-frame>li>a.current,
.dt-sc-tabs-vertical-frame-container.type4 ul.dt-sc-tabs-vertical-frame>li>a:before,
.dt-sc-tabs-vertical-frame-container.type4 ul.dt-sc-tabs-vertical-frame>li>a:after {
  background: #b2dcaa;
}

.dt-sc-keynote-speakers .dt-sc-speakers-thumb .dt-sc-speakers-thumb-overlay {
  background: rgba(178, 220, 170, 0.9);
}

.dt-sc-timeline-section.type4 .dt-sc-timeline-thumb-overlay {
  background: rgba(178, 220, 170, 0.7);
}

.type3 .dt-sc-toggle-frame .dt-sc-toggle-content,
.dt-sc-tabs-vertical-frame-container.type3 ul.dt-sc-tabs-vertical-frame>li>a.current:before {
  border-left-color: #b2dcaa;
}

.dt-sc-tabs-horizontal-frame-container.type3 ul.dt-sc-tabs-horizontal-frame>li>a.current,
.dt-sc-tabs-horizontal-frame-container.type4 ul.dt-sc-tabs-horizontal-frame>li>a.current {
  border-bottom-color: #b2dcaa;
}

.dt-sc-icon-box.type3.dt-sc-diamond:hover .icon-wrapper:after,
.dt-sc-single-hexagon,
.dt-sc-single-hexagon:before,
.dt-sc-single-hexagon .dt-sc-single-hexagon-overlay:before,
.dt-sc-single-hexagon:after,
.dt-sc-single-hexagon .dt-sc-single-hexagon-overlay:after,
.dt-sc-counter.type6 .dt-sc-couter-icon-holder:before,
.dt-sc-tabs-vertical-frame-container.type3 ul.dt-sc-tabs-vertical-frame>li>a:hover,
.dt-sc-tabs-vertical-frame-container.type3 ul.dt-sc-tabs-vertical-frame>li>a.current,
.page-link>span,
.page-link a:hover,
.dt-sc-counter.type3 .icon-wrapper:before,
.dt-sc-attorney-sorting,
.secondary-sidebar .type14 .widgettitle {
  border-color: #b2dcaa;
}

.skin-highlight .dt-sc-tabs-horizontal-frame-container.type6 ul.dt-sc-tabs-horizontal-frame>li>a:before {
  border-top-color: #b2dcaa;
}

.dt-sc-button.with-icon.icon-right.type1:hover,
.dt-sc-button.filled:hover,
input[type="submit"]:hover,
button:hover,
input[type="reset"]:hover,
.dt-sc-team-social.rounded-square li a:hover,
.dt-sc-icon-box.type3:hover .icon-wrapper span,
.dt-sc-newsletter-section.type2 .dt-sc-subscribe-frm input[type="submit"]:hover,
.skin-highlight .dt-sc-testimonial.type6 .dt-sc-testimonial-author:before,
.skin-highlight .dt-sc-testimonial.type6:after,
.mz-blog .comments a:hover,
.mz-blog div.vc_gitem-post-category-name:hover,
.dt-sc-video-wrapper .video-overlay-inner a:hover {
  background: #95db87;
}

.dt-sc-image-caption.type2:hover .dt-sc-image-content,
.dt-sc-newsletter-section.type2 .dt-sc-subscribe-frm input[type="email"],
.dt-sc-sociable.hexagon-with-border li,
.dt-sc-sociable.hexagon-with-border li:before,
.dt-sc-sociable.hexagon-with-border li:after,
.dt-sc-contact-info.type5 .dt-sc-contact-icon,
.dt-sc-contact-info.type5 .dt-sc-contact-icon:before,
.dt-sc-contact-info.type5 .dt-sc-contact-icon:after {
  border-color: #95db87;
}

.dt-sc-icon-box.type10 .icon-wrapper:before,
.dt-sc-contact-info.type4 span:after,
.dt-sc-pr-tb-col.type2 .dt-sc-tb-header:before {
  box-shadow: 5px 0px 0px 0px #95db87;
}

.dt-sc-icon-box.type10:hover .icon-wrapper:before {
  box-shadow: 7px 0px 0px 0px #95db87;
}

.dt-sc-counter.type6 .dt-sc-couter-icon-holder:before {
  box-shadow: 5px 1px 0px 0px #95db87;
}

.dt-sc-button.with-shadow.white,
.dt-sc-pr-tb-col.type2 .dt-sc-buy-now a {
  box-shadow: 3px 3px 0px 0px #95db87;
}

.dt-sc-triangle-title:before,
.dt-sc-icon-box.type10 .icon-wrapper:after {
  background: #bedbb8;
}

#buddypress div.pagination .pagination-links span,
#buddypress div.pagination .pagination-links a:hover,
#buddypress #members-dir-list ul li:hover {
  border-color: #b2dcaa;
}

#buddypress div.pagination .pagination-links span,
#buddypress div.pagination .pagination-links a:hover,
#buddypress #group-create-body #group-creation-previous,
#item-header-content #item-meta>#item-buttons .group-button,
#buddypress div#subnav.item-list-tabs ul li.feed a:hover,
#buddypress div.activity-meta a:hover,
#buddypress div.item-list-tabs ul li.selected a span,
#buddypress .activity-list li.load-more a,
#buddypress .activity-list li.load-newest a {
  background-color: #b2dcaa;
}

#item-header-content #item-meta>#item-buttons .group-button:hover,
#buddypress .activity-list li.load-more a:hover,
#buddypress .activity-list li.load-newest a:hover {
  background-color: #95db87;
}

#members-list.item-list.single-line li h5 span.small a.button,
#buddypress div.item-list-tabs ul li.current a,
#buddypress #group-create-tabs ul li.current a,
#buddypress a.bp-primary-action:hover span,
#buddypress div.item-list-tabs ul li.selected a {
  color: #b2dcaa;
}

.bbp-pagination-links a:hover,
.bbp-pagination-links span.current {
  border-color: #b2dcaa;
}

#bbpress-forums li.bbp-header,
.bbp-submit-wrapper #bbp_topic_submit,
.bbp-reply-form #bbp_reply_submit,
.bbp-pagination-links a:hover,
.bbp-pagination-links span.current,
#bbpress-forums #subscription-toggle a.subscription-toggle {
  background-color: #b2dcaa;
}

#bbpress-forums #subscription-toggle a.subscription-toggle:hover,
.bbp-submit-wrapper #bbp_topic_submit:hover {
  background-color: #95db87;
}

.bbp-forums .bbp-body .bbp-forum-info::before {
  color: #b2dcaa;
}

#tribe-bar-views .tribe-bar-views-list .tribe-bar-views-option a:hover,
#tribe-bar-views .tribe-bar-views-list .tribe-bar-views-option.tribe-bar-active a:hover,
#tribe-bar-form .tribe-bar-submit input[type="submit"],
#tribe-bar-views .tribe-bar-views-list li.tribe-bar-active a,
.tribe-events-calendar thead th,
#tribe-events-content .tribe-events-tooltip h4,
.tribe-events-calendar td.tribe-events-present div[id*="tribe-events-daynum-"],
.tribe-events-read-more,
#tribe-events .tribe-events-button,
.tribe-events-button,
.tribe-events-calendar td.tribe-events-present div[id*="tribe-events-daynum-"]>a,
.tribe-events-back>a,
#tribe_events_filters_toggle {
  background: #b2dcaa;
}

#tribe-bar-form .tribe-bar-submit input[type="submit"]:hover,
.tribe-events-read-more:hover,
#tribe-events .tribe-events-button:hover,
.tribe-events-button:hover,
.tribe-events-back>a:hover,
.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr th:hover,
#tribe_events_filters_toggle:hover {
  background: #95db87;
}

.tribe-events-list .tribe-events-event-cost span {
  border-color: #b2dcaa;
}

.tribe-grid-header,
.tribe-grid-allday .tribe-events-week-allday-single,
.tribe-grid-body .tribe-events-week-hourly-single {
  background: #b2dcaa;
}

.tribe-grid-header .tribe-week-today {
  background-color: #95db87;
}

.type1 .event-schedule,
.type1.tribe_events .nav-top-links a:hover,
.type1.tribe_events .event-image-wrapper .event-datetime>i,
.type1.tribe_events .event-image-wrapper .event-venue>i,
.type1.tribe_events h4 a,
.type2.tribe_events .date-wrapper p span,
.type2.tribe_events h4 a,
.type3.tribe_events .right-calc a:hover,
.type3.tribe_events .tribe-events-sub-nav li a:hover,
.type3.tribe_events .tribe-events-sub-nav li a span,
.type4.tribe_events .data-wrapper p span,
.type4.tribe_events .data-wrapper p i,
.type4.tribe_events .event-organize h4 a,
.type4.tribe_events .event-venue h4 a,
.type5.tribe_events .event-details h3,
.type5.tribe_events .event-organize h3,
.type5.tribe_events .event-venue h3,
.type5.tribe_events .data-wrapper p span,
.data-wrapper p i,
.type5.tribe_events .event-organize h4 a,
.type5.tribe_events .event-venue h4 a {
  color: #b2dcaa;
}

.type1.tribe_events .event-image-wrapper .event-datetime>span,
.type3.tribe_events .event-date,
.event-meta-tab ul.dt-sc-tabs-horizontal-frame>li>a {
  background: #b2dcaa;
}

.dt-sc-event.type1 .dt-sc-event-thumb p,
.dt-sc-event.type1 .dt-sc-event-meta:before,
.dt-sc-event.type2:hover .dt-sc-event-meta,
.dt-sc-event.type3 .dt-sc-event-date,
.dt-sc-event.type3:hover .dt-sc-event-meta {
  background: #b2dcaa;
}

.dt-sc-event.type1 .dt-sc-event-meta p span,
.dt-sc-event.type1:hover h2.entry-title a,
.dt-sc-event.type3:hover h2.entry-title a,
.dt-sc-event.type4 .dt-sc-event-date span {
  color: #b2dcaa;
}

.dt-sc-event.type4 .dt-sc-event-date:after {
  border-bottom-color: #b2dcaa;
}

.woocommerce a.button,
.woocommerce button.button,
.woocommerce button,
.woocommerce input.button,
.woocommerce input[type=button],
.woocommerce input[type=submit],
.woocommerce #respond input#submit,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt,
.woocommerce #respond input#submit.alt,
.woocommerce .product .summary .add_to_wishlist,
.woocommerce .wishlist_table .add_to_cart.button,
.woocommerce .yith-wcwl-add-button a.add_to_wishlist,
.woocommerce .yith-wcwl-popup-button a.add_to_wishlist,
.woocommerce .wishlist_table a.ask-an-estimate-button,
.woocommerce .wishlist-title a.show-title-form,
.woocommerce .hidden-title-form a.hide-title-form,
.woocommerce .yith-wcwl-wishlist-new button,
.woocommerce .wishlist_manage_table a.create-new-wishlist,
.woocommerce .wishlist_manage_table button.submit-wishlist-changes,
.woocommerce .yith-wcwl-wishlist-search-form button.wishlist-search-button,
.woocommerce .cart input.button,
.woocommerce .shop_table th,
.woocommerce div.product .woocommerce-tabs ul.tabs li.active a:after,
.woocommerce-page a.button,
.woocommerce-page button.button,
.woocommerce-page button,
.woocommerce-page input.button,
.woocommerce-page input[type=button],
.woocommerce-page input[type=submit],
.woocommerce-page #respond input#submit,
.woocommerce-page a.button.alt,
.woocommerce-page button.button.alt,
.woocommerce-page input.button.alt,
.woocommerce-page #respond input#submit.alt,
.woocommerce-page .product .summary .add_to_wishlist,
.woocommerce-page .wishlist_table .add_to_cart.button,
.woocommerce-page .yith-wcwl-add-button a.add_to_wishlist,
.woocommerce-page .yith-wcwl-popup-button a.add_to_wishlist,
.woocommerce-page .wishlist_table a.ask-an-estimate-button,
.woocommerce-page .wishlist-title a.show-title-form,
.woocommerce-page .hidden-title-form a.hide-title-form,
.woocommerce-page .yith-wcwl-wishlist-new button,
.woocommerce-page .wishlist_manage_table a.create-new-wishlist,
.woocommerce-page .wishlist_manage_table button.submit-wishlist-changes,
.woocommerce-page .yith-wcwl-wishlist-search-form button.wishlist-search-button,
.woocommerce-page .cart input.button,
.woocommerce-page .shop_table th,
.woocommerce-page div.product .woocommerce-tabs ul.tabs li.active a:after {
  background-color: #b2dcaa;
}

.woocommerce ul.products li.product .featured-tag,
.woocommerce ul.products li.product:hover .featured-tag,
.woocommerce.single-product .featured-tag {
  background-color: #b2dcaa;
}

.woocommerce ul.products li.product .featured-tag:after,
.woocommerce ul.products li.product:hover .featured-tag:after,
.woocommerce.single-product .featured-tag:after {
  border-color: #b2dcaa;
}

.woocommerce-checkout #payment ul.payment_methods li a:hover {
  color: #b2dcaa;
}

.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce button:hover,
.woocommerce input.button:hover,
.woocommerce input[type=button]:hover,
.woocommerce input[type=submit]:hover,
.woocommerce #respond input#submit:hover,
.woocommerce a.button.alt:hover,
.woocommerce button.button.alt:hover,
.woocommerce input.button.alt:hover,
.woocommerce #respond input#submit.alt:hover,
.woocommerce .product .summary .add_to_wishlist:hover,
.woocommerce .wishlist_table .add_to_cart.button:hover,
.woocommerce .yith-wcwl-add-button a.add_to_wishlist:hover,
.woocommerce .yith-wcwl-popup-button a.add_to_wishlist:hover,
.woocommerce .wishlist_table a.ask-an-estimate-button:hover,
.woocommerce .wishlist-title a.show-title-form:hover,
.woocommerce .hidden-title-form a.hide-title-form:hover,
.woocommerce .yith-wcwl-wishlist-new button:hover,
.woocommerce .wishlist_manage_table a.create-new-wishlist:hover,
.woocommerce .wishlist_manage_table button.submit-wishlist-changes:hover,
.woocommerce .yith-wcwl-wishlist-search-form button.wishlist-search-button:hover,
.woocommerce .cart input.button:hover,
.woocommerce-page a.button:hover,
.woocommerce-page button.button:hover,
.woocommerce-page button:hover,
.woocommerce-page input.button:hover,
.woocommerce-page input[type=button]:hover,
.woocommerce-page input[type=submit]:hover,
.woocommerce-page #respond input#submit:hover,
.woocommerce-page a.button.alt:hover,
.woocommerce-page button.button.alt:hover,
.woocommerce-page input.button.alt:hover,
.woocommerce-page #respond input#submit.alt:hover,
.woocommerce-page .product .summary .add_to_wishlist:hover,
.woocommerce-page .wishlist_table .add_to_cart.button:hover,
.woocommerce-page .yith-wcwl-add-button a.add_to_wishlist:hover,
.woocommerce-page .yith-wcwl-popup-button a.add_to_wishlist:hover,
.woocommerce-page .wishlist_table a.ask-an-estimate-button:hover,
.woocommerce-page .wishlist-title a.show-title-form:hover,
.woocommerce-page .hidden-title-form a.hide-title-form:hover,
.woocommerce-page .yith-wcwl-wishlist-new button:hover,
.woocommerce-page .wishlist_manage_table a.create-new-wishlist:hover,
.woocommerce-page .wishlist_manage_table button.submit-wishlist-changes:hover,
.woocommerce-page .yith-wcwl-wishlist-search-form button.wishlist-search-button:hover,
.woocommerce-page .cart input.button:hover,
.woocommerce a.button.alt.disabled,
.woocommerce a.button.alt:disabled,
.woocommerce a.button.alt[disabled]:disabled,
.woocommerce button.button.alt.disabled,
.woocommerce button.button.alt:disabled,
.woocommerce button.button.alt[disabled]:disabled,
.woocommerce input.button.alt.disabled,
.woocommerce input.button.alt:disabled,
.woocommerce input.button.alt[disabled]:disabled,
.woocommerce #respond input#submit.alt.disabled,
.woocommerce #respond input#submit.alt:disabled,
.woocommerce #respond input#submit.alt[disabled]:disabled,
.woocommerce a.button.alt.disabled:hover,
.woocommerce a.button.alt:disabled:hover,
.woocommerce a.button.alt[disabled]:disabled:hover,
.woocommerce button.button.alt.disabled:hover,
.woocommerce button.button.alt:disabled:hover,
.woocommerce button.button.alt[disabled]:disabled:hover,
.woocommerce input.button.alt.disabled:hover,
.woocommerce input.button.alt:disabled:hover,
.woocommerce input.button.alt[disabled]:disabled:hover,
.woocommerce #respond input#submit.alt.disabled:hover,
.woocommerce #respond input#submit.alt:disabled:hover,
.woocommerce #respond input#submit.alt[disabled]:disabled:hover,
.woocommerce a.button.disabled:hover,
.woocommerce a.button:disabled:hover,
.woocommerce a.button:disabled[disabled]:hover,
.woocommerce button.button.disabled:hover,
.woocommerce button.button:disabled:hover,
.woocommerce button.button:disabled[disabled]:hover,
.woocommerce input.button.disabled:hover,
.woocommerce input.button:disabled:hover,
.woocommerce input.button:disabled[disabled]:hover,
.woocommerce #respond input#submit.disabled:hover,
.woocommerce #respond input#submit:disabled:hover,
.woocommerce #respond input#submit:disabled[disabled]:hover {
  background-color: #95db87;
}

.woo-type1 ul.products li.product .product-thumb a.add_to_cart_button:hover,
.woo-type1 ul.products li.product .product-thumb a.button.product_type_simple:hover,
.woo-type1 ul.products li.product .product-thumb a.button.product_type_variable:hover,
.woo-type1 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover,
.woo-type1 ul.products li.product .product-thumb a.add_to_wishlist:hover,
.woo-type1 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover,
.woo-type1 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover {
  background-color: #b2dcaa;
}

.woo-type1 ul.products li.product-category:hover .product-details h5,
.woo-type1 ul.products li.product-category:hover .product-details h5 .count,
.woo-type1 ul.products li.product .product-details .product-price .amount,
.woo-type1 ul.products li.product .product-details span.price,
.woo-type1 ul.products li.product .product-details span.price del,
.woo-type1 ul.products li.product .product-details span.price del .amount,
.woo-type1 ul.products li.product .product-details span.price ins,
.woo-type1 ul.products li.product .product-details span.price ins .amount,
.woo-type1.woocommerce.single-product .product .summary .product_meta a:hover,
.woo-type1.woocommerce div.product .woocommerce-tabs ul.tabs li.active a {
  color: #b2dcaa;
}

.woo-type1 .woocommerce ul.products li.product .star-rating:before,
.woo-type1 .woocommerce ul.products li.product .star-rating span:before,
.woo-type1.woocommerce ul.products li.product .star-rating:before,
.woo-type1.woocommerce ul.products li.product .star-rating span:before,
.woo-type1.woocommerce .star-rating:before,
.woo-type1.woocommerce .star-rating span:before,
.woo-type1 .woocommerce .star-rating:before,
.woo-type1 .woocommerce .star-rating span:before {
  color: rgba(178, 220, 170, 0.85);
}

.woo-type1 ul.products li.product:hover .product-thumb:after {
  -webkit-box-shadow: 0 0 0 10px rgba(190, 219, 184, 0.35) inset;
  -moz-box-shadow: 0 0 0 10px rgba(190, 219, 184, 0.35) inset;
  -ms-box-shadow: 0 0 0 10px rgba(190, 219, 184, 0.35) inset;
  -o-box-shadow: 0 0 0 10px rgba(190, 219, 184, 0.35) inset;
  box-shadow: 0 0 0 10px rgba(190, 219, 184, 0.35) inset;
}

.woo-type2 ul.products li.product .product-thumb a.add_to_cart_button,
.woo-type2 ul.products li.product .product-thumb a.button.product_type_simple,
.woo-type2 ul.products li.product .product-thumb a.button.product_type_variable,
.woo-type2 ul.products li.product .product-thumb a.added_to_cart.wc-forward,
.woo-type2 ul.products li.product .product-thumb a.add_to_wishlist,
.woo-type2 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a,
.woo-type2 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a,
.woo-type2.woocommerce ul.products li.product .onsale,
.woo-type2.woocommerce ul.products li.product:hover .onsale,
.woo-type2 .woocommerce ul.products li.product .onsale,
.woo-type2 .woocommerce ul.products li.product:hover .onsale,
.woo-type2.woocommerce ul.products li.product .out-of-stock,
.woo-type2.woocommerce ul.products li.product:hover .out-of-stock,
.woo-type2 .woocommerce ul.products li.product .out-of-stock,
.woo-type2 .woocommerce ul.products li.product:hover .out-of-stock,
.woo-type2.woocommerce span.onsale,
.woo-type2.woocommerce span.out-of-stock,
.woo-type2 .woocommerce span.onsale,
.woo-type2 .woocommerce span.out-of-stock,
.woo-type2 div.product .woocommerce-tabs ul.tabs li.active a {
  background-color: #b2dcaa;
}

.woo-type2 ul.products li.product .product-thumb a.add_to_cart_button:hover,
.woo-type2 ul.products li.product .product-thumb a.button.product_type_simple:hover,
.woo-type2 ul.products li.product .product-thumb a.button.product_type_variable:hover,
.woo-type2 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover,
.woo-type2 ul.products li.product .product-thumb a.add_to_wishlist:hover,
.woo-type2 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover,
.woo-type2 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover {
  background-color: rgba(178, 220, 170, 0.75);
}

.woo-type2 ul.products li.product .product-details h5 a:hover,
.woo-type2 ul.products li.product-category:hover .product-details h5,
.woo-type2 ul.products li.product-category:hover .product-details h5 .count {
  color: rgba(178, 220, 170, 0.6);
}

.woo-type2.woocommerce a.button:hover,
.woo-type2.woocommerce button.button:hover,
.woo-type2.woocommerce button:hover,
.woo-type2.woocommerce input.button:hover,
.woo-type2.woocommerce input[type=button]:hover,
.woo-type2.woocommerce input[type=submit]:hover,
.woo-type2.woocommerce #respond input#submit:hover,
.woo-type2.woocommerce a.button.alt:hover,
.woo-type2.woocommerce button.button.alt:hover,
.woo-type2.woocommerce input.button.alt:hover,
.woo-type2.woocommerce #respond input#submit.alt:hover,
.woo-type2 .woocommerce a.button:hover,
.woo-type2 .woocommerce button.button:hover,
.woo-type2 .woocommerce button:hover,
.woo-type2 .woocommerce input.button:hover,
.woo-type2 .woocommerce input[type=button]:hover,
.woo-type2 .woocommerce input[type=submit]:hover,
.woo-type2 .woocommerce #respond input#submit:hover,
.woo-type2 .woocommerce a.button.alt:hover,
.woo-type2 .woocommerce button.button.alt:hover,
.woo-type2 .woocommerce input.button.alt:hover,
.woo-type2 .woocommerce #respond input#submit.alt:hover,
.woo-type2 .product .summary .add_to_wishlist:hover,
.woo-type2 .wishlist_table .add_to_cart.button:hover,
.woo-type2 .yith-wcwl-add-button a.add_to_wishlist:hover,
.woo-type2 .yith-wcwl-popup-button a.add_to_wishlist:hover,
.woo-type2 .wishlist_table a.ask-an-estimate-button:hover,
.woo-type2 .wishlist-title a.show-title-form:hover,
.woo-type2 .hidden-title-form a.hide-title-form:hover,
.woo-type2 .yith-wcwl-wishlist-new button:hover,
.woo-type2 .wishlist_manage_table a.create-new-wishlist:hover,
.woo-type2 .wishlist_manage_table button.submit-wishlist-changes:hover,
.woo-type2 .yith-wcwl-wishlist-search-form button.wishlist-search-button:hover,
.woo-type2 .cart input.button:hover {
  background-color: #bedbb8;
}

.woo-type2 div.product .summary a,
.woo-type2 .shipping-calculator-button,
.woo-type2.woocommerce-checkout #payment ul.payment_methods li a:hover {
  color: #bedbb8;
}

.woo-type3 ul.products li.product .product-details h5:after {
  background-color: #b2dcaa;
}

.woo-type3 ul.products li.product-category:hover .product-details h5,
.woo-type3 ul.products li.product-category:hover .product-details h5 .count {
  color: #b2dcaa;
}

.woo-type3 ul.products li.product .product-thumb a.add_to_cart_button:hover,
.woo-type3 ul.products li.product .product-thumb a.button.product_type_simple:hover,
.woo-type3 ul.products li.product .product-thumb a.button.product_type_variable:hover,
.woo-type3 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover,
.woo-type3 ul.products li.product .product-thumb a.add_to_wishlist:hover,
.woo-type3 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover,
.woo-type3 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover {
  background-color: #95db87;
}

.woo-type4 ul.products li.product .product-thumb a.add_to_cart_button:after,
.woo-type4 ul.products li.product .product-thumb a.button.product_type_simple:after,
.woo-type4 ul.products li.product .product-thumb a.button.product_type_variable:after,
.woo-type4 ul.products li.product .product-thumb a.added_to_cart.wc-forward:after,
.woo-type4 ul.products li.product .product-thumb a.add_to_wishlist:after,
.woo-type4 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:after,
.woo-type4 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:after,
.woo-type4 ul.products li.product .product-details h5:after {
  background-color: #b2dcaa;
}

.woo-type4 ul.products li.product-category:hover .product-details h5,
.woo-type4 ul.products li.product-category:hover .product-details h5 .count {
  color: #b2dcaa;
}

.woo-type4 ul.products li.product .product-thumb a.add_to_cart_button:hover:after,
.woo-type4 ul.products li.product .product-thumb a.button.product_type_simple:hover:after,
.woo-type4 ul.products li.product .product-thumb a.button.product_type_variable:hover:after,
.woo-type4 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover:after,
.woo-type4 ul.products li.product .product-thumb a.add_to_wishlist:hover:after,
.woo-type4 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover:after,
.woo-type4 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover:after {
  background-color: #95db87;
}

.woo-type5 ul.products li.product .product-thumb a.add_to_cart_button,
.woo-type5 ul.products li.product .product-thumb a.button.product_type_simple,
.woo-type5 ul.products li.product .product-thumb a.button.product_type_variable,
.woo-type5 ul.products li.product .product-thumb a.added_to_cart.wc-forward,
.woo-type5 ul.products li.product .product-thumb a.add_to_wishlist,
.woo-type5 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a,
.woo-type5 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a,
.woo-type5 ul.products li.product .product-details h5:after {
  background-color: #b2dcaa;
}

.woo-type5 ul.products li.product-category:hover .product-details h5,
.woo-type5 ul.products li.product-category:hover .product-details h5 .count {
  color: #b2dcaa;
}

.woo-type5 ul.products li.product .product-thumb a.add_to_cart_button:hover,
.woo-type5 ul.products li.product .product-thumb a.button.product_type_simple:hover,
.woo-type5 ul.products li.product .product-thumb a.button.product_type_variable:hover,
.woo-type5 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover,
.woo-type5 ul.products li.product .product-thumb a.add_to_wishlist:hover,
.woo-type5 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover,
.woo-type5 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover {
  background-color: #95db87;
}

.woo-type6 ul.products li.product .price {
  background-color: #b2dcaa;
}

.woo-type6.woocommerce ul.products li.product:hover .product-content,
.woo-type6 .woocommerce ul.products li.product:hover .product-content,
.woo-type6.woocommerce ul.products li.product.instock:hover .on-sale-product .product-content,
.woo-type6 .woocommerce ul.products li.product.instock:hover .on-sale-product .product-content,
.woo-type6.woocommerce ul.products li.product.outofstock:hover .out-of-stock-product .product-content,
.woo-type6 .woocommerce ul.products li.product.outofstock:hover .out-of-stock-product .product-content,
.woo-type6.woocommerce ul.products li.product-category:hover .product-thumb .image:after,
.woo-type6 .woocommerce ul.products li.product-category:hover .product-thumb .image:after {
  background-color: rgba(178, 220, 170, 0.75);
}

.woo-type6 ul.products li.product .product-thumb a.add_to_cart_button:hover:before,
.woo-type6 ul.products li.product .product-thumb a.button.product_type_simple:hover:before,
.woo-type6 ul.products li.product .product-thumb a.button.product_type_variable:hover:before,
.woo-type6 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover:before,
.woo-type6 ul.products li.product .product-thumb a.add_to_wishlist:hover:before,
.woo-type6 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover:before,
.woo-type6 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover:before,
.woo-type6 ul.products li.product-category:hover .product-details h5,
.woo-type6 ul.products li.product-category:hover .product-details h5 .count {
  color: #b2dcaa;
}

.woo-type7 ul.products li.product .product-thumb a.add_to_cart_button,
.woo-type7 ul.products li.product .product-thumb a.button.product_type_simple,
.woo-type7 ul.products li.product .product-thumb a.button.product_type_variable,
.woo-type7 ul.products li.product .product-thumb a.added_to_cart.wc-forward,
.woo-type7 ul.products li.product .product-details,
.woo-type7 ul.products li.product:hover .product-details h5 {
  background-color: #b2dcaa;
}

.woo-type7 ul.products li.product .product-thumb a.add_to_wishlist,
.woo-type7 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a,
.woo-type7 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a,
.woo-type7 ul.products li.product:hover .product-details {
  background-color: #bedbb8;
}

.woo-type7 ul.products li.product .product-details {
  -webkit-box-shadow: 0 -2px 0 0 #bedbb8;
  -moz-box-shadow: 0 -2px 0 0 #bedbb8;
  -ms-box-shadow: 0 -2px 0 0 #bedbb8;
  -o-box-shadow: 0 -2px 0 0 #bedbb8;
  box-shadow: 0 -2px 0 0 #bedbb8;
}

.woo-type7 ul.products li.product .product-details h5 {
  background-color: #95db87;
}

.woo-type7 ul.products li.product:hover .product-details {
  -webkit-box-shadow: 0 -3px 0 0 #95db87 inset;
  -moz-box-shadow: 0 -3px 0 0 #95db87 inset;
  -ms-box-shadow: 0 -3px 0 0 #95db87 inset;
  -o-box-shadow: 0 -3px 0 0 #95db87 inset;
  box-shadow: 0 -3px 0 0 #95db87 inset;
}

.woo-type8 ul.products li.product .product-details,
.woo-type8 ul.products li.product:hover .product-details h5:before {
  background-color: #b2dcaa;
}

.woo-type8.woocommerce ul.products li.product:hover .product-content,
.woo-type8.woocommerce ul.products li.product-category:hover .product-thumb .image:after,
.woo-type8 .woocommerce ul.products li.product:hover .product-content,
.woo-type8 .woocommerce ul.products li.product-category:hover .product-thumb .image:after {
  background-color: rgba(178, 220, 170, 0.75);
}

.woo-type8.woocommerce ul.products li.product:hover .product-content:after,
.woo-type8 .woocommerce ul.products li.product:hover .product-content:after {
  border-color: rgba(178, 220, 170, 0.75) rgba(178, 220, 170, 0.75) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.35);
}

.woo-type8 ul.products li.product .product-thumb a.add_to_cart_button:hover:before,
.woo-type8 ul.products li.product .product-thumb a.button.product_type_simple:hover:before,
.woo-type8 ul.products li.product .product-thumb a.button.product_type_variable:hover:before,
.woo-type8 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover:before,
.woo-type8 ul.products li.product .product-thumb a.add_to_wishlist:hover:before,
.woo-type8 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover:before,
.woo-type8 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover:before,
.woo-type8 ul.products li.product:hover .product-details h5 a:hover {
  color: #b2dcaa;
}

.woo-type8 ul.products li.product:hover .product-wrapper {
  border-color: #95db87;
}

.woo-type8 ul.products li.product:hover .product-details h5:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #95db87 rgba(0, 0, 0, 0);
}

.woo-type9 ul.products li.product .product-thumb a.add_to_cart_button:hover:after,
.woo-type9 ul.products li.product .product-thumb a.button.product_type_simple:hover:after,
.woo-type9 ul.products li.product .product-thumb a.button.product_type_variable:hover:after,
.woo-type9 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover:after,
.woo-type9 ul.products li.product .product-thumb a.add_to_wishlist:hover:after,
.woo-type9 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover:after,
.woo-type9 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover:after {
  background-color: #b2dcaa;
}

.woo-type9 ul.products li.product .product-details {
  background-color: #b2dcaa;
}

.woo-type9.woocommerce ul.products li.product .product-wrapper,
.woo-type9 .woocommerce ul.products li.product .product-wrapper {
  border-color: rgba(178, 220, 170, 0.6);
}

.woo-type9 ul.products li.product .product-thumb a.add_to_cart_button:after,
.woo-type9 ul.products li.product .product-thumb a.button.product_type_simple:after,
.woo-type9 ul.products li.product .product-thumb a.button.product_type_variable:after,
.woo-type9 ul.products li.product .product-thumb a.added_to_cart.wc-forward:after,
.woo-type9 ul.products li.product .product-thumb a.add_to_wishlist:after,
.woo-type9 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:after,
.woo-type9 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:after,
.woo-type9 ul.products li.product:hover .product-details {
  background-color: #95db87;
}

.woo-type9 ul.products li.product:hover .product-details h5:after {
  border-color: #95db87;
}

.woo-type9 ul.products li.product:hover .product-wrapper {
  border-color: rgba(149, 219, 135, 0.75);
}

.woo-type10 ul.products li.product .product-thumb a.add_to_cart_button,
.woo-type10 ul.products li.product .product-thumb a.button.product_type_simple,
.woo-type10 ul.products li.product .product-thumb a.button.product_type_variable,
.woo-type10 ul.products li.product .product-thumb a.added_to_cart.wc-forward,
.woo-type10 ul.products li.product .product-thumb a.add_to_wishlist,
.woo-type10 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a,
.woo-type10 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a,
.woo-type10 ul.products li.product:hover .product-details {
  background-color: #b2dcaa;
}

.woo-type10 ul.products li.product:hover .product-wrapper {
  border-color: #b2dcaa;
}

.woo-type10 ul.products li.product:hover .product-details:before,
.woo-type10 ul.products li.product:hover .product-details:after {
  border-bottom-color: #b2dcaa;
}

.woo-type10 ul.products li.product .product-thumb a.add_to_cart_button:hover,
.woo-type10 ul.products li.product .product-thumb a.button.product_type_simple:hover,
.woo-type10 ul.products li.product .product-thumb a.button.product_type_variable:hover,
.woo-type10 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover,
.woo-type10 ul.products li.product .product-thumb a.add_to_wishlist:hover,
.woo-type10 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover,
.woo-type10 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover {
  background-color: #95db87;
}

.woo-type10.woocommerce ul.products li.product .featured-tag,
.woo-type10 .woocommerce ul.products li.product .featured-tag,
.woo-type10.woocommerce.single-product .featured-tag {
  background-color: #95db87;
}

.woo-type10 ul.products li.product .featured-tag:after,
.woo-type10 ul.products li.product:hover .featured-tag:after,
.woo-type10.woocommerce.single-product .featured-tag:after {
  border-color: #95db87;
}

.woo-type11 ul.products li.product .product-thumb a.add_to_cart_button:hover,
.woo-type11 ul.products li.product .product-thumb a.button.product_type_simple:hover,
.woo-type11 ul.products li.product .product-thumb a.button.product_type_variable:hover,
.woo-type11 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover,
.woo-type11 ul.products li.product .product-thumb a.add_to_wishlist:hover,
.woo-type11 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover,
.woo-type11 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover,
.woo-type11.woocommerce div.product .woocommerce-tabs ul.tabs li.active a:after {
  background-color: #b2dcaa;
}

.woo-type11 ul.products li.product .product-details {
  background-color: #b2dcaa;
}

.woo-type11 ul.products li.product:hover .product-wrapper {
  -webkit-box-shadow: 0 0 0 3px #b2dcaa;
  -moz-box-shadow: 0 0 0 3px #b2dcaa;
  -ms-box-shadow: 0 0 0 3px #b2dcaa;
  -o-box-shadow: 0 0 0 3px #b2dcaa;
  box-shadow: 0 0 0 3px #b2dcaa;
}

.woo-type11 ul.products li.product .product-thumb a.add_to_cart_button:before,
.woo-type11 ul.products li.product .product-thumb a.button.product_type_simple:before,
.woo-type11 ul.products li.product .product-thumb a.button.product_type_variable:before,
.woo-type11 ul.products li.product .product-thumb a.added_to_cart.wc-forward:before,
.woo-type11 ul.products li.product .product-thumb a.add_to_wishlist:before,
.woo-type11 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:before,
.woo-type11 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:before {
  color: #b2dcaa;
}

.woo-type11 ul.products li.product .product-thumb a.add_to_cart_button,
.woo-type11 ul.products li.product .product-thumb a.button.product_type_simple,
.woo-type11 ul.products li.product .product-thumb a.button.product_type_variable,
.woo-type11 ul.products li.product .product-thumb a.added_to_cart.wc-forward,
.woo-type11 ul.products li.product .product-thumb a.add_to_wishlist,
.woo-type11 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a,
.woo-type11 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a,
.woo-type11 ul.products li.product:hover .product-wrapper:before,
.woo-type11 ul.products li.product:hover .product-wrapper:after,
.woo-type11.woocommerce ul.products li.product .product-thumb,
.woo-type11 .woocommerce ul.products li.product .product-thumb,
.woo-type11 ul.products li.product-category a img {
  border-color: #95db87;
}

.woo-type12 ul.products li.product .product-thumb a.add_to_cart_button,
.woo-type12 ul.products li.product .product-thumb a.button.product_type_simple,
.woo-type12 ul.products li.product .product-thumb a.button.product_type_variable,
.woo-type12 ul.products li.product .product-thumb a.added_to_cart.wc-forward,
.woo-type12 ul.products li.product .product-thumb a.add_to_wishlist,
.woo-type12 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a,
.woo-type12 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a,
.woo-type12 ul.products li.product:hover .product-details,
.woo-type12 ul.products li.product .product-details h5:after {
  background-color: #b2dcaa;
}

.woo-type12 ul.products li.product .product-details {
  -webkit-box-shadow: 0 -3px 0 0 #b2dcaa inset;
  -moz-box-shadow: 0 -3px 0 0 #b2dcaa inset;
  -ms-box-shadow: 0 -3px 0 0 #b2dcaa inset;
  -o-box-shadow: 0 -3px 0 0 #b2dcaa inset;
  box-shadow: 0 -3px 0 0 #b2dcaa inset;
}

.woo-type12 ul.products li.product .product-thumb a.add_to_cart_button:hover,
.woo-type12 ul.products li.product .product-thumb a.button.product_type_simple:hover,
.woo-type12 ul.products li.product .product-thumb a.button.product_type_variable:hover,
.woo-type12 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover,
.woo-type12 ul.products li.product .product-thumb a.add_to_wishlist:hover,
.woo-type12 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover,
.woo-type12 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover,
.woo-type12 ul.products li.product:hover .product-details h5:after {
  background-color: #95db87;
}

.woo-type12 ul.products li.product:hover .product-wrapper {
  border-color: #95db87;
}

.woo-type13 ul.products li.product .product-details h5:before {
  background-color: #b2dcaa;
}

.woo-type13.woocommerce ul.products li.product:hover .product-content,
.woo-type13 .woocommerce ul.products li.product:hover .product-content,
.woo-type13.woocommerce ul.products li.product.instock:hover .on-sale-product .product-content,
.woo-type13 .woocommerce ul.products li.product.instock:hover .on-sale-product .product-content,
.woo-type13.woocommerce ul.products li.product.outofstock:hover .out-of-stock-product .product-content,
.woo-type13 .woocommerce ul.products li.product.outofstock:hover .out-of-stock-product .product-content,
.woo-type13.woocommerce ul.products li.product-category:hover .product-thumb .image:after,
.woo-type13 .woocommerce ul.products li.product-category:hover .product-thumb .image:after {
  background-color: rgba(178, 220, 170, 0.75);
}

.woo-type13 ul.products li.product .product-thumb a.add_to_cart_button:hover:before,
.woo-type13 ul.products li.product .product-thumb a.button.product_type_simple:hover:before,
.woo-type13 ul.products li.product .product-thumb a.button.product_type_variable:hover:before,
.woo-type13 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover:before,
.woo-type13 ul.products li.product .product-thumb a.add_to_wishlist:hover:before,
.woo-type13 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover:before,
.woo-type13 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover:before,
.woo-type13 ul.products li.product:hover .product-details h5 a,
.woo-type13 ul.products li.product-category:hover .product-details h5,
.woo-type13 ul.products li.product-category:hover .product-details h5 .count {
  color: #b2dcaa;
}

.woo-type14 ul.products li.product:hover .product-details,
.woo-type14 ul.products li.product .product-details h5:before,
.woo-type14 ul.products li.product:hover .product-details h5:after {
  background-color: #b2dcaa;
}

.woo-type14 ul.products li.product:hover .product-details h5:after {
  border-color: #b2dcaa;
}

.woo-type14 ul.products li.product .product-details,
.woo-type14 ul.products li.product .product-details h5:after {
  -webkit-box-shadow: 0 0 0 2px #b2dcaa inset;
  -moz-box-shadow: 0 0 0 2px #b2dcaa inset;
  -ms-box-shadow: 0 0 0 2px #b2dcaa inset;
  -o-box-shadow: 0 0 0 2px #b2dcaa inset;
  box-shadow: 0 0 0 2px #b2dcaa inset;
}

.woo-type14 ul.products li.product .product-thumb a.add_to_cart_button:hover,
.woo-type14 ul.products li.product .product-thumb a.button.product_type_simple:hover,
.woo-type14 ul.products li.product .product-thumb a.button.product_type_variable:hover,
.woo-type14 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover,
.woo-type14 ul.products li.product .product-thumb a.add_to_wishlist:hover,
.woo-type14 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover,
.woo-type14 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover {
  background-color: #95db87;
}

.woo-type15 ul.products li.product .product-thumb a.add_to_cart_button:after,
.woo-type15 ul.products li.product .product-thumb a.button.product_type_simple:after,
.woo-type15 ul.products li.product .product-thumb a.button.product_type_variable:after,
.woo-type15 ul.products li.product .product-thumb a.added_to_cart.wc-forward:after,
.woo-type15 ul.products li.product .product-thumb a.add_to_wishlist:after,
.woo-type15 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:after,
.woo-type15 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:after,
.woo-type15 ul.products li.product .price:after {
  background-color: #b2dcaa;
}

.woo-type15 ul.products li.product:hover .product-wrapper {
  border-color: #b2dcaa;
}

.woo-type15 ul.products li.product .product-thumb a.add_to_cart_button:after,
.woo-type15 ul.products li.product .product-thumb a.button.product_type_simple:after,
.woo-type15 ul.products li.product .product-thumb a.button.product_type_variable:after,
.woo-type15 ul.products li.product .product-thumb a.added_to_cart.wc-forward:after,
.woo-type15 ul.products li.product .product-thumb a.add_to_wishlist:after,
.woo-type15 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:after,
.woo-type15 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:after {
  -webkit-box-shadow: 0 0 0 2px #b2dcaa;
  -moz-box-shadow: 0 0 0 2px #b2dcaa;
  -ms-box-shadow: 0 0 0 2px #b2dcaa;
  -o-box-shadow: 0 0 0 2px #b2dcaa;
  box-shadow: 0 0 0 2px #b2dcaa;
}

.woo-type15 ul.products li.product .product-thumb a.add_to_cart_button:hover:after,
.woo-type15 ul.products li.product .product-thumb a.button.product_type_simple:hover:after,
.woo-type15 ul.products li.product .product-thumb a.button.product_type_variable:hover:after,
.woo-type15 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover:after,
.woo-type15 ul.products li.product .product-thumb a.add_to_wishlist:hover:after,
.woo-type15 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover:after,
.woo-type15 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover:after,
.woo-type15.woocommerce ul.products li.product .featured-tag,
.woo-type15 .woocommerce ul.products li.product .featured-tag,
.woo-type15.woocommerce.single-product .featured-tag {
  background-color: #95db87;
}

.woo-type15.woocommerce ul.products li.product .featured-tag:after,
.woo-type15.woocommerce ul.products li.product:hover .featured-tag:after,
.woo-type15 .woocommerce ul.products li.product .featured-tag:after,
.woo-type15 .woocommerce ul.products li.product:hover .featured-tag:after,
.woo-type15.woocommerce.single-product .featured-tag:after {
  border-color: #95db87;
}

.woo-type16 ul.products li.product .product-wrapper,
.woo-type16 ul.products li.product .product-thumb a.add_to_cart_button:hover,
.woo-type16 ul.products li.product .product-thumb a.button.product_type_simple:hover,
.woo-type16 ul.products li.product .product-thumb a.button.product_type_variable:hover,
.woo-type16 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover,
.woo-type16 ul.products li.product .product-thumb a.add_to_wishlist:hover,
.woo-type16 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover,
.woo-type16 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover,
.woo-type16.woocommerce .shop_table th,
.woo-type16 .woocommerce .shop_table th,
.woo-type16.woocommerce div.product .woocommerce-tabs ul.tabs li.active a:after {
  background-color: #b2dcaa;
}

.woo-type17 ul.products li.product .product-thumb a.add_to_cart_button:hover:after,
.woo-type17 ul.products li.product .product-thumb a.button.product_type_simple:hover:after,
.woo-type17 ul.products li.product .product-thumb a.button.product_type_variable:hover:after,
.woo-type17 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover:after,
.woo-type17 ul.products li.product .product-thumb a.add_to_wishlist:hover:after,
.woo-type17 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover:after,
.woo-type17 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover:after,
.woo-type17 ul.products li.product:hover .product-details {
  background-color: #b2dcaa;
}

.woo-type17 ul.products li.product:hover .product-wrapper,
.woo-type17 ul.products li.product:hover .product-thumb a.add_to_cart_button:after,
.woo-type17 ul.products li.product:hover .product-thumb a.button.product_type_simple:after,
.woo-type17 ul.products li.product:hover .product-thumb a.button.product_type_variable:after,
.woo-type17 ul.products li.product:hover .product-thumb a.added_to_cart.wc-forward:after,
.woo-type17 ul.products li.product:hover .product-thumb a.add_to_wishlist:after,
.woo-type17 ul.products li.product:hover .product-thumb .yith-wcwl-wishlistaddedbrowse a:after,
.woo-type17 ul.products li.product:hover .product-thumb .yith-wcwl-wishlistexistsbrowse a:after,
.woo-type17 ul.products li.product .product-details h5 a:after,
.woo-type17 ul.products li.product-category .product-details h5:after,
.woo-type17 ul.products li.product .price {
  border-color: #b2dcaa;
}

.woo-type17 ul.products li.product .product-thumb a.add_to_cart_button,
.woo-type17 ul.products li.product .product-thumb a.button.product_type_simple,
.woo-type17 ul.products li.product .product-thumb a.button.product_type_variable,
.woo-type17 ul.products li.product .product-thumb a.added_to_cart.wc-forward,
.woo-type17 ul.products li.product .product-thumb a.add_to_wishlist,
.woo-type17 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a,
.woo-type17 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a,
.woo-type17 ul.products li.product .product-thumb a.add_to_cart_button:before,
.woo-type17 ul.products li.product .product-thumb a.button.product_type_simple:before,
.woo-type17 ul.products li.product .product-thumb a.button.product_type_variable:before,
.woo-type17 ul.products li.product .product-thumb a.added_to_cart.wc-forward:before,
.woo-type17 ul.products li.product .product-thumb a.add_to_wishlist:before,
.woo-type17 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:before,
.woo-type17 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:before,
.woo-type17 ul.products li.product .product-details h5 a,
.woo-type17 ul.products li.product-category .product-details h5,
.woo-type17 ul.products li.product-category .product-details h5 .count,
.woo-type17 ul.products li.product .product-details .product-price .amount,
.woo-type17 ul.products li.product .product-details span.price,
.woo-type17 ul.products li.product .product-details span.price del,
.woo-type17 ul.products li.product .product-details span.price del .amount,
.woo-type17 ul.products li.product .product-details span.price ins,
.woo-type17 ul.products li.product .product-details span.price ins .amount,
.woo-type17 .widget.woocommerce ul li:hover:before {
  color: #b2dcaa;
}

.woo-type17.woocommerce ul.products li.product .featured-tag,
.woo-type17 .woocommerce ul.products li.product .featured-tag,
.woo-type17.woocommerce.single-product .featured-tag {
  background-color: #95db87;
}

.woo-type17 ul.products li.product .featured-tag:after,
.woo-type17 ul.products li.product:hover .featured-tag:after,
.woo-type17.woocommerce.single-product .featured-tag:after {
  border-color: #95db87;
}

.woo-type18 ul.products li.product .product-thumb a.add_to_cart_button,
.woo-type18 ul.products li.product .product-thumb a.button.product_type_simple,
.woo-type18 ul.products li.product .product-thumb a.button.product_type_variable,
.woo-type18 ul.products li.product .product-thumb a.added_to_cart.wc-forward,
.woo-type18 ul.products li.product .product-thumb a.add_to_wishlist,
.woo-type18 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a,
.woo-type18 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a {
  background-color: #b2dcaa;
}

.woo-type18 ul.products li.product:hover .product-content,
.woo-type18 ul.products li.product.instock:hover .on-sale-product .product-content,
.woo-type18 ul.products li.product.outofstock:hover .out-of-stock-product .product-content,
.woo-type18.woocommerce ul.products li.product:hover .product-content,
.woo-type18.woocommerce ul.products li.product.instock:hover .on-sale-product .product-content,
.woo-type18.woocommerce ul.products li.product.outofstock:hover .out-of-stock-product .product-content,
.woo-type18.woocommerce-page ul.products li.product:hover .product-content,
.woo-type18.woocommerce-page ul.products li.product.instock:hover .on-sale-product .product-content,
.woo-type18.woocommerce-page ul.products li.product.outofstock:hover .out-of-stock-product .product-content {
  background-color: rgba(190, 219, 184, 0.6);
}

.woo-type18 ul.products li.product:hover .product-wrapper:after {
  border-color: #bedbb8;
}

.woo-type18 ul.products li.product:hover .product-details h5 a:hover,
.woo-type18.woocommerce-checkout #payment ul.payment_methods li a:hover {
  color: #bedbb8;
}

.woo-type18 ul.products li.product .product-thumb a.add_to_cart_button:hover,
.woo-type18 ul.products li.product .product-thumb a.button.product_type_simple:hover,
.woo-type18 ul.products li.product .product-thumb a.button.product_type_variable:hover,
.woo-type18 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover,
.woo-type18 ul.products li.product .product-thumb a.add_to_wishlist:hover,
.woo-type18 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover,
.woo-type18 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover {
  background-color: #95db87;
}

.woo-type18 ul.products li.product:hover .product-details h5 {
  border-color: #95db87;
}

.woo-type18 ul.products li.product:hover .product-details h5 a,
.woo-type18 ul.products li.product-category:hover .product-details h5,
.woo-type18 ul.products li.product-category:hover .product-details h5 .count,
.woo-type18 ul.products li.product:hover .product-details .product-price .amount,
.woo-type18 ul.products li.product:hover .product-details span.price,
.woo-type18 ul.products li.product:hover .product-details span.price del,
.woo-type18 ul.products li.product:hover .product-details span.price del .amount,
.woo-type18 ul.products li.product:hover .product-details span.price ins,
.woo-type18 ul.products li.product:hover .product-details span.price ins .amount,
.woo-type18 ul.products li.product .product-details .product-rating-wrapper .star-rating:before,
.woo-type18 ul.products li.product .product-details .product-rating-wrapper .star-rating span:before {
  color: #95db87;
}

.woo-type19 ul.products li.product:hover .product-wrapper,
.woo-type19 ul.products li.product:hover .product-details {
  background-color: #b2dcaa;
}

.woo-type19 ul.products li.product .product-thumb a.add_to_cart_button:hover:before,
.woo-type19 ul.products li.product .product-thumb a.button.product_type_simple:hover:before,
.woo-type19 ul.products li.product .product-thumb a.button.product_type_variable:hover:before,
.woo-type19 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover:before,
.woo-type19 ul.products li.product .product-thumb a.add_to_wishlist:hover:before,
.woo-type19 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover:before,
.woo-type19 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover:before {
  color: #b2dcaa;
}

.woo-type19.woocommerce ul.products li.product:hover .product-content,
.woo-type19 .woocommerce ul.products li.product:hover .product-content,
.woo-type19.woocommerce ul.products li.product.instock:hover .on-sale-product .product-content,
.woo-type19 .woocommerce ul.products li.product.instock:hover .on-sale-product .product-content,
.woo-type19.woocommerce ul.products li.product.outofstock:hover .out-of-stock-product .product-content,
.woo-type19 .woocommerce ul.products li.product.outofstock:hover .out-of-stock-product .product-content {
  background-color: rgba(190, 219, 184, 0.45);
}

.woo-type19 ul.products li.product:hover .product-thumb .image {
  -webkit-box-shadow: 0 0 1px 4px #95db87;
  -moz-box-shadow: 0 0 1px 4px #95db87;
  -ms-box-shadow: 0 0 1px 4px #95db87;
  -o-box-shadow: 0 0 1px 4px #95db87;
  box-shadow: 0 0 1px 4px #95db87;
}

.woo-type20 ul.products li.product .product-thumb a.add_to_cart_button,
.woo-type20 ul.products li.product .product-thumb a.button.product_type_simple,
.woo-type20 ul.products li.product .product-thumb a.button.product_type_variable,
.woo-type20 ul.products li.product .product-thumb a.added_to_cart.wc-forward,
.woo-type20 ul.products li.product .product-thumb a.add_to_wishlist,
.woo-type20 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a,
.woo-type20 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a,
.woo-type20 ul.products li.product .product-wrapper:after,
.woo-type20.woocommerce ul.products li.product .product-details h5,
.woo-type20 .woocommerce ul.products li.product .product-details h5,
.woo-type20 ul.products li.product-category .product-wrapper h3 {
  border-color: #b2dcaa;
}

.woo-type20 ul.products li.product .product-thumb a.add_to_cart_button:before,
.woo-type20 ul.products li.product .product-thumb a.button.product_type_simple:before,
.woo-type20 ul.products li.product .product-thumb a.button.product_type_variable:before,
.woo-type20 ul.products li.product .product-thumb a.added_to_cart.wc-forward:before,
.woo-type20 ul.products li.product .product-thumb a.add_to_wishlist:before,
.woo-type20 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:before,
.woo-type20 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:before,
.woo-type20 ul.products li.product .product-details h5 a,
.woo-type20 ul.products li.product-category .product-details h5,
.woo-type20 ul.products li.product-category .product-details h5 .count,
.woo-type20 ul.products li.product .product-details .product-price .amount,
.woo-type20 ul.products li.product .product-details span.price,
.woo-type20 ul.products li.product .product-details span.price del,
.woo-type20 ul.products li.product .product-details span.price del .amount,
.woo-type20 ul.products li.product .product-details span.price ins,
.woo-type20 ul.products li.product .product-details span.price ins .amount,
.woo-type20 ul.products li.product .product-details .product-rating-wrapper .star-rating:before,
.woo-type20 ul.products li.product .product-details .product-rating-wrapper .star-rating span:before {
  color: #b2dcaa;
}

.woo-type20.woocommerce ul.products li.product:hover .product-content,
.woo-type20 .woocommerce ul.products li.product:hover .product-content,
.woo-type20.woocommerce ul.products li.product.instock:hover .on-sale-product .product-content,
.woo-type20 .woocommerce ul.products li.product.instock:hover .on-sale-product .product-content,
.woo-type20.woocommerce ul.products li.product.outofstock:hover .out-of-stock-product .product-content,
.woo-type20 .woocommerce ul.products li.product.outofstock:hover .out-of-stock-product .product-content {
  background-color: rgba(190, 219, 184, 0.3);
}

.woo-type20 ul.products li.product .product-wrapper {
  -webkit-box-shadow: 0 0 0 5px rgba(190, 219, 184, 0.75) inset;
  -moz-box-shadow: 0 0 0 5px rgba(190, 219, 184, 0.75) inset;
  -ms-box-shadow: 0 0 0 5px rgba(190, 219, 184, 0.75) inset;
  -o-box-shadow: 0 0 0 5px rgba(190, 219, 184, 0.75) inset;
  box-shadow: 0 0 0 5px rgba(190, 219, 184, 0.75) inset;
}

.woo-type20.woocommerce .shop_table th,
.woo-type20 .woocommerce .shop_table th,
.woo-type20.woocommerce div.product .woocommerce-tabs ul.tabs li.active a:after {
  background-color: #95db87;
}

.woo-type20 ul.products li.product .product-thumb a.add_to_cart_button:hover,
.woo-type20 ul.products li.product .product-thumb a.button.product_type_simple:hover,
.woo-type20 ul.products li.product .product-thumb a.button.product_type_variable:hover,
.woo-type20 ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover,
.woo-type20 ul.products li.product .product-thumb a.add_to_wishlist:hover,
.woo-type20 ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover,
.woo-type20 ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover,
.woo-type20 ul.products li.product:hover .product-wrapper {
  background-color: rgba(149, 219, 135, 0.5);
}

.woo-type20 ul.products li.product:hover .product-wrapper:after,
.woo-type20 div.product div.images img {
  border-color: #95db87;
}

.woo-type20.woocommerce-checkout #payment ul.payment_methods li a:hover {
  color: #95db87;
}

.woo-type21 .woocommerce ul.products li.product .product-thumb a.add_to_cart_button:hover,
.woo-type21 .woocommerce ul.products li.product .product-thumb a.button.product_type_simple:hover,
.woo-type21 .woocommerce ul.products li.product .product-thumb a.button.product_type_variable:hover,
.woo-type21 .woocommerce ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover,
.woo-type21 .woocommerce ul.products li.product .product-thumb a.add_to_wishlist:hover,
.woo-type21 .woocommerce ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover,
.woo-type21 .woocommerce ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover,
.woo-type21.woocommerce ul.products li.product .product-thumb a.add_to_cart_button:hover,
.woo-type21.woocommerce ul.products li.product .product-thumb a.button.product_type_simple:hover,
.woo-type21.woocommerce ul.products li.product .product-thumb a.button.product_type_variable:hover,
.woo-type21.woocommerce ul.products li.product .product-thumb a.added_to_cart.wc-forward:hover,
.woo-type21.woocommerce ul.products li.product .product-thumb a.add_to_wishlist:hover,
.woo-type21.woocommerce ul.products li.product .product-thumb .yith-wcwl-wishlistaddedbrowse a:hover,
.woo-type21.woocommerce ul.products li.product .product-thumb .yith-wcwl-wishlistexistsbrowse a:hover,
.woo-type21 .woocommerce a.button:hover,
.woo-type21 .woocommerce button.button:hover,
.woo-type21 .woocommerce button:hover,
.woo-type21 .woocommerce input.button:hover,
.woo-type21 .woocommerce input[type=button]:hover,
.woo-type21 .woocommerce input[type=submit]:hover,
.woo-type21 .woocommerce #respond input#submit:hover,
.woo-type21 .woocommerce a.button.alt:hover,
.woo-type21 .woocommerce button.button.alt:hover,
.woo-type21 .woocommerce input.button.alt:hover,
.woo-type21 .woocommerce #respond input#submit.alt:hover,
.woo-type21 .woocommerce .product .summary .add_to_wishlist:hover,
.woo-type21 .woocommerce .wishlist_table .add_to_cart.button:hover,
.woo-type21 .woocommerce .yith-wcwl-add-button a.add_to_wishlist:hover,
.woo-type21 .woocommerce .yith-wcwl-popup-button a.add_to_wishlist:hover,
.woo-type21 .woocommerce .wishlist_table a.ask-an-estimate-button:hover,
.woo-type21 .woocommerce .wishlist-title a.show-title-form:hover,
.woo-type21 .woocommerce .hidden-title-form a.hide-title-form:hover,
.woo-type21 .woocommerce .yith-wcwl-wishlist-new button:hover,
.woo-type21 .woocommerce .wishlist_manage_table a.create-new-wishlist:hover,
.woo-type21 .woocommerce .wishlist_manage_table button.submit-wishlist-changes:hover,
.woo-type21 .woocommerce .yith-wcwl-wishlist-search-form button.wishlist-search-button:hover,
.woo-type21 .woocommerce .cart input.button:hover,
.woo-type21.woocommerce a.button:hover,
.woo-type21.woocommerce button.button:hover,
.woo-type21.woocommerce button:hover,
.woo-type21.woocommerce input.button:hover,
.woo-type21.woocommerce input[type=button]:hover,
.woo-type21.woocommerce input[type=submit]:hover,
.woo-type21.woocommerce #respond input#submit:hover,
.woo-type21.woocommerce a.button.alt:hover,
.woo-type21.woocommerce button.button.alt:hover,
.woo-type21.woocommerce input.button.alt:hover,
.woo-type21.woocommerce #respond input#submit.alt:hover,
.woo-type21.woocommerce .product .summary .add_to_wishlist:hover,
.woo-type21.woocommerce .wishlist_table .add_to_cart.button:hover,
.woo-type21.woocommerce .yith-wcwl-add-button a.add_to_wishlist:hover,
.woo-type21.woocommerce .yith-wcwl-popup-button a.add_to_wishlist:hover,
.woo-type21.woocommerce .wishlist_table a.ask-an-estimate-button:hover,
.woo-type21.woocommerce .wishlist-title a.show-title-form:hover,
.woo-type21.woocommerce .hidden-title-form a.hide-title-form:hover,
.woo-type21.woocommerce .yith-wcwl-wishlist-new button:hover,
.woo-type21.woocommerce .wishlist_manage_table a.create-new-wishlist:hover,
.woo-type21.woocommerce .wishlist_manage_table button.submit-wishlist-changes:hover,
.woo-type21.woocommerce .yith-wcwl-wishlist-search-form button.wishlist-search-button:hover,
.woo-type21.woocommerce .cart input.button:hover,
.woo-type21 .woocommerce .product .summary .add_to_wishlist:hover:before,
.woo-type21.woocommerce .product .summary .add_to_wishlist:hover:before {
  background-color: #b2dcaa;
}

.woo-type21 .woocommerce .product .summary .add_to_wishlist:hover,
.woo-type21.woocommerce .product .summary .add_to_wishlist:hover {
  color: #b2dcaa;
}

.widget.buddypress div.item-options a:hover,
.widget.buddypress div.item-options a.selected,
#footer .footer-widgets.dt-sc-dark-bg .widget.buddypress div.item-options a.selected,
.widget.widget_bp_core_members_widget div.item .item-title a:hover,
.widget.buddypress .bp-login-widget-user-links>div.bp-login-widget-user-link a:hover {
  color: #b2dcaa;
}

.woocommerce .widget_price_filter .price_slider_wrapper .ui-widget-content {
  background: #b2dcaa;
}

.widget.tribe-events-countdown-widget .tribe-countdown-text a:hover {
  color: #b2dcaa;
}

.widget.tribe_mini_calendar_widget .tribe-mini-calendar thead.tribe-mini-calendar-nav td {
  background: #b2dcaa;
}

.widget.tribe_mini_calendar_widget .tribe-mini-calendar thead.tribe-mini-calendar-nav td {
  border-color: #b2dcaa;
}

.widget.tribe_mini_calendar_widget .tribe-mini-calendar .tribe-events-present,
.widget.tribe_mini_calendar_widget .tribe-mini-calendar .tribe-events-has-events.tribe-mini-calendar-today,
.tribe-mini-calendar .tribe-events-has-events.tribe-events-present a:hover,
.widget.tribe_mini_calendar_widget .tribe-mini-calendar td.tribe-events-has-events.tribe-mini-calendar-today a:hover {
  background: #b2dcaa;
}

.dt-sc-dark-bg .widget.tribe_mini_calendar_widget .tribe-mini-calendar .tribe-events-present,
.dt-sc-dark-bg .widget.tribe_mini_calendar_widget .tribe-mini-calendar .tribe-events-has-events.tribe-mini-calendar-today,
.dt-sc-dark-bg .tribe-mini-calendar .tribe-events-has-events.tribe-events-present a:hover,
.dt-sc-dark-bg .widget.tribe_mini_calendar_widget .tribe-mini-calendar td.tribe-events-has-events.tribe-mini-calendar-today a:hover {
  background: #b2dcaa;
}

.dt-sc-attorney-sorting a.active-sort,
.dt-sc-attorneys-single h5.dt-sc-attorney-role:before,
.dt-sc-attorneys-single h5.dt-sc-attorney-role:after,
.dt-sc-attorney .dt-sc-attorney-details h5:before,
.dt-sc-attorney .dt-sc-attorney-details h5:after,
.widget.widget_attroney .widgettitle:before,
.attorney-contact-form-widget input[type="submit"]:hover,
.dt-sc-practices-list li a:hover,
.dt-sc-attorney-location-overlay p:last-child a {
  background: #b2dcaa;
}

.dt-sc-attorney-sorting a:hover,
.dt-sc-attorneys-single h6,
.dt-sc-attorney .dt-sc-attorney-details h6,
.dt-sc-attorneys-single a.read-more:hover,
.dt-sc-attorney .dt-sc-attorney-details a.read-more:hover,
.widget.widget_attroney .widgettitle,
.widget.widget_attorney_practice .attorney-practice-widget ul li a:hover,
.attorney-contact-form-widget input[type="submit"],
.dt-sc-attorney-location-overlay p a:hover,
.dt-sc-loading:before {
  color: #b2dcaa;
}

.dt-sc-attorney-location-overlay:before {
  border-top-color: #b2dcaa;
}

.dt-sc-chef-single-image-overlay .dt-sc-sociable li a,
.dt-sc-chef-single-image-overlay .dt-sc-chef-single-special p a:before,
.dt-sc-menu .menu-categories a:before {
  background: #b2dcaa;
}

.dt-sc-menu-sorting a.active-sort,
.dt-sc-menu .image-overlay .price {
  border-color: #b2dcaa;
}

.dt-sc-restaurant-events-list .dt-sc-restaurant-event-details h6:before {
  border-bottom-color: rgba(178, 220, 170, 0.6);
}

.dt-sc-menu-sorting a {
  color: rgba(178, 220, 170, 0.6);
}

.dt-sc-chef .dt-sc-chef-details p,
.dt-sc-chef .dt-sc-chef-details h6,
.dt-sc-chef .dt-sc-chef-details .dt-sc-chef-category a:hover,
.dt-sc-chef .dt-sc-chef-details .dt-sc-button:hover,
.dt-sc-chef-single-details .dt-sc-chef-single-likes p a:hover,
.dt-sc-chef-single-image-overlay .dt-sc-chef-single-special h6,
.dt-sc-chef-single-image-overlay .dt-sc-sociable li a:hover,
.dt-sc-chef-single-image-overlay .dt-sc-chef-single-special p a:hover,
.dt-sc-menu .image-overlay .price,
.dt-sc-menu .image-overlay h6 a:hover,
.dt-sc-menu .menu-categories a:hover,
.dt-sc-menu-sorting a:hover,
.dt-sc-menu-sorting a.active-sort,
.dt-sc-menu .dt-sc-menu-details .dt-sc-menu-price,
.dt-sc-restaurant-events-list .dt-sc-restaurant-event-details p {
  color: #b2dcaa;
}

.dt-sc-yoga-video .dt-sc-yoga-video-meta,
.dt-sc-yoga-classes:hover .dt-sc-yoga-classes-details,
.dt-sc-yoga-classes-sorting a,
.dt-sc-yoga-pose:hover .dt-sc-yoga-pose-details,
.dt-sc-yoga-teacher:hover .dt-sc-yoga-teacher-details {
  background: #b2dcaa;
}

.dt-sc-yoga-classes .dt-sc-yoga-classes-image-wrapper:before,
.dt-sc-yoga-course .dt-sc-yoga-course-thumb-overlay,
.dt-sc-yoga-program .dt-sc-yoga-program-thumb-overlay {
  background: rgba(178, 220, 170, 0.8);
}

.dt-sc-yoga-pose .dt-sc-yoga-pose-thumb:before,
.dt-sc-yoga-teacher .dt-sc-yoga-teacher-thumb:before {
  background: rgba(178, 220, 170, 0.9);
}

.dt-sc-yoga-people-single .dt-sc-single-line-dashed-separator,
.dt-sc-yoga-program-single:hover,
.dt-sc-yoga-pose-single .entry-thumb:hover,
.dt-sc-yoga-style-single .entry-thumb:hover {
  border-color: #b2dcaa;
}

.dt-sc-yoga-classes .dt-sc-yoga-classes-image-overlay a,
.dt-sc-yoga-pose .dt-sc-yoga-pose-overlay a,
.dt-sc-yoga-teacher .dt-sc-yoga-teacher-overlay a,
.dt-sc-yoga-courses-sorting a:hover,
.dt-sc-yoga-course .dt-sc-yoga-course-details h5 a:hover,
.dt-sc-yoga-course .dt-sc-yoga-course-details h6 a:hover,
.dt-sc-yoga-course .dt-sc-yoga-course-details .price,
.dt-sc-yoga-course .dt-sc-yoga-course-thumb-overlay a,
.dt-sc-yoga-program .dt-sc-yoga-program-details h5 a:hover,
.dt-sc-yoga-program .dt-sc-yoga-program-details h6 a:hover,
.dt-sc-yoga-program .dt-sc-yoga-program-thumb-overlay a,
.dt-sc-yoga-people-info h4 {
  color: #b2dcaa;
}

.yoga-video-sorting input[type="submit"],
.dt-sc-yoga-classes .dt-sc-yoga-classes-image-overlay a:hover,
.dt-sc-yoga-courses-sorting a.active-sort,
.dt-sc-yoga-courses-sorting a:before,
.dt-sc-yoga-course .dt-sc-yoga-course-thumb-overlay a:hover,
.dt-sc-yoga-pose .dt-sc-yoga-pose-overlay a:hover,
.dt-sc-yoga-teacher .dt-sc-yoga-teacher-overlay a:hover,
.dt-sc-yoga-program .dt-sc-yoga-program-thumb-overlay a:hover {
  background: #bedbb8;
}

.dt-sc-yoga-video .dt-sc-yoga-video-overlay p a {
  border-color: #bedbb8;
}

.dt-sc-yoga-video .dt-sc-yoga-video-overlay p a,
.dt-sc-yoga-video .dt-sc-yoga-video-overlay h6 a:hover,
.dt-sc-yoga-courses-sorting a,
.dt-sc-yoga-course .dt-sc-yoga-course-meta p,
.dt-sc-yoga-course .dt-sc-yoga-course-details h6 a,
.dt-sc-yoga-program .dt-sc-yoga-program-meta p,
.dt-sc-yoga-program .dt-sc-yoga-program-details h6 a {
  color: #bedbb8;
}

.dt-sc-yoga-classes-sorting a:hover,
.dt-sc-yoga-classes-sorting a.active-sort {
  background: #95db87;
}

.yoga-single-meta li span {
  color: #95db87;
}

.dt-sc-doctors-sorting a.active-sort,
.dt-sc-doctors .dt-sc-doctors-details ul.dt-sc-sociable li a {
  background: #b2dcaa;
}

.dt-sc-doctors .dt-sc-doctors-thumb-overlay {
  background: rgba(178, 220, 170, 0.9);
}

.dt-sc-doctors-sorting a.active-sort {
  border-color: #b2dcaa;
}

.dt-sc-doctors-sorting a:hover,
.dt-sc-doctors .dt-sc-doctors-details h5 a,
.dt-sc-doctors .dt-sc-doctors-details ul.dt-sc-doctors-meta li span,
.dt-sc-doctors .dt-sc-doctors-details ul.dt-sc-doctors-meta li a:hover,
.dt-sc-doctors-single-meta li span {
  color: #b2dcaa;
}

.dt-sc-doctors .dt-sc-doctors-details ul.dt-sc-sociable li a:hover {
  background: #95db87;
}

.dt-sc-event-addon-detail>a.buy-now {
  background: #b2dcaa;
}

.dt-sc-event-addon>.dt-sc-event-addon-date {
  background-color: rgba(178, 220, 170, 0.9);
}

.dt-sc-dj-club h2 {
  border-color: #b2dcaa;
}

.dt-sc-event-sorting a.active-sort,
.dt-sc-event-sorting a:hover,
.dt-sc-event-addon-detail ul li span,
.dt-sc-event-month:hover h2 a,
.dt-sc-dj-profile .dt-sc-dj-profile-details h3,
.dt-sc-dj-profile .dt-sc-dj-profile-details .dt-sc-dj-profile-meta p,
.dt-sc-dj-club h4 {
  color: #b2dcaa;
}

.dt-sc-event-month-thumb .dt-sc-event-month-date-wrapper {
  background: #bedbb8;
}

.dt-sc-event-month-thumb .dt-sc-event-read-more {
  background: rgba(149, 219, 135, 0.9);
}

.dt-sc-event-sorting a,
.dt-sc-event-month-meta li {
  color: #95db87;
}

.model-nav-container .model-next-link a:hover,
.model-nav-container .model-prev-link a:hover {
  background: #b2dcaa;
}

.model-nav-container .model-next-link a:hover,
.model-nav-container .model-prev-link a:hover {
  border-color: #b2dcaa;
}

.dt-sc-model figcaption h3 a:hover,
.dt-sc-model-sorting a:hover,
.dt-sc-model-sorting a.active-sort,
.dt-sc-model-details li span,
.dt-sc-model-single-slider-wrapper .bx-controls a:hover:before {
  color: #b2dcaa;
}

.dt-sc-model-sorting a:before {
  background: #95db87;
}

.dt-sc-model-sorting a {
  color: #95db87;
}

.dt-sc-course .dt-sc-course-overlay {
  background: rgba(178, 220, 170, 0.8);
}

.dt-sc-course .dt-sc-course-overlay a {
  background: #bedbb8;
}

.dt-sc-faculty .dt-sc-faculty-thumb-overlay {
  background: rgba(190, 219, 184, 0.9);
}

.dt-sc-faculty-sorting a:hover,
.dt-sc-faculty-sorting a.active-sort,
.dt-sc-course .dt-sc-course-details .dt-sc-course-meta p span,
.dt-sc-course.no-course-thumb .dt-sc-course-details h5 a {
  color: #bedbb8;
}

.dt-sc-hotel-room-sorting a:before,
.dt-sc-hotel-room-sorting a.active-sort:before,
.dt-sc-hotel-room-single-metadata form input[type="submit"]:hover,
.dt-sc-hotel-room-single-metadata form p:before {
  background: #b2dcaa;
}

.dt-sc-hotel-room-single-metadata form,
.dt-sc-hotel-room-single-metadata form input[type="submit"] {
  border-color: #b2dcaa;
}

.dt-sc-hotel-room .dt-sc-hotel-room-thumb {
  border-top-color: #b2dcaa;
}

.dt-sc-hotel-room .dt-sc-hotel-room-thumb-overlay p .price,
.dt-sc-hotel-room .dt-sc-hotel-room-buttons a:hover,
.dt-sc-hotel-room .dt-sc-hotel-room-details .dt-sc-hotel-room-content h4,
.dt-sc-hotel-room-single-details h3,
.dt-sc-hotel-room-single-details ul li:before,
.dt-sc-hotel-room-single-metadata .price-wrapper p .price,
.dt-sc-hotel-room-single-metadata ul.dt-sc-sociable li a:hover {
  color: #b2dcaa;
}

.dt-sc-training-details-overlay,
.dt-sc-trainers .dt-sc-sociable,
.dt-sc-workouts .dt-sc-workouts-details a span,
.dt-sc-workouts .dt-sc-workouts-details a:hover,
.dt-sc-fitness-program figure figcaption,
.dt-sc-fitness-program-nav li a:before,
.dt-sc-fitness-program-nav li.current_page_item a,
.dt-sc-fitness-program-nav li a:after,
.dt-sc-fitness-program-details h4:before,
.dt-sc-fitness-program-details .dt-sc-pr-tb-col .dt-sc-buy-now a {
  background: #b2dcaa;
}

.dt-sc-process-steps .dt-sc-process-thumb-overlay {
  background: rgba(178, 220, 170, 0.7);
}

.dt-sc-fitness-program-short-details-wrapper .dt-sc-fitness-program-short-details {
  background: rgba(178, 220, 170, 0.9);
}

.dt-sc-trainers:hover,
.dt-sc-trainers:hover .dt-sc-trainers-title,
.dt-sc-process-steps .dt-sc-process-thumb img {
  border-color: #b2dcaa;
}

.dt-sc-training-details h6,
.dt-sc-trainers .dt-sc-trainers-title h6,
.dt-sc-workouts .dt-sc-workouts-details h4,
.dt-sc-fitness-program-sorting a:hover,
.dt-sc-fitness-program-sorting a.active-sort,
.dt-sc-fitness-program .dt-sc-fitness-program-price sup,
.dt-sc-fitness-program .dt-sc-fitness-program-price sub,
.dt-sc-fitness-program-nav li a:hover,
.dt-sc-fitness-program-details .dt-sc-pr-tb-col .dt-sc-price h6 sup,
.dt-sc-fitness-diet-result p {
  color: #b2dcaa;
}

.dt-sc-training-details-overlay h6,
.dt-sc-training-details-overlay .price,
.dt-sc-training-details .dt-sc-training-details-overlay h6 {
  color: #bedbb8;
}

.dt-sc-training-details {
  background: #95db87;
}

.dt-sc-training-thumb-overlay {
  background: rgba(149, 219, 135, 0.8);
}

.under-construction.type4 .wpb_wrapper>h2 span,
.under-construction.type4 .read-more i,
.under-construction.type4.wpb_wrapper>h4:after,
.under-construction.type4 .wpb_wrapper>h4:before,
.under-construction.type1 .read-more span.fa,
.under-construction.type1 .read-more a:hover,
.under-construction.type2 .counter-icon-wrapper .dt-sc-counter-number,
.under-construction.type2 h2,
.under-construction.type2 .dt-sc-counter-wrapper h3,
.under-construction.type2 .mailchimp-newsletter h3,
.under-construction.type7 h2,
.under-construction.type7 .mailchimp-newsletter h3,
.under-construction.type3 p,
.under-construction.type5 h2 span,
.under-construction.type5 .dt-sc-counter-number,
.under-construction.type5 footer .dt-sc-team-social li:hover a,
.under-construction.type5 input[type="email"],
.under-construction.type7 .aligncenter .wpb_text_column h2 {
  color: #b2dcaa;
}

.under-construction.type4 .dt-sc-counter-wrapper,
.under-construction.type1 .dt-sc-newsletter-section form input[type="submit"],
.under-construction.type1 .dt-sc-counter-wrapper .counter-icon-wrapper:before,
.under-construction.type2 .dt-sc-sociable>li:hover a,
.under-construction.type7 .dt-sc-sociable>li:hover a,
.under-construction.type3 .dt-sc-newsletter-section form input[type="submit"],
.under-construction.type3 .dt-sc-sociable>li:hover a,
.under-construction.type7 .dt-sc-counter-wrapper,
.under-construction.type7 .dt-sc-newsletter-section form input[type="submit"] {
  background: #b2dcaa;
}

.under-construction.type3 .dt-sc-sociable>li:hover a {
  border-color: #b2dcaa;
}

.error404 .type2 h2,
.error404 .type8 h2,
.error404 .type8 .dt-go-back:hover i {
  color: #b2dcaa;
}

.error404 .type2 a.dt-sc-back,
.error404 .type4 .error-box,
.error404 .type4 .dt-sc-newsletter-section input[type="submit"],
.error404 .type8 .dt-go-back {
  background: #b2dcaa;
}

.error404 .type2 a.dt-sc-back:hover,
.error404 .type4 .dt-sc-newsletter-section input[type="submit"]:hover {
  background: #95db87;
}

@media only screen and (max-width: 767px) {

  .dt-sc-contact-info.type4:after,
  .dt-sc-icon-box.type10 .icon-content h4:after,
  .dt-sc-counter.type6.last h4::before,
  .dt-sc-counter.type6 h4::after {
    background-color: #b2dcaa;
  }

  .dt-sc-timeline-section.type2,
  .dt-sc-timeline-section.type2::before {
    border-color: #b2dcaa;
  }

  .dt-sc-highlight .dt-sc-testimonial.type6 .dt-sc-testimonial-author:after,
  .dt-sc-highlight .dt-sc-testimonial.type6 .dt-sc-testimonial-author:after,
  .skin-highlight .dt-sc-testimonial.type6 .dt-sc-testimonial-author:after {
    background-color: #95db87;
  }
}

body,
.layout-boxed .inner-wrapper {
  background-color: #fff;
}

#logo .logo-title>h1 a {
  color: #fff;
}

.dt-sc-highlight.extend-bg-fullwidth-left:after,
.dt-sc-highlight.extend-bg-fullwidth-right:after {
  background: #b2dcaa;
}

.top-bar a,
.dt-sc-dark-bg.top-bar a {
  color: #b2dcaa;
}

#main-menu ul.menu>li>a:hover,
#main-menu ul.menu li.menu-item-megamenu-parent:hover>a,
#main-menu ul.menu>li.menu-item-simple-parent:hover>a {
  color: #b2dcaa;
}

#main-menu>ul.menu>li.current_page_item>a,
#main-menu>ul.menu>li.current_page_ancestor>a,
#main-menu>ul.menu>li.current-menu-item>a,
#main-menu ul.menu>li.current-menu-ancestor>a,
#main-menu ul.menu li.menu-item-simple-parent ul>li.current_page_item>a,
#main-menu ul.menu li.menu-item-simple-parent ul>li.current_page_ancestor>a,
#main-menu ul.menu li.menu-item-simple-parent ul>li.current-menu-item>a,
#main-menu ul.menu li.menu-item-simple-parent ul>li.current-menu-ancestor>a,
.left-header #main-menu>ul.menu>li.current_page_item>a,
.left-header #main-menu>ul.menu>li.current_page_ancestor>a,
.left-header #main-menu>ul.menu>li.current-menu-item>a,
.left-header #main-menu>ul.menu>li.current-menu-ancestor>a {
  color: #b2dcaa;
}

#footer a:hover,
#footer .dt-sc-dark-bg a:hover,
#footer .widget ul li:hover:before {
  color: #b2dcaa;
}

.top-bar {
  color: #000000;
  background-color: #eeeeee
}

.top-bar a:hover,
.dt-sc-dark-bg.top-bar a:hover {
  color: #000000;
}

#main-menu ul.menu>li>a {
  color: #000000;
}

.menu-active-highlight #main-menu>ul.menu>li.current_page_item>a,
.menu-active-highlight #main-menu>ul.menu>li.current_page_ancestor>a,
.menu-active-highlight #main-menu>ul.menu>li.current-menu-item>a,
.menu-active-highlight #main-menu>ul.menu>li.current-menu-ancestor>a {
  color: #ffffff;
}

.footer-widgets {
  background-color: rgba(255, 255, 255, 1);
}

.footer-widgets,
#footer,
.footer-copyright {
  color: #777777;
}

.footer-widgets a,
#footer a {
  color: #000000;
}

#footer h3 {
  color: #000000;
}

.footer-copyright {
  background: rgba(255, 255, 255, 1);
}

#main-menu .megamenu-child-container>ul.sub-menu>li>a,
#main-menu .megamenu-child-container>ul.sub-menu>li>.nolink-menu {
  color: ;
}

#main-menu .megamenu-child-container>ul.sub-menu>li>a:hover {
  color: ;
}

#main-menu .megamenu-child-container>ul.sub-menu>li.current_page_item>a,
#main-menu .megamenu-child-container>ul.sub-menu>li.current_page_ancestor>a,
#main-menu .megamenu-child-container>ul.sub-menu>li.current-menu-item>a,
#main-menu .megamenu-child-container>ul.sub-menu>li.current-menu-ancestor>a {
  color: ;
}

#main-menu .megamenu-child-container ul.sub-menu>li>ul>li>a,
#main-menu ul li.menu-item-simple-parent ul>li>a {
  color: ;
}

h1,
.dt-sc-counter.type1 .dt-sc-counter-number,
.dt-sc-portfolio-sorting a,
.dt-sc-testimonial .dt-sc-testimonial-author cite,
.dt-sc-pr-tb-col.minimal .dt-sc-price p,
.dt-sc-pr-tb-col.minimal .dt-sc-price h6 span,
.dt-sc-testimonial.special-testimonial-carousel blockquote,
.dt-sc-pr-tb-col .dt-sc-tb-title,
.dt-sc-pr-tb-col .dt-sc-tb-content,
.dt-sc-pr-tb-col .dt-sc-tb-content li,
.dt-sc-bar-text,
.dt-sc-counter.type3 .dt-sc-counter-number,
.dt-sc-newsletter-section.type2 .dt-sc-subscribe-frm input[type="submit"],
.dt-sc-timeline .dt-sc-timeline-content h2 span,
.dt-sc-model-sorting a,
.dt-sc-icon-box.type9 .icon-content h4,
.dt-sc-icon-box.type9 .icon-content h4 span,
.dt-sc-menu-sorting a,
.dt-sc-menu .image-overlay .price,
.dt-sc-menu .menu-categories a,
.dt-sc-pr-tb-col .dt-sc-price h6,
ul.products li .onsale,
input[type="submit"],
input[type="reset"] {
  font-family: Lusitana, sans-serif;
}

h2 {
  font-family: Lusitana, sans-serif;
}

h3,
.dt-sc-testimonial.type1 blockquote,
.blog-entry.entry-date-left .entry-date,
.dt-sc-ribbon-title,
.dt-sc-testimonial.type1 .dt-sc-testimonial-author cite {
  font-family: Lusitana, sans-serif;
}

h4,
.blog-entry .entry-meta,
.dt-sc-button,
.blog-entry .entry-title h4 {
  font-family: Lusitana, sans-serif;
}

h5 {
  font-family: Lusitana, sans-serif;
}

h6 {
  font-family: Lusitana, sans-serif;
}

h1 {
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.5px;
}

h2 {
  font-size: 24px;
  font-weight: normal;
  letter-spacing: 0.5px;
}

h3 {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.5px;
}

h4 {
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.5px;
}

h5 {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.5px;
}

h6 {
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0.5px;
}

body {
  font-size: 14px;
  line-height: 24px;
}

body,
.blog-entry.blog-medium-style .entry-meta,
.dt-sc-event-image-caption .dt-sc-image-content h3,
.dt-sc-events-list .dt-sc-event-title h5,
.dt-sc-team.type2 .dt-sc-team-details h4,
.dt-sc-team.type2 .dt-sc-team-details h5,
.dt-sc-contact-info.type5 h6,
.dt-sc-sponsors .dt-sc-one-third h3,
.dt-sc-testimonial.type5 .dt-sc-testimonial-author cite,
.dt-sc-counter.type3 h4,
.dt-sc-contact-info.type2 h6,
.woocommerce ul.products li.product .onsale,
#footer .mailchimp-form input[type="email"],
.dt-sc-icon-box.type5 .icon-content h5,
.main-header #searchform input[type="text"],
.dt-sc-testimonial.type1 .dt-sc-testimonial-author cite small,
.dt-sc-pr-tb-col.type2 .dt-sc-tb-content li,
.dt-sc-team.rounded .dt-sc-team-details h5,
.megamenu-child-container>ul.sub-menu>li>a .menu-item-description,
.menu-item-description,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="tel"],
input[type="number"],
input[type="range"],
input[type="date"],
textarea,
input.text,
input[type="search"],
select,
textarea {
  font-family: Quicksand, sans-serif;
}

#main-menu ul.menu>li>a,
.left-header #main-menu>ul.menu>li>a {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.5px;
}

#main-menu ul.menu li a,
.dt-sc-pr-tb-col .dt-sc-tb-title h5,
.dt-sc-timeline .dt-sc-timeline-content h2,
.dt-sc-icon-box.type3 .icon-content h4,
.dt-sc-popular-procedures .details h3,
.dt-sc-popular-procedures .details .duration,
.dt-sc-popular-procedures .details .price,
.dt-sc-counter.type2 .dt-sc-counter-number,
.dt-sc-counter.type2 h4,
.dt-sc-testimonial.type4 .dt-sc-testimonial-author cite {
  font-family: Lusitana, sans-serif;
}
