@charset "utf-8";
/* Table of Content
==================================================
	1.0 - Transition
	2.0 - Contact
	3.0 - Browser Css
	4.0 - Default Color
	5.0 - Skin Color*/

	/*--------------------------------------------------------------
	1.0 - Transition
	--------------------------------------------------------------*/

.dt-sc-contact-info.type2, .dt-sc-contact-info.type2 span, .dt-sc-contact-info.type5, .dt-sc-contact-info.type8 span { -webkit-transition:all 0.3s linear; -moz-transition:all 0.3s linear; -o-transition:all 0.3s linear; -ms-transition:all 0.3s linear; transition:all 0.3s linear; }



	/*--------------------------------------------------------------
	2.0 - Contact
	--------------------------------------------------------------*/
	.dt-sc-contact-info { float:left; margin:0px; padding:0px; clear:both; width:100%; line-height:28px; position:relative; }
	.dt-sc-contact-info span { float:left; margin:5px 10px 10px 0px; font-size:20px; width:30px; text-align:center; }
	.dt-sc-contact-info p { margin:0px; }
	.dt-sc-contact-info .icon-pointer { margin-bottom:10px; }
		
	.dt-sc-contact-info.type1 h6 { font-size:15px; font-weight:bold; margin-bottom:10px; text-transform: uppercase; }
	.dt-sc-contact-info.type1 { font-size:15px; }
	
	.dt-sc-contact-info.type2 { border:1px solid; padding:0px 0px 10px 100px; }
	.dt-sc-contact-info.type2 h6 { text-transform:uppercase; font-size:13px; letter-spacing:0.6px; margin:10px 0px; font-weight:bold; }
	.dt-sc-contact-info.type2 span { float:left; position:absolute; left:0px; top:-5px; height:100%; width:80px; font-size:30px; line-height:100px; }
	
	.dt-sc-contact-info.type3 { position:relative; padding:40px 12% 50px; text-align:center; border: 15px solid #f6f3ea; }
	.dt-sc-contact-info.type3 h6 { font-size:28px; text-transform:uppercase; margin-bottom:5px; }
	.dt-sc-contact-info.type3 h6, .dt-sc-contact-info.type3 p { position:relative; z-index:1; font-weight:normal; }
	.dt-sc-contact-info.type3 span { font-size:34px; z-index:9; float:none; display:block; margin:auto; position:relative; background: #fff; display: inline-block; padding: 0 0; width: 50px; left: 0; line-height: 35px; height: 35px; }
	.dt-sc-contact-info.type3 h6 + p { margin-top: 25px; }
	.dt-sc-contact-info.type3:before { content:""; position:absolute; left:0px; top:0px; width:100%; height:100%; border:2px solid; }
	.dt-sc-contact-info.type3 p { border-top:2px solid #dae9d4; border-bottom:2px solid #dae9d4; padding: 15px 0 15px; font-size:17px; color:#727272; }
	.dt-sc-contact-info.type3.pattern-bg { border:15px solid; -webkit-border-image: url(/public/image/contact-info-bg.jpg) 15 round; border-image: url(/public/image/contact-info-bg.jpg) 15 round; -o-border-image: url(/public/image/contact-info-bg.jpg) 15 round; }
	.dt-sc-contact-info.type3.pattern-bg:before { display:none; }
	.dt-sc-contact-info.type3 span + p { margin-top: -18px; }
	/*.dt-sc-contact-info.type3:after { content:""; position:absolute; left:0px; top:0; margin:auto; bottom:0; width:100%; height:100%; }*/
	
	
	.dt-sc-contact-info.type4 { border:1px solid; border-right-width: 0; text-align:center; padding:30px 10px; margin:0px -1px 0px 0px; float:left; }
	.last .dt-sc-contact-info.type4 { border-right:1px solid; }
	.dt-sc-contact-info.type4 span { display:inline-block; float:none; position:relative; width:70px; height:70px; margin-bottom:20px; line-height:68px; }
	.dt-sc-contact-info.type4 h6 { font-size:16px; font-weight:bold; text-transform:uppercase; }
	.dt-sc-contact-info.type4 p, .dt-sc-contact-info.type4 a { font-size:14px; line-height:22px; }
	
	.dt-sc-contact-info.type4 span:before { position:relative; z-index:1; font-size:30px; }
	.dt-sc-contact-info.type4 span:after { content:""; position:absolute; left:0px; right:0px; top:8px; margin:auto; width:50px; height:50px; -webkit-transform:rotate(45deg); -moz-transform:rotate(45deg); -ms-transform:rotate(45deg);   transform:rotate(45deg); border-radius:5px; }
	
	.dt-sc-contact-info.type4:before { content:""; position:absolute; right:-7px; top:0px; bottom:0px; margin:auto; width:12px; height:12px; -webkit-transform:rotate(45deg); -moz-transform:rotate(45deg); -ms-transform:rotate(45deg);  transform:rotate(45deg); z-index:1; }
	.last .dt-sc-contact-info.type4:before { content: none; }
	
	.dt-sc-contact-info-wrapper { float:left; width:100%; text-align:center; position:relative; z-index:99; } 
	.dt-sc-contact-info-wrapper .wpb_column { float:none; display:inline-block; }
	
	
	.dt-sc-contact-info.type5 { text-align:center; padding:40px 20px; }
	.dt-sc-contact-info.type5 .dt-sc-contact-icon span { font-size:60px; line-height:72px; position:relative; z-index:9; width:auto; float:none; margin:0px; }
	.dt-sc-contact-info.type5 h6 { font-size:16px; text-transform:uppercase; margin:30px 0px 0px; font-weight:600; }
	
	.dt-sc-contact-info.type5 .dt-sc-contact-icon { text-align:center; position: relative; width: 131px; height: 75.06px; margin: 43.30px 0; border-left: solid 3px; border-right: solid 3px; display:inline-block; }
	.dt-sc-contact-info.type5 .dt-sc-contact-icon:before, .dt-sc-contact-info.type5 .dt-sc-contact-icon:after { content: ""; position: absolute; z-index: 1; width: 91.92px; height: 91.92px; -webkit-transform: scaleY(0.5774) rotate(-45deg); -ms-transform: scaleY(0.5774) rotate(-45deg); transform: scaleY(0.5774) rotate(-45deg); background-color: inherit; left: 17.0381px; }
	.dt-sc-contact-info.type5 .dt-sc-contact-icon:before { top: -45.9619px; border-top: solid 4.2426px; border-right: solid 4.2426px; }
	.dt-sc-contact-info.type5 .dt-sc-contact-icon:after { bottom: -45.9619px; border-bottom: solid 4.2426px; border-left: solid 4.2426px; }
	
	.dt-sc-contact-info.type5.blue .dt-sc-contact-icon { background:#3d9bd5; }
	.dt-sc-contact-info.type5.blue .dt-sc-contact-icon, .dt-sc-contact-info.type5.blue .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.blue .dt-sc-contact-icon:after { border-color:#358cc6; }
	.dt-sc-contact-info.type5.blue:hover { background-color:#3d9bd5; }
	.dt-sc-contact-info.type5.blue:hover .dt-sc-contact-icon span { color:#3d9bd5; }	
	
	.dt-sc-contact-info.type5.brown .dt-sc-contact-icon { background:#795548; }
	.dt-sc-contact-info.type5.brown .dt-sc-contact-icon, .dt-sc-contact-info.type5.brown .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.brown .dt-sc-contact-icon:after { border-color:#573a30; }
	.dt-sc-contact-info.type5.brown:hover { background-color:#795548; }
	.dt-sc-contact-info.type5.brown:hover .dt-sc-contact-icon span { color:#795548; }

	.dt-sc-contact-info.type5.cadetblue .dt-sc-contact-icon { background:#3c939d; }
	.dt-sc-contact-info.type5.cadetblue .dt-sc-contact-icon, .dt-sc-contact-info.type5.cadetblue .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.cadetblue .dt-sc-contact-icon:after { border-color:#34818a; }
	.dt-sc-contact-info.type5.cadetblue:hover { background-color:#3c939d; }
	.dt-sc-contact-info.type5.cadetblue:hover .dt-sc-contact-icon span { color:#3c939d; }

	.dt-sc-contact-info.type5.chillipepper .dt-sc-contact-icon { background:#c10841; }
	.dt-sc-contact-info.type5.chillipepper .dt-sc-contact-icon, .dt-sc-contact-info.type5.chillipepper .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.chillipepper .dt-sc-contact-icon:after { border-color:#9d0836; }
	.dt-sc-contact-info.type5.chillipepper:hover { background-color:#c10841; }
	.dt-sc-contact-info.type5.chillipepper:hover .dt-sc-contact-icon span { color:#c10841; }

	.dt-sc-contact-info.type5.cyan .dt-sc-contact-icon { background:#00bcd4; }
	.dt-sc-contact-info.type5.cyan .dt-sc-contact-icon, .dt-sc-contact-info.type5.cyan .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.cyan .dt-sc-contact-icon:after { border-color:#00a0b4; }
	.dt-sc-contact-info.type5.cyan:hover { background-color:#00bcd4; }
	.dt-sc-contact-info.type5.cyan:hover .dt-sc-contact-icon span { color:#00bcd4; }

	.dt-sc-contact-info.type5.darkgolden .dt-sc-contact-icon { background:#b48b3c; }
	.dt-sc-contact-info.type5.darkgolden .dt-sc-contact-icon, .dt-sc-contact-info.type5.darkgolden .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.darkgolden .dt-sc-contact-icon:after { border-color:#a17b35; }
	.dt-sc-contact-info.type5.darkgolden:hover { background-color:#b48b3c; }
	.dt-sc-contact-info.type5.darkgolden:hover .dt-sc-contact-icon span { color:#b48b3c; }

	.dt-sc-contact-info.type5.deeporange .dt-sc-contact-icon { background:#ff5722; }
	.dt-sc-contact-info.type5.deeporange .dt-sc-contact-icon, .dt-sc-contact-info.type5.deeporange .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.deeporange .dt-sc-contact-icon:after { border-color:#db4211; }
	.dt-sc-contact-info.type5.deeporange:hover { background-color:#ff5722; }
	.dt-sc-contact-info.type5.deeporange:hover .dt-sc-contact-icon span { color:#ff5722; }
	
	.dt-sc-contact-info.type5.deeppurple .dt-sc-contact-icon { background:#673ab7; }
	.dt-sc-contact-info.type5.deeppurple .dt-sc-contact-icon, .dt-sc-contact-info.type5.deeppurple .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.deeppurple .dt-sc-contact-icon:after { border-color:#532b99; }
	.dt-sc-contact-info.type5.deeppurple:hover { background-color:#673ab7; }
	.dt-sc-contact-info.type5.deeppurple:hover .dt-sc-contact-icon span { color:#673ab7; }

	.dt-sc-contact-info.type5.green .dt-sc-contact-icon { background:#68b471; }
	.dt-sc-contact-info.type5.green .dt-sc-contact-icon, .dt-sc-contact-info.type5.green .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.green .dt-sc-contact-icon:after { border-color:#58975f; }
	.dt-sc-contact-info.type5.green:hover { background-color:#68b471; }
	.dt-sc-contact-info.type5.green:hover .dt-sc-contact-icon span { color:#68b471; }

	.dt-sc-contact-info.type5.lime .dt-sc-contact-icon { background:#cddc39; }
	.dt-sc-contact-info.type5.lime .dt-sc-contact-icon, .dt-sc-contact-info.type5.lime .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.lime .dt-sc-contact-icon:after { border-color:#b1bf27; }
	.dt-sc-contact-info.type5.lime:hover { background-color:#cddc39; }
	.dt-sc-contact-info.type5.lime:hover .dt-sc-contact-icon span { color:#cddc39; }

	.dt-sc-contact-info.type5.magenta .dt-sc-contact-icon { background:#cb506d; }
	.dt-sc-contact-info.type5.magenta .dt-sc-contact-icon, .dt-sc-contact-info.type5.magenta .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.magenta .dt-sc-contact-icon:after { border-color:#ae3753; }
	.dt-sc-contact-info.type5.magenta:hover { background-color:#cb506d; }
	.dt-sc-contact-info.type5.magenta:hover .dt-sc-contact-icon span { color:#cb506d; }	

	.dt-sc-contact-info.type5.orange .dt-sc-contact-icon { background:#ea8852; }
	.dt-sc-contact-info.type5.orange .dt-sc-contact-icon, .dt-sc-contact-info.type5.orange .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.orange .dt-sc-contact-icon:after { border-color:#d27745; }
	.dt-sc-contact-info.type5.orange:hover { background-color:#ea8852; }
	.dt-sc-contact-info.type5.orange:hover .dt-sc-contact-icon span { color:#ea8852; }
	
	.dt-sc-contact-info.type5.pink .dt-sc-contact-icon { background:#fd6ca3; }
	.dt-sc-contact-info.type5.pink .dt-sc-contact-icon, .dt-sc-contact-info.type5.pink .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.pink .dt-sc-contact-icon:after { border-color:#df5e8f; }
	.dt-sc-contact-info.type5.pink:hover { background-color:#fd6ca3; }
	.dt-sc-contact-info.type5.pink:hover .dt-sc-contact-icon span { color:#fd6ca3; }	
	
	.dt-sc-contact-info.type5.purple .dt-sc-contact-icon { background:#9c27b0; }
	.dt-sc-contact-info.type5.purple .dt-sc-contact-icon, .dt-sc-contact-info.type5.purple .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.purple .dt-sc-contact-icon:after { border-color:#7c1b8c; }
	.dt-sc-contact-info.type5.purple:hover { background-color:#9c27b0; }
	.dt-sc-contact-info.type5.purple:hover .dt-sc-contact-icon span { color:#9c27b0; }

	.dt-sc-contact-info.type5.red .dt-sc-contact-icon { background:#da0000; }
	.dt-sc-contact-info.type5.red .dt-sc-contact-icon, .dt-sc-contact-info.type5.red .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.red .dt-sc-contact-icon:after { border-color:#c50000; }
	.dt-sc-contact-info.type5.red:hover { background-color:#da0000; }
	.dt-sc-contact-info.type5.red:hover .dt-sc-contact-icon span { color:#da0000; }

	.dt-sc-contact-info.type5.skyblue .dt-sc-contact-icon { background:#0eb2e7; }
	.dt-sc-contact-info.type5.skyblue .dt-sc-contact-icon, .dt-sc-contact-info.type5.skyblue .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.skyblue .dt-sc-contact-icon:after { border-color:#0da2d4; }
	.dt-sc-contact-info.type5.skyblue:hover { background-color:#0eb2e7; }
	.dt-sc-contact-info.type5.skyblue:hover .dt-sc-contact-icon span { color:#0eb2e7; }

	.dt-sc-contact-info.type5.teal .dt-sc-contact-icon { background:#009688; }
	.dt-sc-contact-info.type5.teal .dt-sc-contact-icon, .dt-sc-contact-info.type5.teal .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.teal .dt-sc-contact-icon:after { border-color:#007f73; }
	.dt-sc-contact-info.type5.teal:hover { background-color:#009688; }
	.dt-sc-contact-info.type5.teal:hover .dt-sc-contact-icon span { color:#009688; }

	.dt-sc-contact-info.type5.turquoise .dt-sc-contact-icon { background:#32ccbd; }
	.dt-sc-contact-info.type5.turquoise .dt-sc-contact-icon, .dt-sc-contact-info.type5.turquoise .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.turquoise .dt-sc-contact-icon:after { border-color:#24b6a8; }
	.dt-sc-contact-info.type5.turquoise:hover { background-color:#32ccbd; }
	.dt-sc-contact-info.type5.turquoise:hover .dt-sc-contact-icon span { color:#32ccbd; }

	.dt-sc-contact-info.type5.wisteria .dt-sc-contact-icon { background:#9b59b6; }
	.dt-sc-contact-info.type5.wisteria .dt-sc-contact-icon, .dt-sc-contact-info.type5.wisteria .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.wisteria .dt-sc-contact-icon:after { border-color:#7c4094; }
	.dt-sc-contact-info.type5.wisteria:hover { background-color:#9b59b6; }
	.dt-sc-contact-info.type5.wisteria:hover .dt-sc-contact-icon span { color:#9b59b6; }
	
	.dt-sc-contact-info.type5.yellow .dt-sc-contact-icon { background:#ffe401; }
	.dt-sc-contact-info.type5.yellow .dt-sc-contact-icon, .dt-sc-contact-info.type5.yellow .dt-sc-contact-icon:before, .dt-sc-contact-info.type5.yellow .dt-sc-contact-icon:after { border-color:#ebd302; }
	.dt-sc-contact-info.type5.yellow:hover { background-color:#ffe401; }
	.dt-sc-contact-info.type5.yellow:hover .dt-sc-contact-icon span { color:#ffe401; }
	
	/* .dt-sc-contact-info.type5:hover { background:url(/public/image/hexagon-shape-pattern.png) repeat; } */
	
	
	.dt-sc-contact-details-on-map { position:relative; float:left; clear:both; width:100%; margin:0px; padding:0px; }
	.dt-sc-contact-details-on-map .map { widows:100%; margin:0px; padding:0px; float:left; clear:both; }
	.dt-sc-contact-details-on-map img { max-width:100%; height:auto; display:block;  }
	
	.dt-sc-contact-info.type6 { text-align:center; padding:30px 20px; position:relative; }
	.dt-sc-contact-info.type6 span { font-size:36px; position:relative; z-index:9; width:auto; float:none; margin:0px; }
	.dt-sc-contact-info.type6 h6 { font-size:18px; text-transform:uppercase; margin:20px 0px 13px; }
	.dt-sc-contact-info.type6:before { content:""; position:absolute; left:-15px; top:-15px; width:100%; height:100%; z-index:-1; }
	
	
	.dt-sc-contact-info.type7 { padding:0px 0px 10px 100px; position:relative; }
	.dt-sc-contact-info.type7 h6 { text-transform:uppercase; font-size:13px; letter-spacing:0.6px; margin:10px 0px; font-weight:bold; }
	.dt-sc-contact-info.type7 span { float:left; position:absolute; left:0px; top:15px; height:100%; width:65px; font-size:30px; line-height:65px; color:#ffffff; }
	.dt-sc-contact-info.type7 span:after { content:""; position:absolute; width:65px; height:65px; left:0px; top:0px; -webkit-transform:rotate(45deg); -moz-transform:rotate(45deg); -ms-transform:rotate(45deg);  transform:rotate(45deg); border-radius:10px; z-index:-1; }
	
	
	.dt-sc-contact-info.type8 { border:1px solid #000000; padding:20px 30px 15px; position:relative; text-align:center; margin:90px 0px 0px; }
	.dt-sc-contact-info.type8 span { position:absolute; top:-140px; left: 0; right: 0; margin:auto; width:84px; height:84px; font-size:32px; line-height:84px; color:#ffffff; background:#000000; border-radius:50%; box-shadow: 0 0 0 5px #fff; -webkit-box-shadow: 0 0 0 5px #fff;  -moz-box-shadow: 0 0 0 5px #fff; -ms-box-shadow: 0 0 0 5px #fff; -o-box-shadow: 0 0 0 5px #fff; }
	.dt-sc-contact-info.type8 h6 { font-size:14px; font-weight:bold; text-transform:uppercase; letter-spacing:0.6px; margin-bottom:10px; } 
 
 	
 	
	/*--------------------------------------------------------------
	4.0 - Default Colors
	--------------------------------------------------------------*/
	.dt-sc-dark-bg .dt-sc-contact-info.type8 { border-color:#ffffff; }
	.dt-sc-dark-bg .dt-sc-contact-info.type8 p a:hover { color:rgba(255, 255, 255, 0.5); }
	
	.dt-sc-contact-info.type2 h6 { color:rgba(0, 0, 0, 0.5); }
	
	.dt-sc-contact-info.type2 span, .dt-sc-dark-bg .dt-sc-contact-info.type8 a, .dt-sc-contact-info.type4 span:before, .dt-sc-contact-info.type5 .dt-sc-contact-icon span, .dt-sc-contact-info.type5:hover h6 a, .dt-sc-contact-info.type6, .dt-sc-contact-info.type6 span, .dt-sc-contact-info.type6 h6, .dt-sc-contact-info.type6 a, /* Dark bg */ .dt-sc-dark-bg .dt-sc-contact-info.type8 p a { color:#ffffff; }
	
	.dt-sc-contact-info.type6 a:hover { color:rgba(0, 0, 0, 0.6); }
	
	.dt-sc-contact-info.type3 p a:hover { color:#000000; }
	
	.dt-sc-contact-info.type5 h6 a { color:#222222; }
	
	.dt-sc-dark-bg .dt-sc-contact-info.type8 a:hover { color:rgba(255, 255, 255, 0.2); }	
	
		
	.dt-sc-contact-info.type2 { border-color:#000000; }
	
	.dt-sc-contact-info.type3:before { border-color:rgba(255, 255, 255, 0.4); }
	
	
	.dt-sc-contact-info.type4, .dt-sc-contact-info.type3:after, .dt-sc-contact-info.type5:hover .dt-sc-contact-icon { background:#ffffff; }
	
	.dt-sc-contact-info.type2 span, .dt-sc-contact-info.type6:before { background:#000000; } 